import styled from 'styled-components'
import {
  Flex,
  PopupActions,
  PopupPadding,
  Text,
  textVariants,
} from '@/components/ui'

export const PopupContent = styled(Flex)<{
  variant: 'congratulation' | 'information'
}>`
  width: 384px;
  min-height: 400px;
  padding: ${PopupPadding};
  flex-direction: column;

  i {
    color: ${(p) =>
      p.variant === 'congratulation'
        ? 'var(--green-500);'
        : 'var(--grey-300);'};
  }
`

export const Title = styled(Text)`
  margin: 20px 0px 8px;
`

export const Subtitle = styled(Text)`
  flex-grow: 1;

  p {
    margin-bottom: 20px;
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin-left: 40px;

    li {
      left: 0;
      position: relative;
      counter-increment: item;
    }

    li:before {
      left: -40px;
      position: absolute;
      ${textVariants['body-m-semibold']}
      margin-right: 10px;
      content: counter(item);
      background-color: var(--grey-100);
      border-radius: 100%;
      color: var(--grey-900);
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    > li:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

export const Subscript = styled(Text)`
  color: var(--grey-500);
`

export const Actions = styled(PopupActions)`
  button {
    width: 100%;
  }
`

export const Note = styled(Flex)`
  border-radius: 16px;
  background: var(--grey-gradient);
  padding: 20px 16px;
  margin-top: 20px;
`

import React from 'react'
import ReactSelect, {
  ControlProps,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
  Props,
  StylesConfig,
} from 'react-select'

interface CustomProps {
  dataSourceUrl?: string
  invalid?: boolean
  isOpen?: boolean
}

export interface SelectOption {
  value: string
  label: string
}

const customStyles = ({ invalid }: { invalid: boolean }): StylesConfig => ({
  indicatorSeparator: () => ({}),
  control: (
    base: CSSObjectWithLabel,
    { isFocused }: ControlProps,
  ): CSSObjectWithLabel =>
    ({
      ...base,
      fontWeight: 400,
      backgroundColor: invalid ? 'var(--rose-25)' : 'var(--grey-100)',
      color: 'var(--grey-900)',
      border: 'none',
      borderRadius: '12px',
      boxShadow: 'none',
      padding: '7px 4px',
      fontSize: '15px',
      cursor: 'pointer',
      ...(invalid && { outline: '1.5px solid var(--rose-500)' }),
      ...(isFocused && {
        backgroundColor: 'var(--panel-1)',
        outline: '1.5px solid var(--purple-500)',
      }),
      ...(!isFocused && {
        ':hover': {
          backgroundColor: 'var(--grey-200)',
        },
      }),
    } as CSSObjectWithLabel),
  singleValue: (base: CSSObjectWithLabel): CSSObjectWithLabel =>
    ({
      ...base,
      color: 'var(--grey-900)',
    } as CSSObjectWithLabel),
  input: (base: CSSObjectWithLabel): CSSObjectWithLabel =>
    ({
      ...base,
      color: 'var(--grey-900)',
    } as CSSObjectWithLabel),
  menu: (base: CSSObjectWithLabel): CSSObjectWithLabel =>
    ({
      ...base,
      borderRadius: '6px',
    } as CSSObjectWithLabel),
  menuPortal: (base: CSSObjectWithLabel): CSSObjectWithLabel =>
    ({
      ...base,
      zIndex: 1002,
    } as CSSObjectWithLabel),
  placeholder: (base: CSSObjectWithLabel): CSSObjectWithLabel =>
    ({
      ...base,
      color: 'var(--grey-500)',
    } as CSSObjectWithLabel),
  option: (
    base: CSSObjectWithLabel,
    { isSelected }: OptionProps,
  ): CSSObjectWithLabel =>
    ({
      ...base,
      fontSize: '14px',
      cursor: 'pointer',
      transition: 'all 0.1s',
      ...(isSelected && { backgroundColor: 'var(--purple-500)' }),
      color: isSelected ? 'var(--panel-1)' : 'var(--grey-900)',
      ':hover': {
        ...(!isSelected && { backgroundColor: 'var(--grey-100)' }),
      },
      ':active': {
        backgroundColor: 'var(--grey-100)',
      },
    } as CSSObjectWithLabel),
})

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & CustomProps,
): JSX.Element => {
  return (
    <ReactSelect<Option>
      // @ts-ignore
      styles={customStyles({ invalid: props.invalid })}
      menuPortalTarget={document.getElementById('main')!}
      {...props}
      menuIsOpen={props.isOpen}
      noOptionsMessage={() => 'No Options'}
    />
  )
}

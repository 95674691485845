import styled from 'styled-components'
import { PopupActions, PopupPadding, textVariants } from '@/components/ui'
import { Media } from '@/utils/media'

export const PopupContent = styled.div`
  ${textVariants['body-m-regular']}
  padding: ${PopupPadding};
  width: 384px;
  flex-shrink: 1;
  overflow: auto;

  ${Media.Desktop`
    max-height: 450px;
  `}

  a {
    color: var(--purple-500);
  }
`

export const Actions = styled(PopupActions)`
  button {
    width: 100%;
  }
`

import React, { useEffect, useState } from 'react'
import { Step, Steps } from 'intro.js-react'
import { Popup } from '@/components/ui'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useWizardContext } from '@/contexts/Wizard'
import { WelcomeToWizardPopup } from '../Popups/WelcomeToWizard'

const steps: Step[] = [
  {
    element: '#book-call',
    title: 'Welcome to your visa wizard!',
    intro: `Now that you've made a payment for the consultation, you can book your call by clicking on the "Book call" button in this section`,
    position: 'right',
  },
  {
    element: '#heading',
    title: 'To-do list',
    intro:
      'Your To-do list lists out all the action items that your advisor will need you to take. This could be payment requests or files needed.',
    position: 'right',
  },
  {
    element: '#tab-messages',
    title: 'Messages',
    intro:
      'You can chat 1-on-1 with your advisors directly in the messages tab',
    position: 'right',
  },
  {
    element: '#tab-files',
    title: 'Files',
    intro:
      'Your advisor will request files for your application for you to upload in the Files section',
    position: 'right',
  },
  {
    element: '#tab-services',
    title: 'Services',
    intro:
      'Need help with translations, insurance, accommodation, or anything else? Check out our services tab to see how else we can help :)',
    position: 'right',
  },
]

export const WelcomeWizardFlow = () => {
  const { mode } = useWizardContext()

  const [showIntro, setShowIntro] = useLocalStorage('wizard-intro', true)
  const [isTriggered, setTriggered] = useState(false)
  const [step, setStep] = useState(0)

  const handleClose = () => {
    setTriggered(false)
    setStep(0)
    setShowIntro(false)
  }

  useEffect(() => {
    showIntro && mode === 'user' && setTriggered(true)
  }, [mode, showIntro])

  if (!isTriggered) return null

  return (
    <>
      <Popup isOpen={step === 0}>
        <WelcomeToWizardPopup
          onClose={handleClose}
          onConfirm={() => setStep(1)}
        />
      </Popup>
      <Steps
        enabled={step === 1}
        steps={steps}
        initialStep={0}
        onExit={handleClose}
        options={{
          showStepNumbers: true,
          exitOnOverlayClick: false,
          tooltipClass: 'intro-tooltip',
          overlayOpacity: 0,
          showBullets: false,
          disableInteraction: true,
          doneLabel: 'Finish Tour',
        }}
      />
    </>
  )
}

WelcomeWizardFlow.displayName = 'WelcomeWizardFlow'

import styled, { css } from 'styled-components'
import { textVariants } from './Text'

export interface TabItem {
  id: string
  title: string
}

interface Props {
  tabs: TabItem[]
  activeTab: TabItem
  onSelect: (tab: TabItem) => void
}

export const Container = styled.ul`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`

export const Tab = styled.li<{ isActive: boolean }>`
  ${textVariants['body-m-semibold']}
  padding: 10px 16px;
  border-radius: 999px;
  color: var(--grey-600);
  cursor: pointer;

  ${(p) =>
    p.isActive &&
    css`
      color: var(--grey-900);
      background-color: var(--grey-100);
    `}
`

export const Tabs: React.FC<Props> = ({
  tabs,
  activeTab,
  onSelect,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {tabs.map((tab) => (
        <Tab
          id={`tab-${tab.id}`}
          key={tab.id}
          isActive={activeTab.id === tab.id}
          onClick={() => onSelect(tab)}
        >
          {tab.title}
        </Tab>
      ))}
    </Container>
  )
}

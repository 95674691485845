import styled, { css } from 'styled-components'
import { textVariants } from './Text'

interface BadgeProps {
  variant: 'green' | 'grey' | 'rose'
}

export const Badge = styled.span<BadgeProps>`
  ${textVariants['body-m-semibold']}
  padding: 8px 12px;
  color: var(--panel-1);
  display: inline-flex;
  border-radius: 16px;
  gap: 4px;
  flex-shrink: 0;
  flex-grow: 0;

  ${(p) =>
    p.variant === 'green' &&
    css`
      background-color: var(--green-500);
    `}

  ${(p) =>
    p.variant === 'grey' &&
    css`
      background-color: var(--grey-400);
    `}

    ${(p) =>
    p.variant === 'rose' &&
    css`
      background-color: var(--rose-500);
    `}
`

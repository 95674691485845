import AD from './ad.svg'
import AE from './ae.svg'
import AF from './af.svg'
import AG from './ag.svg'
import AI from './ai.svg'
import AL from './al.svg'
import AM from './am.svg'
import AO from './ao.svg'
import AQ from './aq.svg'
import AR from './ar.svg'
import ARAB from './arab.svg'
import AS from './as.svg'
import AT from './at.svg'
import AU from './au.svg'
import AW from './aw.svg'
import AX from './ax.svg'
import AZ from './az.svg'
import BA from './ba.svg'
import BB from './bb.svg'
import BD from './bd.svg'
import BE from './be.svg'
import BF from './bf.svg'
import BG from './bg.svg'
import BH from './bh.svg'
import BI from './bi.svg'
import BJ from './bj.svg'
import BL from './bl.svg'
import BM from './bm.svg'
import BN from './bn.svg'
import BO from './bo.svg'
import BQ from './bq.svg'
import BR from './br.svg'
import BS from './bs.svg'
import BT from './bt.svg'
import BV from './bv.svg'
import BW from './bw.svg'
import BY from './by.svg'
import BZ from './bz.svg'
import CA from './ca.svg'
import CC from './cc.svg'
import CD from './cd.svg'
import CEFTA from './cefta.svg'
import CF from './cf.svg'
import CG from './cg.svg'
import CH from './ch.svg'
import CI from './ci.svg'
import CK from './ck.svg'
import CL from './cl.svg'
import CM from './cm.svg'
import CN from './cn.svg'
import CO from './co.svg'
import CP from './cp.svg'
import CR from './cr.svg'
import CU from './cu.svg'
import CV from './cv.svg'
import CW from './cw.svg'
import CX from './cx.svg'
import CY from './cy.svg'
import CZ from './cz.svg'
import DE from './de.svg'
import DG from './dg.svg'
import DJ from './dj.svg'
import DK from './dk.svg'
import DM from './dm.svg'
import DO from './do.svg'
import DZ from './dz.svg'
import EAC from './eac.svg'
import EC from './ec.svg'
import EE from './ee.svg'
import EG from './eg.svg'
import EH from './eh.svg'
import ER from './er.svg'
import EST from './es-ct.svg'
import ESA from './es-ga.svg'
import ESV from './es-pv.svg'
import ES from './es.svg'
import ET from './et.svg'
import EU from './eu.svg'
import FI from './fi.svg'
import FJ from './fj.svg'
import FK from './fk.svg'
import FM from './fm.svg'
import FO from './fo.svg'
import FR from './fr.svg'
import GA from './ga.svg'
import GBNG from './gb-eng.svg'
import GBIR from './gb-nir.svg'
import GBCT from './gb-sct.svg'
import GBLS from './gb-wls.svg'
import GB from './gb.svg'
import GD from './gd.svg'
import GE from './ge.svg'
import GF from './gf.svg'
import GG from './gg.svg'
import GH from './gh.svg'
import GI from './gi.svg'
import GL from './gl.svg'
import GM from './gm.svg'
import GN from './gn.svg'
import GP from './gp.svg'
import GQ from './gq.svg'
import GR from './gr.svg'
import GS from './gs.svg'
import GT from './gt.svg'
import GU from './gu.svg'
import GW from './gw.svg'
import GY from './gy.svg'
import HK from './hk.svg'
import HM from './hm.svg'
import HN from './hn.svg'
import HR from './hr.svg'
import HT from './ht.svg'
import HU from './hu.svg'
import IC from './ic.svg'
import ID from './id.svg'
import IE from './ie.svg'
import IL from './il.svg'
import IM from './im.svg'
import IN from './in.svg'
import IO from './io.svg'
import IQ from './iq.svg'
import IR from './ir.svg'
import IS from './is.svg'
import IT from './it.svg'
import JE from './je.svg'
import JM from './jm.svg'
import JO from './jo.svg'
import JP from './jp.svg'
import KE from './ke.svg'
import KG from './kg.svg'
import KH from './kh.svg'
import KI from './ki.svg'
import KM from './km.svg'
import KN from './kn.svg'
import KP from './kp.svg'
import KR from './kr.svg'
import KW from './kw.svg'
import KY from './ky.svg'
import KZ from './kz.svg'
import LA from './la.svg'
import LB from './lb.svg'
import LC from './lc.svg'
import LI from './li.svg'
import LK from './lk.svg'
import LR from './lr.svg'
import LS from './ls.svg'
import LT from './lt.svg'
import LU from './lu.svg'
import LV from './lv.svg'
import LY from './ly.svg'
import MA from './ma.svg'
import MC from './mc.svg'
import MD from './md.svg'
import ME from './me.svg'
import MF from './mf.svg'
import MG from './mg.svg'
import MH from './mh.svg'
import MK from './mk.svg'
import ML from './ml.svg'
import MM from './mm.svg'
import MN from './mn.svg'
import MO from './mo.svg'
import MP from './mp.svg'
import MQ from './mq.svg'
import MR from './mr.svg'
import MS from './ms.svg'
import MT from './mt.svg'
import MU from './mu.svg'
import MV from './mv.svg'
import MW from './mw.svg'
import MX from './mx.svg'
import MY from './my.svg'
import MZ from './mz.svg'
import NA from './na.svg'
import NC from './nc.svg'
import NE from './ne.svg'
import NF from './nf.svg'
import NG from './ng.svg'
import NI from './ni.svg'
import NL from './nl.svg'
import NO from './no.svg'
import NP from './np.svg'
import NR from './nr.svg'
import NU from './nu.svg'
import NZ from './nz.svg'
import OM from './om.svg'
import PA from './pa.svg'
import PC from './pc.svg'
import PE from './pe.svg'
import PF from './pf.svg'
import PG from './pg.svg'
import PH from './ph.svg'
import PK from './pk.svg'
import PL from './pl.svg'
import PM from './pm.svg'
import PN from './pn.svg'
import PR from './pr.svg'
import PS from './ps.svg'
import PT from './pt.svg'
import PW from './pw.svg'
import PY from './py.svg'
import QA from './qa.svg'
import RE from './re.svg'
import RO from './ro.svg'
import RS from './rs.svg'
import RU from './ru.svg'
import RW from './rw.svg'
import SA from './sa.svg'
import SB from './sb.svg'
import SC from './sc.svg'
import SD from './sd.svg'
import SE from './se.svg'
import SG from './sg.svg'
import SHC from './sh-ac.svg'
import SHL from './sh-hl.svg'
import SHA from './sh-ta.svg'
import SH from './sh.svg'
import SI from './si.svg'
import SJ from './sj.svg'
import SK from './sk.svg'
import SL from './sl.svg'
import SM from './sm.svg'
import SN from './sn.svg'
import SO from './so.svg'
import SR from './sr.svg'
import SS from './ss.svg'
import ST from './st.svg'
import SV from './sv.svg'
import SX from './sx.svg'
import SY from './sy.svg'
import SZ from './sz.svg'
import TC from './tc.svg'
import TD from './td.svg'
import TF from './tf.svg'
import TG from './tg.svg'
import TH from './th.svg'
import TJ from './tj.svg'
import TK from './tk.svg'
import TL from './tl.svg'
import TM from './tm.svg'
import TN from './tn.svg'
import TO from './to.svg'
import TR from './tr.svg'
import TT from './tt.svg'
import TV from './tv.svg'
import TW from './tw.svg'
import TZ from './tz.svg'
import UA from './ua.svg'
import UG from './ug.svg'
import UM from './um.svg'
import UN from './un.svg'
import US from './us.svg'
import UY from './uy.svg'
import UZ from './uz.svg'
import VA from './va.svg'
import VC from './vc.svg'
import VE from './ve.svg'
import VG from './vg.svg'
import VI from './vi.svg'
import VN from './vn.svg'
import VU from './vu.svg'
import WF from './wf.svg'
import WS from './ws.svg'
import XK from './xk.svg'
import XX from './xx.svg'
import YE from './ye.svg'
import YT from './yt.svg'
import ZA from './za.svg'
import ZM from './zm.svg'
import ZW from './zw.svg'
export const flag = {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  ARAB,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CEFTA,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CP,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DG,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EAC,
  EC,
  EE,
  EG,
  EH,
  ER,
  EST,
  ESA,
  ESV,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GBNG,
  GBIR,
  GBCT,
  GBLS,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  IC,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PC,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SHC,
  SHL,
  SHA,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  UN,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  XX,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
}

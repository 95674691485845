import React, { useMemo, useState } from 'react'
import {
  Button,
  PopupActions,
  PopupTopBar,
  TextField,
  Text,
  Flex,
} from '@/components/ui'
import { InitialPaymentRequestProps } from './types'
import {
  WhiteColumn,
  GreyColumn,
  PopupContent,
  PopupGrid,
  Label,
  Calculation,
  Footer,
  BreakdownItem,
  BreakdownTitle,
  ProposalPrices,
  TotalsContainer,
  RemainingDue,
} from './styles'

export const InitialPaymentRequest = ({
  isLoading,
  initialAmount,
  proposal,
  platformFee = 30,
  onRequest,
  onClose,
  onBack,
}: InitialPaymentRequestProps) => {
  const [amount, setAmount] = useState<number | undefined>(initialAmount)

  const [
    proposedTotal,
    techFee,
    earning,
    dueToday,
    InitialTechFee,
    InitialEarning,
    remainingDue,
  ] = useMemo(() => {
    const initialRequestAmount = amount ? amount : 0

    const prices = proposal?.prices
    const proposedTotal =
      prices?.map((item) => item.price).reduce((a, b) => a + b, 0) || 0

    const crFee = (proposedTotal * platformFee) / 100
    const totalEarning = proposedTotal - crFee

    const initialTechFee = (initialRequestAmount * platformFee) / 100
    const initialEarning = initialRequestAmount - initialTechFee

    const RemainingDue = proposedTotal - initialRequestAmount

    return [
      Math.ceil(proposedTotal).toLocaleString(),
      Math.ceil(crFee).toLocaleString(),
      Math.ceil(totalEarning).toLocaleString(),
      Math.ceil(initialRequestAmount).toLocaleString(),
      Math.ceil(initialTechFee).toLocaleString(),
      Math.ceil(initialEarning).toLocaleString(),
      Math.ceil(RemainingDue).toLocaleString(),
    ]
  }, [amount, platformFee, proposal?.prices])

  const handleCreateProposal = () => {
    const body = {
      ...proposal,
      initialPayment: amount,
    }

    amount && onRequest(body)
  }

  return (
    <PopupGrid>
      <WhiteColumn>
        <PopupTopBar>Request payment</PopupTopBar>
        <PopupContent>
          <ProposalPrices>
            <BreakdownTitle justifyContent="space-between">
              <Text variant="caption-s-medium">Service</Text>
              <Text variant="caption-s-medium">Price</Text>
            </BreakdownTitle>
            {proposal?.prices?.map((item, index) => (
              <BreakdownItem key={index} justifyContent="space-between">
                <Text variant="body-m-regular">{item.title}</Text>
                <Text variant="body-m-regular">
                  ${item.price.toLocaleString()}
                </Text>
              </BreakdownItem>
            ))}
          </ProposalPrices>
          <BreakdownItem justifyContent="space-between" noBorder>
            <Text variant="body-m-semibold">Proposed Total</Text>
            <Text variant="body-m-semibold">${proposedTotal}</Text>
          </BreakdownItem>
          <hr />
          <Label>
            Initial Payment Request
            <TextField
              required
              min={1}
              type="number"
              placeholder="Enter amount in USD"
              value={amount}
              onChange={(e) => setAmount(Number.parseFloat(e.target.value))}
            />
          </Label>
        </PopupContent>
        <PopupActions justifyContent="space-between">
          <Button type="button" variant="grey" size="large" onClick={onBack}>
            Back to editing
          </Button>
          <Button
            variant="black"
            size="large"
            isLoading={isLoading}
            disabled={amount === 0}
            onClick={handleCreateProposal}
          >
            Send proposal
          </Button>
        </PopupActions>
      </WhiteColumn>
      <GreyColumn>
        <PopupTopBar onClose={onClose}>Payment breakdown</PopupTopBar>
        <PopupContent>
          <Calculation>
            <Text variant="body-s-semibold">Proposal Total</Text>
            <TotalsContainer direction="column">
              <Flex justifyContent="space-between">
                <Text variant="body-m-regular">Proposal total</Text>
                <Text variant="body-m-regular">${proposedTotal}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text variant="body-m-regular">
                  Total Technology Fee {platformFee}%
                </Text>
                <Text variant="body-m-regular">${techFee}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text variant="body-m-semibold">Your Total Earning</Text>
                <Text variant="body-m-semibold">${earning}</Text>
              </Flex>
            </TotalsContainer>
            <Text variant="body-s-semibold">Initial Payment Request</Text>
            <TotalsContainer direction="column" hasGradient>
              <Flex justifyContent="space-between">
                <Text variant="body-m-regular">Due today from client</Text>
                <Text variant="body-m-regular">${dueToday}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text variant="body-m-regular">
                  Total Technology Fee {platformFee}%
                </Text>
                <Text variant="body-m-regular">${InitialTechFee}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text variant="body-m-semibold">
                  Your Earning for Initial Payment
                </Text>
                <Text variant="body-m-semibold">${InitialEarning}</Text>
              </Flex>
            </TotalsContainer>
            <RemainingDue justifyContent="space-between">
              <Flex direction="column">
                <Text variant="body-s-semibold">Remaining due from client</Text>
                <Text variant="caption-s-medium">after initial payment</Text>
              </Flex>
              <Text variant="body-s-semibold">${remainingDue}</Text>
            </RemainingDue>
          </Calculation>
          <Footer>
            We are implementing a {platformFee}% Technology Fee on all
            transactions to support the ongoing maintenance and enhancement of
            the Citizen Remote platform.
          </Footer>
        </PopupContent>
      </GreyColumn>
    </PopupGrid>
  )
}

import React from 'react'
import { Button, PopupTopBar, Text } from '@/components/ui'
import { PopupActions, Content } from './styles'

interface ProposalConfirmPopupProps {
  onCancel: () => void
  onConfirm: () => void
}

export const ProposalConfirmPopup: React.FC<ProposalConfirmPopupProps> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <>
      <PopupTopBar onClose={onCancel}>Create Proposal?</PopupTopBar>
      <Content>
        <Text variant="body-m-semibold">
          Are you sure you want to send this proposal?
        </Text>
      </Content>
      <PopupActions justifyContent="right" gap={16}>
        <Button variant="grey" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="black" size="large" onClick={onConfirm}>
          Confirm
        </Button>
      </PopupActions>
    </>
  )
}

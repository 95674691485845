import styled from 'styled-components'
import { Flex, textVariants } from '@/components/ui'
import { Media } from '@/utils/media'
import {
  DefaultPopupContentWrapper,
  FullScreenMobileContainer,
} from '@/components/uiJar/utilityStyles'

export const PopupContainer = styled(FullScreenMobileContainer)``
export const PopupContent = styled(DefaultPopupContentWrapper)``
export const PopupActions = styled(Flex)`
  border-top: 1px solid var(--neutral-alpha-4);
  padding: 16px 16px;
  ${Media.Desktop`
    padding: 16px 20px;
    `}
  button {
    width: 100%;
  }
`
export const PopupHeading = styled.div`
  position: relative;
  padding: 14px 16px;
  ${Media.Tablet`
      display: none;
      `}
`
export const HeadingTitle = styled.div`
  ${textVariants['semibold-4']}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const Hero = styled(Flex)`
  flex-direction: column;
  gap: 20px;
  height: 176px;
  i {
    color: var(--success-9);
  }
`

import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from './Icon'

const Container = styled.div<{ size: number }>`
  display: flex;
  gap: ${(p) => p.size * 0.3}px;

  i {
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
  }
`

const Star = styled(Icon.Solid)<{ isFilled: boolean; isStatic: boolean }>`
  color: ${(p) => (p.isFilled ? 'var(--purple-500)' : 'var(--grey-200)')};
  ${(p) =>
    !p.isStatic &&
    css`
      cursor: pointer;
    `}
`

interface RatingProps {
  rating: number
  onChange?: (rating: number) => void
  size?: number
}

export const Rating = ({
  rating,
  onChange,
  size = 40,
  ...rest
}: RatingProps) => {
  const handleClick = (rating: number) => {
    onChange && onChange(rating)
  }

  return (
    <Container size={size} {...rest}>
      {_.times(5, (index) => (
        <Star
          key={index}
          name="star"
          isFilled={index < rating}
          isStatic={!onChange}
          onClick={() => handleClick(index + 1)}
        />
      ))}
    </Container>
  )
}

import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import { Text } from './Text'

const Container = styled.span`
  display: inline-flex;
  align-items: flex-end;
`

export const Logo = (props: any) => {
  return (
    <Container {...props}>
      <Icon.Base name="LogoFull" width={144} />
    </Container>
  )
}

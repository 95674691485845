import { OtherIcon } from '../Icon'

interface Item {
  icon: OtherIcon
  title: string
  message: string
  new?: boolean
  comingSoon?: boolean
}

export const ITEMS: Item[] = [
  {
    icon: 'robot',
    title: `Trip - Citizen Remote's AI chat bot`,
    message: `Let's get Social! Post your highlights and interact with other remoters!`,
    new: true,
  },
  {
    icon: 'headAvatar',
    title: 'Get the full Social experience',
    message: `Get full access to events and become a host of your own parties!`,
  },
  {
    icon: 'group2Users',
    title: 'Even Stronger Connections',
    message: `View other people's profiles and find other remoters near you!`,
  },
  {
    icon: 'earthGlobe',
    title: 'Explore Further',
    message: `Get unlimited Dating swipes to find that special someone!`,
  },
  {
    icon: 'magicWand',
    title: 'Visa Wizard',
    message: `Discover even more details for each country with additional data!`,
  },
  {
    icon: 'bubblesMessages',
    title: 'Limitless Messaging',
    message: `Endless opportunities to interact with other remoters, message first and create group chats!`,
    comingSoon: true,
  },
  {
    icon: 'notes',
    title: 'Enjoy The Journey',
    message: `Apply for a visa or get help applying with our team of advisors!`,
    comingSoon: true,
  },
  {
    icon: 'emojiSmilingFace',
    title: 'Dating',
    message: `Relax in the knowing that your accommodation application is top of the list!`,
    comingSoon: true,
  },
  {
    icon: 'celebrateParty',
    title: 'Join The Events',
    message: `Get ready for more upcoming features and new adventures!`,
    comingSoon: true,
  },
]

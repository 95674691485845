import React, { InputHTMLAttributes, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { textVariants } from './Text'

interface Props
  extends InputHTMLAttributes<HTMLInputElement>,
    PropsWithChildren {}

const Control = styled.span<{ hasContent: boolean }>`
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  border: 2px solid var(--grey-200);
  transition: 0.1s all;
  border-radius: 999px;
  margin-right: ${(p) => (p.hasContent ? '8px' : '0px')};
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    display: none;
  }
`

const Container = styled.label`
  ${textVariants['body-m-regular']}
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${Control}:after {
    margin-top: 5px;
    width: 10px;
    height: 10px;
    background-color: var(--purple-500);
    border-radius: 999px;
  }
  &:hover input ~ ${Control} {
    border-color: var(--grey-300);
  }
  input:checked ~ ${Control} {
    border-color: var(--purple-500);
  }
  input:checked ~ ${Control}:after {
    display: block;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

const Component: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <Container as={children ? 'label' : 'div'} className={className}>
      <Input type="radio" {...rest} />
      <Control hasContent={!!children} />
      {children}
    </Container>
  )
}

export const Radio = React.memo(Component)

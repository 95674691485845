import React from 'react'
import {
  FlagIcon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '@/components/ui/Icon'
import { InputPrefixTextWrapper, InputPrefixWrapper } from './styles'
import IconRenderer from '../helper/IconRenderer'
import { InputSize } from '.'

interface IconProps {
  type: IconType
  name?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
}

const iconSize: Record<InputSize, number> = {
  1: 18,
  2: 20,
  3: 24,
  4: 24,
}

const chevronIconSize: Record<InputSize, number> = {
  1: 16,
  2: 20,
  3: 24,
  4: 24,
}

type InputPrefixProps = {
  size: InputSize
  orientation: 'left' | 'right'
  icon?: IconProps
  text?: string
  onClick?: () => void
}
const InputPrefix: React.FC<InputPrefixProps> = (props) => {
  const { icon, text, size, orientation, onClick } = props
  const isFlagPrefix = icon?.type === 'Flag' && text

  return (
    <InputPrefixWrapper
      size={size}
      className="inputPrefix"
      orientation={orientation}
      onClick={onClick}
    >
      {icon && (
        <IconRenderer
          size={size}
          sizeMap={iconSize}
          iconName={icon.name}
          iconType={icon.type}
        />
      )}
      <InputPrefixTextWrapper size={size}>
        <span>{text}</span>
        {isFlagPrefix && (
          <IconRenderer
            size={size}
            sizeMap={chevronIconSize}
            iconName={'chevronUpDown'}
            iconType={'Solid'}
          />
        )}
      </InputPrefixTextWrapper>
    </InputPrefixWrapper>
  )
}

export default InputPrefix

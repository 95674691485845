import React, { InputHTMLAttributes, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from './Icon'
import { Text } from './Text'

const Container = styled.label<{ isHover: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 178px;
  border: 1px dashed var(--purple-500);
  border-radius: 16px;
  color: var(--purple-500);
  cursor: pointer;

  ${(p) =>
    p.isHover &&
    css`
      border-style: solid;
      background-color: var(--purple-100);
    `}

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    left: -100;
    top: -100;
  }

  i {
    margin-bottom: 16px;
  }

  > :last-child {
    color: var(--grey-500);
  }
`

const Subtitle = styled(Text)`
  text-align: center;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onSelect'> {
  onSelect: (files: File[]) => void
  title?: string
  subtitle?: string
}

export const FileField = ({
  title = 'Select a file to upload',
  subtitle = 'or drag and drop it here to upload',
  onSelect,
  className,
  ...rest
}: Props) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return
    onSelect(Array.from(e.target.files))
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    setIsHovering(true)
  }

  const handleDrop = (e: React.DragEvent) => {
    setIsHovering(false)
    e.preventDefault()

    if (e.dataTransfer.items) {
      const items = Array.from(e.dataTransfer.items)
      items.some((item) => {
        const file = item.getAsFile()
        if (file) {
          onSelect([file])
          return true
        }
      })
    } else {
      const files = Array.from(e.dataTransfer.files)
      if (files.length > 0) {
        onSelect(files)
      }
    }
  }

  return (
    <Container
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={() => setIsHovering(false)}
      className={className}
      isHover={isHovering}
    >
      <Icon.Solid name="documentPlus" size={32} />
      <Text variant="body-m-semibold">{title}</Text>
      <Subtitle variant="body-s-regular">{subtitle}</Subtitle>
      <input type="file" onChange={handleFile} {...rest} />
    </Container>
  )
}

export const stripeCustomAppearance = (theme: 'light' | 'dark') => {
  const isLight = theme === 'light'
  return {
    variables: {
      fontFamily: 'Inter-display, sans-serif',
      fontLineHeight: '1.5',
      borderRadius: '8px',
      colorBackground: isLight ? '#00104010' : '#e5e5e51a',
      colorPrimary: isLight ? '#1E1F24' : '#eeeeee',
      spacingGridRow: '24px',
      spacingGridColumn: '8px',
      colorText: isLight ? '#101828' : '#fcfcfcb2',
      colorTextPlaceholder: isLight ? '#0002119D' : '#fcfcfcb2',
    },
    rules: {
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px',
      },
      '.Input': {
        padding: '10px',
        border: '1px solid transparent',
        fontSize: '15px',
        lineHeight: '20px',
      },
      '.Input:placeholder': {
        fontSize: '15px',
        lineHeight: '20px',
      },
      '.Input:hover': {
        backgroundColor: isLight ? '#000B3618' : '#ffffff1c',
      },
      '.Input:focus': {
        border: `1px solid ${isLight ? '#00104010' : '#e5e5e51a'}`,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray',
      },
      '.Input--invalid': {
        boxShadow: 'none',
        backgroundColor: isLight ? '#FF010110' : '#f66a7a3d',
        border: 'none',
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none',
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow:
          '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
      '.Label': {
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '20px',
        marginBottom: '8px',
      },
      '.Error': {
        color: isLight ? '#C62A2F' : '#ff8589',
        fontSize: '12px',
        lineHeight: '16px',
        marginLeft: '4px',
      },
    },
  }
}

import React, { useMemo } from 'react'
import { Popup } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { Badge } from '@/components/uiJar/badge'
import { PremiumUpgradeReason } from '@/contexts/PremiumSubscription/types'
import { YEARLY_SUBSCRIPTION_PRICE } from '@/contexts/PremiumSubscription/constants'
import { useTheme } from '@/contexts/Theme'
import {
  PopupContainer,
  PopupContent,
  Hero,
  HeroImage,
  TagLine,
  Body,
  CloseWrapper,
  Footer,
} from './styles'

import { ITEMS } from './constants'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { imageUrl } from '../Image'

type UpgradePopupProps = {
  opened: boolean
  onClose: () => void
  onProceed: () => void
  reason?: PremiumUpgradeReason
}

export const UpgradePopup = ({
  onProceed,
  opened,
  onClose,
  reason,
}: UpgradePopupProps) => {
  const { theme } = useTheme()
  const isDark = theme === 'dark'
  const illustrationUrl = useMemo(() => {
    switch (reason) {
      case PremiumUpgradeReason.SETTING:
        return imageUrl('/img/premium/citizen-remote.svg')
      case PremiumUpgradeReason.ACCESS_SOCIAL:
        return imageUrl('/img/premium/social.svg')
      default:
        return imageUrl('/img/premium/citizen-remote.svg')
    }
  }, [reason])

  const title = useMemo(() => {
    switch (reason) {
      case PremiumUpgradeReason.SETTING:
        return 'Citizen Remote Premium'
      case PremiumUpgradeReason.ACCESS_SOCIAL:
        return 'Get the full Social experience'
      default:
        return 'Citizen Remote Premium'
    }
  }, [reason])
  return (
    <Popup fullScreenInMobile isOpen={opened}>
      <PopupContainer>
        <CloseWrapper justifyContent="flex-end">
          <Button
            icon={{ startIconName: 'crossSmall', type: 'Solid' }}
            size={2}
            variant="ghost"
            colorTheme="neutral"
            onClick={onClose}
          />
        </CloseWrapper>

        <PopupContent>
          <Hero>
            <HeroImage
              alt={`Premium subscription illustration for ${title}`}
              src={illustrationUrl}
            />
            <Flex direction="column" gap={4}>
              <Text variant="semibold-6">{title}</Text>
              <Text variant="regular-4" colorVar="neutral-11">
                Go beyond the limits and unlock dozens of exclusive features by
                subscribing to Citizen Remote Premium.
              </Text>
            </Flex>
          </Hero>
          <TagLine>
            Get Citizen Remote Premium for just ${YEARLY_SUBSCRIPTION_PRICE}
            /year!
          </TagLine>
          <Body>
            <Text variant="medium-3" colorVar="neutral-11">
              What’s included in Premium?
            </Text>
            <Flex direction="column" gap={24} as="ul">
              {ITEMS.map((item) => (
                <Flex key={item.title} gap={8} as="li">
                  <Icon.Base name={item.icon} size={20} />
                  <Text
                    variant="medium-3"
                    colorVar={item.comingSoon ? 'neutral-11' : 'neutral-12'}
                  >
                    {item.title}
                  </Text>
                  {item.new && (
                    <Badge size={1} variant="soft" colorTheme="accent">
                      new
                    </Badge>
                  )}
                  {item.comingSoon && (
                    <Badge size={1} variant="soft" colorTheme="neutral">
                      coming soon
                    </Badge>
                  )}
                </Flex>
              ))}
            </Flex>
          </Body>
        </PopupContent>
        <Footer $isDark={isDark}>
          <Button
            fullWidth
            variant="solid"
            colorTheme="neutral"
            size={3}
            onClick={onProceed}
          >
            Subscribe for ${YEARLY_SUBSCRIPTION_PRICE}/year
          </Button>
          <Flex direction="column">
            <Text variant="medium-2" colorVar="neutral-9">
              By subscribing to premium, you agree to our
            </Text>
            <Text variant="medium-2" colorVar="neutral-9">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://citizenremote.com/terms-of-use/"
              >
                Terms of Service
              </a>{' '}
              and accept our{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://citizenremote.com/eula/'}
              >
                EULA
              </a>
            </Text>
          </Flex>
        </Footer>
      </PopupContainer>
    </Popup>
  )
}

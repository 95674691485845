import { AxiosInstance } from 'axios'
import { IUserSocialLink, IUserSocialLinkType } from '@/models/IUserSocialLink'
import { INotificationSettings } from '@/models/INotificationSettings'
import { IUser } from '@/models/IUser'

export interface IUserSocialLinkPayload {
  links: Array<{ type: IUserSocialLinkType; link: string }>
}

export interface ILeaveFeedbackPayload {
  category: string
  feedback: string
}

export interface ISetNotificationSettingsPayload {
  newMessages: boolean
  newMessageRequest: boolean
  acceptedMessageRequests: boolean
  removedFromGroupChat: boolean
  newLikesOnYourPost: boolean
  newCommentsOnYourPost: boolean
  newCommentsLikesOnYourPost: boolean
  newCommentsOnYourThread: boolean
  mentions: boolean
}

export class SettingsService {
  constructor(private instance: AxiosInstance) {}
  updateSocialLinks = (body: IUserSocialLinkPayload) =>
    this.instance.patch<IUserSocialLink[]>('/users/me/social-links', body)
  getConnectedAccountsState = () =>
    this.instance.get<any>('/users/connected-accounts')

  changePassword = (payload: { oldPassword: string; newPassword: string }) =>
    this.instance.post('users/change-password', payload)

  // *** FEEDBACK ***
  leaveFeedback = (payload: ILeaveFeedbackPayload) =>
    this.instance.post('/feedback', payload)

  // *** NOTIFICATIONS ***
  getNotificationSettings = () =>
    this.instance.get<INotificationSettings>('/notifications/config')

  setNotificationSettings = (params: ISetNotificationSettingsPayload) =>
    this.instance.post<INotificationSettings>('/notifications/config', params)

  // *** Users ***
  blockUser = (id: string) => this.instance.post(`/users/${id}/block`, {})
  unblockUser = (id: string) => this.instance.post(`/users/${id}/unblock`, {})
  getBlockedUser = (id: string) => this.instance.get(`/users/${id}/entity`)
  getBlockedUsers = () => this.instance.get<IUser[]>('/users/blocked')
}

import styled, { css } from 'styled-components'

interface IconContainerProps {
  size: number
  variant?:
    | 'blue'
    | 'orange'
    | 'purple'
    | 'purple-light'
    | 'grey'
    | 'green'
    | 'rose'
    | 'lightGrey'
  isActive?: boolean
  colorVar?: string
}

export const IconContainer = styled.span<IconContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  flex-shrink: 0;
  flex-grow: 0;
  color: ${(p) => (p.colorVar ? `var(--${p.colorVar})` : 'var(--panel-1)')};
  ${(p) =>
    p.variant === 'blue' &&
    css`
      background-color: var(--blue-500);
    `}

  ${(p) =>
    p.variant === 'orange' &&
    css`
      background-color: var(--orange-500);
    `}

    ${(p) =>
    p.variant === 'purple' &&
    css`
      background-color: var(--purple-500);
    `}

    ${(p) =>
    p.variant === 'purple-light' &&
    css`
      color: var(--purple-900);
      background-color: var(--purple-200);
    `}

    ${(p) =>
    p.variant === 'grey' &&
    css`
      background-color: var(--grey-900);
    `}

    ${(p) =>
    p.variant === 'lightGrey' &&
    css`
      background-color: var(--grey-400);
    `}

    ${(p) =>
    p.variant === 'green' &&
    css`
      background-color: var(--green-500);
    `}

    ${(p) =>
    p.variant === 'rose' &&
    css`
      background-color: var(--rose-600);
    `}
`

import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

type CircleButtonSize = 'large' | 'medium'
type CircleButtonVariant = 'solid' | 'transparent' | 'dark-solid' | 'purple'

interface CircleButtonProps {
  size?: CircleButtonSize
  variant: CircleButtonVariant
}

const variantsMap: Record<CircleButtonVariant, FlattenSimpleInterpolation> = {
  solid: css`
    background-color: var(--grey-100);
    color: var(--grey-600);

    &:hover {
      background-color: transparent;
      color: var(--grey-900);
    }
  `,

  transparent: css`
    background-color: transparent;
    color: var(--grey-600);

    &:hover {
      background-color: var(--grey-100);
      color: var(--grey-900);
    }
  `,
  'dark-solid': css`
    background-color: var(--grey-800);
    color: var(--panel-1);
  `,
  purple: css`
    background-color: var(--purple-500);
    color: var(--panel-1);
  `,
}

export const CircleButton = styled.button<CircleButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  border: none;
  cursor: pointer;
  transition: 0.1s all;
  background-color: transparent;
  padding: 0;
  width: ${(p) => (p.size || 'medium' === 'medium' ? 36 : 52)}px;
  height: ${(p) => (p.size || 'medium' === 'medium' ? 36 : 52)}px;

  ${(p) => variantsMap[p.variant || 'purple']}
`

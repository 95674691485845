import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  PopupTopBar,
  Select,
  TextField,
  Text,
  PopupActions,
  Button,
  Flex,
} from '@/components/ui'
import { certificationSupportedLangList } from '@/constants/languages'
import {
  Eula,
  FromTwoContainer,
  Label,
  PopupContent,
  PricingContainer,
  PricingLabel,
} from './styles'
import {
  DocumentCertificationData,
  DocumentCertificationPopupProps,
  Option,
} from './types'

const options: Option[] = [{ value: 'translation', label: 'Translations' }]

export const DocumentCertificationPopup = ({
  onClose,
  onContinue,
}: DocumentCertificationPopupProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DocumentCertificationData>()

  const pages = watch('totalPages')
  const amount = useMemo(() => {
    if (pages == 1) return 35
    return pages * 25
  }, [pages])

  const onSubmit = (data: DocumentCertificationData) => {
    onContinue({
      helpWith: data.helpWith.value,
      totalPages: data.totalPages,
      fromLang: data.fromLang.value,
      toLang: data.toLang.value,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PopupTopBar onClose={onClose}>Document Certifications</PopupTopBar>
      <PopupContent>
        <Text variant="body-m-regular">
          Looking for assistance with Certifications? Let us help you! Select
          document you need help with
        </Text>
        <Label>
          I need help with
          <Controller
            rules={{ required: true }}
            name="helpWith"
            control={control}
            render={({ field }) => (
              <Select
                size={1}
                invalid={!!errors.helpWith}
                {...field}
                options={options}
              />
            )}
          />
        </Label>
        <Label>
          How many total pages do you have?
          <TextField
            type="number"
            placeholder="Number of pages"
            min={1}
            invalid={!!errors.totalPages}
            {...register('totalPages', { required: true })}
          />
        </Label>
        <FromTwoContainer>
          <Label>
            From
            <Controller
              rules={{ required: true }}
              name="fromLang"
              control={control}
              render={({ field }) => (
                <Select
                  size={1}
                  invalid={!!errors.fromLang}
                  {...field}
                  options={certificationSupportedLangList}
                />
              )}
            />
          </Label>
          <Label>
            To
            <Controller
              rules={{ required: true }}
              name="toLang"
              control={control}
              render={({ field }) => (
                <Select
                  size={1}
                  invalid={!!errors.toLang}
                  {...field}
                  options={certificationSupportedLangList}
                />
              )}
            />
          </Label>
        </FromTwoContainer>
        <PricingContainer>
          <PricingLabel variant="body-s-semibold">Total price</PricingLabel>
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="heading-h3">${amount || 0}</Text>
            <Eula variant="body-s-regular">
              Price is based on the amount of pages you need to translate
            </Eula>
          </Flex>
        </PricingContainer>
      </PopupContent>
      <PopupActions>
        <Button variant="grey" size="large" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="black" size="large" type="submit">
          Continue
        </Button>
      </PopupActions>
    </form>
  )
}

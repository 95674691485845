import React from 'react'
import { DateTime } from 'luxon'
import { noop } from 'lodash'
import { Popup } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { IconLink } from '@/components/uiJar/IconLink'
import { usePremiumSubscription } from '@/contexts/PremiumSubscription'
import useApi from '@/hooks/useApi'
import {
  PopupContainer,
  PopupContent,
  Divider,
  SubscriptionNote,
  FooterActions,
  PopupHeading,
  HeadingTitle,
} from './styles'

import { Text } from '../../Text'
import { Flex } from '../../Flex'
import { Icon } from '../../Icon'

type ConfirmCancelSubscriptionPopupProps = {
  isOpen: boolean
  onClose: () => void
  afterCancel: (note: string) => void
}

const ConfirmCancelSubscriptionPopup = (
  props: ConfirmCancelSubscriptionPopupProps,
) => {
  const { api, status } = useApi()
  const { isOpen, onClose, afterCancel } = props
  const { subscriptionExpiry, subscription } = usePremiumSubscription()

  const date = subscriptionExpiry
    ? DateTime.fromISO(subscriptionExpiry as string)
    : ''

  const handleCancel = () => {
    const { subscriber } = subscription || {}
    if (!subscriber) return

    const { management_url, entitlements, subscriptions } = subscriber
    if (management_url) {
      // if we have url from revenuecat, redirect to it
      return (window.location.href = management_url)
    }

    if (entitlements.pro) {
      const {
        pro: { product_identifier },
      } = entitlements
      const subscriptionItem = subscriptions[product_identifier]

      if (subscriptionItem.store === 'stripe') {
        api.payments
          .cancelSubscription()
          .then(
            (data) =>
              data &&
              afterCancel(
                `Your subscription has been cancelled. You will be able to access premium service till the end of your billing cycle (${
                  date ? date.toFormat('MMMM d, yyyy') : '-'
                }).`,
              ),
          )
          .catch(noop)
      } else if (subscriptionItem.store === 'app_store') {
        afterCancel(
          'It seems you upgraded to premium from our iOS app, please head to app settings on your iOS powered device to cancel',
        )
      } else if (subscriptionItem.store === 'play_store') {
        afterCancel(
          'It seems you upgraded to premium from our android app, please head to app settings on your android powered device to cancel',
        )
      }
    } else {
      afterCancel('It seems you are already on free plan')
    }
  }

  return (
    <Popup fullScreenInMobile isOpen={isOpen} onClose={onClose}>
      <PopupContainer>
        <PopupHeading>
          <IconLink
            colorTheme="neutral"
            size={4}
            name="arrowLeft"
            type="Solid"
            onClick={onClose}
          />
          <HeadingTitle>Cancel subscription</HeadingTitle>
        </PopupHeading>
        <PopupContent>
          <Flex direction="column" gap={4}>
            <Text variant="semibold-4">Are you sure you want to cancel?</Text>
            <Text variant="regular-3" colorVar="neutral-11">
              {`If you cancel your subscription, you’ll have access to all premium
              features until your subscription period ends. Then you’ll lose all
              access to all premium features in Citizen Remote app.`}
            </Text>
          </Flex>
          <Divider />
          <SubscriptionNote>
            <Icon.Solid name="exclaimationCircle" size={20} />
            <Text variant="regular-3" colorVar="neutral-11">
              You will keep your access to all Citizen Remote Premium features
              until {date ? date.toFormat('MMMM d, yyyy') : '-'}.
            </Text>
          </SubscriptionNote>
          <Divider />
        </PopupContent>
        <FooterActions>
          <Button
            variant="outline"
            colorTheme="neutral"
            size={3}
            fullWidth
            onClick={onClose}
          >
            Not now
          </Button>
          <Button
            disabled={status === 'loading'}
            isLoading={status === 'loading'}
            fullWidth
            variant="solid"
            colorTheme="danger"
            size={3}
            onClick={handleCancel}
          >
            Cancel subscription
          </Button>
        </FooterActions>
      </PopupContainer>
    </Popup>
  )
}

export default ConfirmCancelSubscriptionPopup

import styled, { css } from 'styled-components'
import {
  Flex,
  PopupPadding,
  Text,
  textVariants,
  FileField as FileFieldBase,
  TextField,
} from '@/components/ui'

export const PopupGrid = styled(Flex)`
  max-height: 650px;
  min-height: 0;
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${PopupPadding};
  overflow: auto;
`

export const MarkdownContent = styled.div`
  ${textVariants['body-m-regular']}
  flex-shrink: 1;
  overflow: auto;

  a {
    color: var(--purple-500);
  }
`

export const DocumentPreview = styled(Flex)<{ isViewer?: boolean }>`
  width: 384px;
  ${(p) =>
    p.isViewer &&
    css`
      height: 550px;
    `}
  background-color: ${(p) =>
    p.isViewer ? 'var(--panel-1)' : 'var(--grey-100)'};
  border-right: 0.4px solid var(--grey-300);
  flex-direction: column;
`

export const UploadSection = styled(Flex)`
  width: 416px;
  flex-direction: column;
`

export const FileContainer = styled.div<{ url: string }>`
  height: 245px;
  position: relative;
  background-color: var(--panel-1);
  border: 1px solid var(--grey-200);
  border-radius: 16px;
  margin: 24px 0px;
  overflow: hidden;
  background-image: url('${(p) => p.url}');
  background-position: top center;
  background-size: cover;
`

export const FilePreviewCTA = styled(Flex)`
  position: absolute;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--panel-1);
  padding: 0px 20px;
  left: 0;
  right: 0;
  bottom: 0;
`

export const FileList = styled.ul`
  margin: 16px 0px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;

  li {
    padding: 0px 6px;
  }

  i {
    margin-right: 8px;
    color: var(--grey-500);
    flex-shrink: 0;
  }

  button {
    flex-shrink: 0;
  }

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`
export const FileItem = styled.li`
  display: flex;
  align-items: center;
`

export const FileName = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FileField = styled(FileFieldBase)`
  margin-bottom: 32px;
  flex-shrink: 0;
`

export const LabelField = styled(TextField)`
  margin-bottom: 15px;
`

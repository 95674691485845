import styled from 'styled-components'
import {
  CRImage,
  Flex,
  PopupActions,
  PopupPadding,
  Text,
  textVariants,
} from '@/components/ui'

export const PopupContent = styled.div`
  width: 384px;
  height: 500px;
  max-height: 632px;
  padding: ${PopupPadding};

  ol {
    list-style: none;
    counter-reset: item;
    margin-top: 32px;
    margin-left: 40px;

    li {
      left: 0;
      position: relative;
      counter-increment: item;
    }

    li:before {
      left: -40px;
      position: absolute;
      ${textVariants['body-m-semibold']}
      margin-right: 10px;
      content: counter(item);
      background-color: var(--grey-100);
      border-radius: 100%;
      color: var(--grey-900);
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    > li:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

export const TopBar = styled(Flex)`
  margin-bottom: 20px;
`

export const Cover = styled(CRImage)`
  position: relative;
  height: 224px;
  background-color: var(--grey-100);
  background-position: center top;

  button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`

export const Title = styled(Text)`
  text-align: center;
  margin: 20px 0px 8px;
`

export const Subtitle = styled(Text)`
  text-align: center;
  width: 80%;
  margin: 0 auto;
`

export const Items = styled.ul`
  margin-top: 20px;
`

export const Item = styled.li`
  ${textVariants['body-m-semibold']}
  display: flex;
  align-items: center;
  padding: 16px;

  i {
    color: var(--purple-500);
    margin-right: 12px;
  }
`

export const ScrollLabel = styled(Text)`
  color: var(--grey-500);
  padding: 8px 46px 0px;
`

export const ReviewsTitle = styled(Text)`
  margin: 60px 0 8px;
  text-align: center;
`

export const Reviews = styled.ul`
  margin: 32px 0px;

  li:not(:last-child) {
    border-bottom: 0.4px solid var(--grey-300);
  }
`

export const Review = styled.li`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
`

export const ReviewTitle = styled(Flex)`
  align-items: center;
  margin-bottom: 8px;
  gap: 4px;

  > :first-child {
    margin-right: 4px;
  }

  > :last-child {
    color: var(--grey-500);
  }
`

export const PopupNotice = styled(Text)`
  color: var(--grey-500);
  padding: ${PopupPadding};
`

export const Actions = styled(PopupActions)`
  justify-content: center;
  flex-direction: column;

  p {
    color: var(--grey-500);
    text-align: center;
  }
`

import React, { ButtonHTMLAttributes } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Icon } from './Icon'
import { textVariants } from './Text'

type ButtonSize = 'large' | 'medium' | 'small'
type ButtonVariant =
  | 'primary'
  | 'primary-ghost'
  | 'secondary'
  | 'secondary-ghost'
  | 'black'
  | 'black-ghost'
  | 'black-solid'
  | 'grey'
  | 'grey-ghost'
  | 'white'
  | 'white-ghost'
  | 'cancel'

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  variant?: ButtonVariant
  size?: ButtonSize
  icon?: React.ReactNode
  isLoading?: boolean
  isInline?: boolean
  as?: any
  isGhost: boolean
  hasContent: boolean
  /**
   * Fills the width
   */
  block?: boolean
  borderRadius?: number
  marginH?: number
  border?: boolean
}

const sizeCss = css<ButtonProps>`
  ${({ size, isGhost }) =>
    size === 'small' &&
    css`
      ${textVariants['body-s-semibold']}
      ${!isGhost &&
      css`
        padding: 8px 12px;
      `}
    `}

  ${({ size, isGhost }) =>
    size === 'medium' &&
    css`
      ${textVariants['body-s-semibold']}
      ${!isGhost &&
      css`
        padding: 12px;
      `}
    `}
  
    ${({ size, isGhost }) =>
    size === 'large' &&
    css`
      ${textVariants['body-m-semibold']}
      ${!isGhost &&
      css`
        padding: 13px 18px;
      `}
    `}
    ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius}px;
    `}
`

const variantsMap: Record<ButtonVariant, FlattenSimpleInterpolation> = {
  primary: css`
    background-color: var(--purple-500);
    color: var(--panel-1);

    &:hover {
      background-color: var(--purple-600);
    }
  `,
  'primary-ghost': css`
    color: var(--purple-500);

    &:hover {
      color: var(--purple-600);
    }
  `,
  secondary: css`
    background-color: var(--purple-50);
    color: var(--purple-500);

    &:hover {
      background-color: var(--purple-100);
    }
  `,
  'secondary-ghost': css`
    color: var(--purple-300);

    &:hover {
      color: var(--purple-500);
    }
  `,
  black: css`
    background-color: var(--grey-900);
    color: var(--panel-1);

    &:hover {
      background-color: var(--grey-700);
    }
  `,
  'black-solid': css`
    background-color: var(--grey-800);
    color: var(--panel-1);

    &:hover {
      background-color: var(--grey-700);
    }
  `,
  'black-ghost': css`
    color: var(--grey-900);

    &:hover {
      color: var(--grey-700);
    }
  `,
  grey: css`
    background-color: var(--grey-100);
    color: var(--grey-600);

    &:hover {
      background-color: var(--grey-200);
      color: var(--grey-900);
    }
  `,
  'grey-ghost': css`
    color: var(--grey-500);

    &:hover {
      color: var(--grey-900);
    }
  `,
  white: css`
    background-color: var(--panel-1);
    color: var(--grey-900);

    &:hover {
      color: var(--grey-700);
    }
  `,
  'white-ghost': css`
    color: var(--panel-1);

    &:hover {
      color: var(--panel-1);
    }
  `,
  cancel: css`
    color: white;
    background-color: var(--danger-9);
  `,
}

const StyledButton = styled.button<ButtonProps>`
  display: ${(p) => (p.isInline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  border: none;
  cursor: pointer;
  transition: 0.1s all;
  background-color: transparent;
  padding: 0;

  ${sizeCss}
  ${(p) => !p.disabled && variantsMap[p.variant || 'primary']}

  ${(p) =>
    !p.hasContent &&
    css`
      width: 36px;
      height: 36px;
      padding: 0;
    `}

    ${(p) =>
    p.hasContent &&
    css`
      i {
        margin-right: 10px;
      }
    `}
  ${(p) =>
    p.disabled &&
    css`
      color: var(--grey-300);
      background-color: var(--grey-100);
    `}

  ${(p) =>
    p.block
      ? css`
          width: 100%;
        `
      : ``}
  ${(p) =>
    p.marginH
      ? css`
          margin-left: ${p.marginH}px;
          margin-right: ${p.marginH}px;
        `
      : ``}
  ${(p) =>
    p.border
      ? css`
          border: 2px solid black;
        `
      : ``}
`

export const Button = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'isGhost' | 'hasContent'>
>(
  (
    {
      icon,
      variant,
      size,
      isLoading = false,
      isInline = false,
      disabled,
      children,
      ...rest
    },
    ref,
  ) => (
    <StyledButton
      ref={ref}
      variant={variant}
      size={size || 'medium'}
      isGhost={variant?.includes('ghost')}
      hasContent={children !== undefined}
      isInline={isInline}
      disabled={isLoading || disabled}
      {...rest}
    >
      {icon && !isLoading && icon}
      {isLoading && (
        <Icon.Base name="Loading" size={size === 'large' ? 24 : 20} />
      )}
      {children}
    </StyledButton>
  ),
)

Button.displayName = 'Button'

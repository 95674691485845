import styled from 'styled-components'
import { Flex, PopupPadding, Text, TextField } from '@/components/ui'
import { HoverLabel } from '@/components/ui/Misc'

export const Content = styled.div`
  width: 384px;
`

export const Options = styled.div`
  padding: 0px ${PopupPadding};
  height: 360px;
  overflow: auto;
  & > :not(:last-child) {
    border-bottom: 0.4px solid var(--grey-300);
  }
`

export const Option = styled(HoverLabel).attrs({
  size: 8,
})`
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`

export const OptionContent = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  max-width: 70%;
  gap: 4px;

  * {
    width: 100%;
  }
`

export const Actions = styled(Flex)`
  padding: ${PopupPadding};
  justify-content: flex-end;
  border-top: 0.4px solid var(--grey-300);
  gap: 16px;
`

export const ItemSubtitle = styled(Text)`
  color: var(--grey-500);
`

export const Stepper = styled(Flex)`
  gap: 8px;
`

export const StepperNumber = styled(Text)`
  width: 32px;
  text-align: center;
`

export const SearchContainer = styled.div`
  padding: ${PopupPadding};
`

export const SearchField = styled(TextField)`
  width: 100%;
  i {
    color: var(--grey-500);
  }
`

import React from 'react'
import { Popup } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import {
  PopupContainer,
  PopupContent,
  Divider,
  SubscriptionNote,
  FooterActions,
} from './styles'

import { Text } from '../../Text'
import { Icon } from '../../Icon'

type CancelSubscriptionSuccessPopupProps = {
  isOpen: boolean
  onClose: () => void
  note: string
}

const CancelSubscriptionSuccessPopup = (
  props: CancelSubscriptionSuccessPopupProps,
) => {
  const { isOpen, onClose, note } = props

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <PopupContainer>
        <PopupContent>
          <SubscriptionNote>
            <Icon.Solid name="exclaimationCircle" size={20} />
            <Text variant="regular-3" colorVar="neutral-11">
              {note}
            </Text>
          </SubscriptionNote>
          <Divider />
        </PopupContent>
        <FooterActions>
          <Button fullWidth variant="solid" colorTheme="neutral" size={3}>
            Ok
          </Button>
        </FooterActions>
      </PopupContainer>
    </Popup>
  )
}

export default CancelSubscriptionSuccessPopup

import styled, { css } from 'styled-components'

interface Props
  extends Pick<React.CSSProperties, 'alignItems' | 'justifyContent' | 'gap'> {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  gap?: number
  width?: number | string
}

export const Flex = styled.div<Props>`
  display: flex;
  ${(p) =>
    p.direction &&
    css`
      flex-direction: ${p.direction};
    `}
  ${(p) =>
    p.width &&
    css`
      width: ${p.width};
    `}
  ${(p) =>
    p.alignItems &&
    css`
      align-items: ${p.alignItems};
    `}
    ${(p) =>
    p.justifyContent &&
    css`
      justify-content: ${p.justifyContent};
    `}
    ${(p) =>
    p.gap &&
    css`
      gap: ${p.gap}px;
    `}
`

import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Icon, Text } from '@/components/ui'
import { Actions, PopupContent, Subtitle, Title, Note } from './styles'

interface ProposalSentPopupProps {
  title: string
  message: string
  noteTitle: string
  note: string
  onClose: () => void
}

export const ProposalSentPopup = ({
  title,
  message,
  noteTitle,
  note,
  onClose,
}: ProposalSentPopupProps) => {
  const handleContinue = () => {
    onClose()
  }
  return (
    <>
      <PopupContent variant="congratulation">
        <Icon.Solid name="checkCircle" size={64} />
        <Title variant="heading-h5">{title}</Title>
        <Text variant="body-m-regular">
          {message && <ReactMarkdown>{message}</ReactMarkdown>}
        </Text>
        <Note direction="column" gap={8}>
          <Text variant="body-m-semibold">{noteTitle}</Text>
          <Text variant="body-m-regular">{note}</Text>
        </Note>
      </PopupContent>
      <Actions>
        <Button variant="black" size="large" onClick={handleContinue}>
          Continue
        </Button>
      </Actions>
    </>
  )
}

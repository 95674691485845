export type Prices = {
  title: string
  price: number
}

export enum ProposalStatus {
  Sent = 0,
  Accepted = 1,
  Rejected = 2,
  Updated = 3,
  Withdrawn = 4,
}

export interface IProposal {
  id: string
  userWizardId: string

  summary?: string
  prices?: Prices[]
  initialPayment?: number
  status: ProposalStatus

  createdAt: string
  updatedAt: string
}

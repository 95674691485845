import React from 'react'
import { useForm } from 'react-hook-form'
import { noop } from 'lodash'
import { DateTime } from 'luxon'
import {
  Button,
  PopupActions,
  PopupTopBar,
  TextField,
  Text,
  Checkbox,
} from '@/components/ui'
import useApi from '@/hooks/useApi'
import { FieldGrid, Kind, Label, Options, PopupContent } from './styles'
import { Kinds } from './constants'
import { SpainStepTwoData, SpainStepTwoProps } from './types'

export const SpainStepTwo = ({
  data,
  stepOneData,
  onClose,
  onContinue,
  onBack,
}: SpainStepTwoProps) => {
  const { api, status } = useApi()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SpainStepTwoData>({
    defaultValues: data,
  })

  const onSubmit = (data: SpainStepTwoData) => {
    if (!stepOneData) return

    const dob = DateTime.fromObject({
      year: stepOneData.dob.year,
      month: stepOneData.dob.month,
      day: stepOneData.dob.day,
    }).toISO()

    const arrivalDate = DateTime.fromObject({
      year: data.arrival.year,
      month: data.arrival.month,
      day: data.arrival.day,
    }).toISO()

    api.insurance
      .requestSpainInsurance({
        firstName: stepOneData.firstName,
        lastName: stepOneData.lastName,
        email: stepOneData.email,
        dob,
        spouse: stepOneData.familyInfo.spouse || 0,
        children: stepOneData.familyInfo.children || 0,
        pets: stepOneData.familyInfo.pets || 0,
        country: data.country,
        city: data.city,
        arrivalDate,
        kind: data.kind,
      })
      .then(() => onContinue(data))
      .catch(noop)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PopupTopBar onClose={onClose}>Want to get insured in Spain?</PopupTopBar>
      <PopupContent>
        <Text variant="body-m-regular">
          Fill out information below and a rep will email you shortly!
        </Text>
        <Label>
          Country you came from
          <TextField
            placeholder="Add country"
            invalid={!!errors.country}
            {...register('country', { required: true })}
          />
        </Label>
        <Label>
          Where you want to live?
          <TextField
            placeholder="Add city"
            invalid={!!errors.city}
            {...register('city', { required: true })}
          />
        </Label>
        <Label>
          Date of arrival in Spain
          <FieldGrid>
            <TextField
              type="number"
              placeholder="Day"
              invalid={!!errors.arrival}
              {...register('arrival.day', { required: true, min: 1, max: 31 })}
            />
            <TextField
              type="number"
              placeholder="Month"
              invalid={!!errors.arrival}
              {...register('arrival.month', {
                required: true,
                min: 1,
                max: 12,
              })}
            />
            <TextField
              type="number"
              placeholder="Year"
              invalid={!!errors.arrival}
              {...register('arrival.year', { required: true, min: 1 })}
            />
          </FieldGrid>
        </Label>
        <Label as="div">
          What kind of insurance you&apos;re looking for?
          <Options as="ul">
            {Kinds.map((kind, index) => (
              <Kind key={index} size={8}>
                <Text variant="body-m-regular">{kind}</Text>
                <Checkbox
                  value={kind}
                  {...register('kind', { required: true })}
                />
              </Kind>
            ))}
          </Options>
        </Label>
      </PopupContent>
      <PopupActions>
        <Button variant="grey" size="large" onClick={onBack} type="button">
          Back
        </Button>
        <Button
          variant="black"
          size="large"
          type="submit"
          isLoading={status === 'posting'}
        >
          Request Quote
        </Button>
      </PopupActions>
    </form>
  )
}

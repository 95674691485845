import React, { Fragment } from 'react'
import { flag, other, outline, solid } from '../../ui/icons'
import {
  FlagIcon,
  Icon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '../../ui/Icon'

type SizeMap = Record<number, number>

const IconRenderer: React.FC<{
  sizeMap: SizeMap
  size: number
  iconType: IconType | undefined
  iconName?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
}> = (props) => {
  const { iconType, iconName, size, sizeMap } = props
  if (!iconName) return <Fragment />
  if (iconType === 'Other' && iconName in other) {
    return <Icon.Base name={iconName as OtherIcon} size={sizeMap[size]} />
  }
  if (iconType === 'Outline' && iconName in outline) {
    return <Icon.Outline name={iconName as OutlineIcon} size={sizeMap[size]} />
  }
  if (iconType === 'Solid' && iconName in solid) {
    return <Icon.Solid name={iconName as SolidIcon} size={sizeMap[size]} />
  }
  if (iconType === 'Flag' && iconName in flag) {
    return <Icon.Flag name={iconName as FlagIcon} size={sizeMap[size]} />
  }
  return <Fragment />
}

export default IconRenderer

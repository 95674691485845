import React, { useState, useCallback, useEffect } from 'react'
import {
  PopupTopBar,
  Button,
  Flex,
  TextField,
  Text,
  Icon,
} from '@/components/ui'
import { Prices } from '@/models/IProposal'
import {
  PopupContent,
  PopupActions,
  Label,
  ProposalPrices,
  PricesHeader,
  PricesContainer,
  PriceItem,
  PriceField,
  NameField,
  TotalsContainer,
} from './styles'
import { ICreateProposalProps } from './types'

export const CreateProposal: React.FC<ICreateProposalProps> = ({
  onClose,
  proposal,
  userWizardId,
  isLoading,
  platformFee = 30,
  draftProposal,
  onCreateProposal,
  onPaymentRequest,
}) => {
  const proposalPrices = draftProposal
    ? draftProposal?.prices
    : proposal?.prices
  const proposalSummary = draftProposal
    ? draftProposal?.summary
    : proposal?.summary
  const proposalInitialPayment = draftProposal
    ? draftProposal.hasInitialPayment
    : !!proposal?.initialPayment

  // proposal states
  const [prices, setPrices] = useState<Prices[]>(proposalPrices || [])
  const [summary, setSummary] = useState(proposalSummary || '')
  const [title, setTitle] = useState('')
  const [price, setPrice] = useState<number>()
  const [hasInitialPayment, toggleInitialPayment] = useState(
    proposalInitialPayment || false,
  )
  // total states
  const [proposalTotal, setProposalTotal] = useState('0')
  const [techFee, setTechFee] = useState('0')
  const [totalEarning, setTotalEarning] = useState('0')

  const handleAddPrice = () => {
    if (price && title) {
      setPrices((prices) => [...prices, { title, price }])
      setTitle('')
      setPrice(0)
    }
  }

  useEffect(() => {
    const pricesTotal = prices
      .map((item) => item.price)
      .reduce((a, b) => a + b, 0)
    const total = pricesTotal
    const crFee = (total * platformFee) / 100
    const totalEarning = total - crFee

    setProposalTotal(Math.ceil(total).toLocaleString())
    setTechFee(Math.ceil(crFee).toLocaleString())
    setTotalEarning(Math.ceil(totalEarning).toLocaleString())
  }, [platformFee, prices])

  const setupProposal = () => {
    const proposalBody = {
      ...(!proposal && { userWizardId: userWizardId || '' }),
      hasInitialPayment,
      prices,
      summary,
    }

    hasInitialPayment
      ? onPaymentRequest(proposalBody)
      : onCreateProposal(proposalBody)
  }

  const saveTitle = proposal ? 'Update Proposal' : 'Send Proposal'

  const Services = ({ service }: { service: Prices }) => {
    return (
      <PriceItem alignItems="center" justifyContent="space-between">
        <Text variant="body-m-regular">{service.title}</Text>
        <Text variant="body-m-regular">${service.price.toLocaleString()}</Text>
        <Button
          variant="grey-ghost"
          icon={<Icon.Solid name="trash" size={20} />}
          onClick={() => setPrices(prices.filter((price) => price !== service))}
        />
      </PriceItem>
    )
  }

  return (
    <>
      <PopupTopBar onClose={onClose}>
        {proposal ? 'Edit Proposal' : 'New Proposal'}
      </PopupTopBar>
      <PopupContent isNew>
        <Flex gap={24} direction="column">
          <Label>
            Summary
            <TextField
              placeholder="Your message"
              multiline
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </Label>
          <ProposalPrices>
            <PricesHeader justifyContent="space-between" gap={16}>
              <Text variant="caption-s-medium">Services</Text>
              <Text variant="caption-s-medium">Price</Text>
            </PricesHeader>
            <PricesContainer>
              {prices.length > 0 && (
                <Flex direction="column">
                  {prices.map((item, index) => (
                    <Services key={index} service={item} />
                  ))}
                </Flex>
              )}
              <PriceField alignItems="center" gap={16}>
                <NameField
                  placeholder="Item name"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  type="number"
                  placeholder="price"
                  value={price}
                  min={0}
                  onChange={(e) => setPrice(parseFloat(e.target.value))}
                />
              </PriceField>
            </PricesContainer>
          </ProposalPrices>
          <Flex justifyContent="center">
            <Button variant="secondary" onClick={() => handleAddPrice()}>
              Add Item
            </Button>
          </Flex>
          <hr />
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="body-m-regular">
              Require an initial payment after accepting proposal?
            </Text>
            <Button
              variant="grey-ghost"
              icon={
                <Icon.Solid
                  name={hasInitialPayment ? 'switchOn' : 'switchOff'}
                />
              }
              onClick={() => toggleInitialPayment(!hasInitialPayment)}
            />
          </Flex>
          <TotalsContainer direction="column">
            <Flex justifyContent="space-between">
              <Text variant="body-m-regular">Proposal total</Text>
              <Text variant="body-m-regular">${proposalTotal}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text variant="body-m-regular">
                Total Technology Fee {platformFee}%
              </Text>
              <Text variant="body-m-regular">${techFee}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text variant="body-m-semibold">Your Total Earning</Text>
              <Text variant="body-m-semibold">${totalEarning}</Text>
            </Flex>
          </TotalsContainer>
        </Flex>
      </PopupContent>
      <PopupActions justifyContent="center">
        <Button
          variant="black"
          size="medium"
          isLoading={isLoading}
          disabled={!prices.length || isLoading}
          onClick={() => setupProposal()}
        >
          {hasInitialPayment ? 'Continue to payment request' : saveTitle}
        </Button>
      </PopupActions>
    </>
  )
}

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Popup } from '@/components/ui'
import { IProposal } from '@/models/IProposal'
import { WithdrawProposalPopup } from '../Popups/WithdrawProposal'

export const WithdrawProposal = forwardRef<
  { trigger: () => void },
  {
    proposal: IProposal | null | undefined
    onUpdateProposal: () => void
  }
>(({ proposal, onUpdateProposal }, ref) => {
  const [isTriggered, setTriggered] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  if (!isTriggered) return null

  return (
    <>
      <Popup isOpen>
        <WithdrawProposalPopup
          onCancel={() => setTriggered(false)}
          proposalId={proposal?.id}
          onUpdate={onUpdateProposal}
        />
      </Popup>
    </>
  )
})

WithdrawProposal.displayName = 'WithdrawProposal'

import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Icon } from '@/components/ui'
import { Actions, PopupContent, Subscript, Subtitle, Title } from './styles'
import { CongratulationsPopupProps } from './types'

export const CongratulationsPopup = ({
  title,
  message,
  children,
  hideReceipt,
  onClose,
  variant = 'congratulation',
}: CongratulationsPopupProps) => {
  const handleContinue = () => {
    onClose()
  }
  return (
    <>
      <PopupContent variant={variant}>
        <Icon.Solid
          name={
            variant === 'congratulation' ? 'checkCircle' : 'informationCircle'
          }
          size={64}
        />
        <Title variant="heading-h5">{title}</Title>
        <Subtitle variant="body-m-regular">
          {message && <ReactMarkdown>{message}</ReactMarkdown>}
          {children}
        </Subtitle>
        {!hideReceipt && (
          <Subscript variant="body-s-regular">
            Receipt has been sent to your email.
          </Subscript>
        )}
      </PopupContent>
      <Actions>
        <Button variant="black" size="large" onClick={handleContinue}>
          Continue
        </Button>
      </Actions>
    </>
  )
}

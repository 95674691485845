import styled from 'styled-components'
import { Flex, PopupPadding } from '@/components/ui'

export const Content = styled.div`
  padding: ${PopupPadding};
  width: 384px;
`

export const Actions = styled(Flex)`
  padding: ${PopupPadding};
  border-top: 0.4px solid var(--grey-300);
`

import React, { InputHTMLAttributes, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { textVariants } from './Text'

interface Props
  extends InputHTMLAttributes<HTMLInputElement>,
    PropsWithChildren {
  isRound?: boolean
  isDark?: boolean
  isContentLeft?: boolean
}

const Control = styled.span<{
  isRound?: boolean
  isDark?: boolean
  hasContent: boolean
}>`
  display: inline-flex;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  border: 1.5px solid
    ${(p) => (p.isDark ? 'var(--grey-900)' : 'var(--grey-200)')};
  transition: 0.1s all;
  border-radius: ${(p) => (p.isRound ? '999px' : '6px')};
  margin-right: ${(p) => (p.hasContent ? '8px' : '0px')};
  &:after {
    content: ' ';
    position: absolute;
    display: none;
  }
`

const Container = styled.label`
  ${textVariants['body-m-regular']}
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${Control}:after {
    margin-top: 3px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  &:hover input ~ ${Control} {
    border-color: var(--grey-300);
  }
  input:checked ~ ${Control} {
    background-color: var(--purple-500);
    border-color: var(--purple-500);
  }
  input:checked ~ ${Control}:after {
    display: block;
  }

  input:disabled ~ ${Control} {
    background-color: var(--grey-900);
    border-color: var(--purple-900);
    opacity: 0.2;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const Component = React.forwardRef<HTMLInputElement, Props>(
  (
    { isRound, isDark, isContentLeft, children, className, ...rest },
    ref,
  ): JSX.Element => {
    return (
      <Container as={children ? 'label' : 'div'} className={className}>
        {isContentLeft && children}
        <Input type="checkbox" ref={ref} {...rest} />
        <Control isRound={isRound} isDark={isDark} hasContent={!!children} />
        {!isContentLeft && children}
      </Container>
    )
  },
)

Component.displayName = 'Checkbox'

export const Checkbox = React.memo(Component)

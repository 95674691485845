import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Popup } from '@/components/ui'
import { taxConsultUrl } from '@/constants/calendly'
import { useAuthContext } from '@/contexts/Auth'
import { HireTaxPopup } from '../Popups'

export const HireTaxFlow = forwardRef<{ trigger: () => void }>((_, ref) => {
  const { user } = useAuthContext()
  const [isTriggered, setTriggered] = useState(false)

  const handleClose = () => {
    setTriggered(false)
    try {
      window.Sprintful.hidePopup()
    } catch {}
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  const handleConfirm = () => {
    window.Sprintful.showPopup({
      url: `${taxConsultUrl}?hide-logo=true&hide-message=false`,
      copyParentsQuery: 'false',
    })
  }

  if (!isTriggered) return null

  return (
    <>
      <Popup isOpen>
        <HireTaxPopup
          onClose={handleClose}
          onConfirm={() => {
            handleConfirm()
          }}
        />
      </Popup>
    </>
  )
})

HireTaxFlow.displayName = 'HireTaxFlow'

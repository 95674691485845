import styled, { css } from 'styled-components'
import { Media } from '@/utils/media'

interface ColumnProps {
  span: number
  padding?: number
  tablet?: number
  laptop?: number
  desktop?: number
}

const singleColumn = 100 / 12

const Container = styled.div`
  width: 100%;
  padding: 0px 16px;
  margin: 0 auto;

  ${Media.Desktop`
    width: 1200px;
    padding: 0;
  `}
`

const MiniContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
  margin: inherit auto;
  ${Media.Desktop`
    width: 796px;
    padding: 0;
  `}

  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const Row = styled.div`
  display: flex;
  min-width: 100%;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;
`

const Column = styled.div<ColumnProps>`
  ${({ padding }) =>
    css`
      padding-left: ${`${padding ?? 16}px`};
      padding-right: ${`${padding ?? 16}px`};
    `}

  ${({ span }) =>
    css`
      width: ${`${span * singleColumn}%`};
      display: ${span === 0 ? 'none' : 'block'};
    `}

  ${({ tablet }) =>
    tablet &&
    Media.Tablet`
  width: ${`${tablet * singleColumn}%`};
  display: ${tablet === 0 ? 'none' : 'block'};
  `}

  ${({ laptop }) =>
    laptop &&
    Media.Laptop`
  width: ${`${laptop * singleColumn}%`};
  display: ${laptop === 0 ? 'none' : 'block'};
  `}

  ${({ desktop }) =>
    desktop &&
    Media.Desktop`
  width: ${`${desktop * singleColumn}%`};
  display: ${desktop === 0 ? 'none' : 'block'};
  `}
`

const TopBar = styled.nav`
  display: flex;
  height: 74px;
  align-items: center;
`

const ContainerOverride = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
`

export const Layout = {
  Container,
  ContainerOverride,
  MiniContainer,
  Row,
  Column,
  TopBar,
}

import styled from 'styled-components'
import { Flex, Text, PopupPadding, textVariants } from '@/components/ui'

export const PopupContent = styled(Flex)`
  flex-direction: column;
  padding: ${PopupPadding};
  width: 384px;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['body-s-semibold']}
  margin-top: 20px;
  gap: 10px;
`

export const PricingContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px;
`

export const PricingLabel = styled(Text)`
  margin-bottom: 8px;
`

export const Eula = styled(Text)`
  color: var(--grey-500);
  max-width: 55%;
`

export const FromTwoContainer = styled(Flex)`
  gap: 16px;

  > label {
    flex: 1;
  }
`

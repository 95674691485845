import styled from 'styled-components'
import {
  CRImage,
  Flex,
  PopupActions,
  PopupPadding,
  Text,
} from '@/components/ui'

export const Cover = styled(CRImage)`
  height: 220px;
  width: 80%;
  align-self: center;
`

export const PopupContent = styled(Flex)`
  width: 384px;
  min-height: 200px;
  padding: ${PopupPadding};
  flex-direction: column;
`

export const Title = styled(Text)`
  margin-bottom: 8px;
  text-align: center;
`

export const Subtitle = styled(Text)`
  flex-grow: 1;
  text-align: center;
`

export const Actions = styled(PopupActions)`
  > :last-child {
    flex-grow: 1;
  }
`

import styled from 'styled-components'
import { CRImage, PopupActions, PopupPadding, Text } from '@/components/ui'

export const PopupContent = styled.div`
  width: 592px;
  padding: ${PopupPadding};
`

export const Cover = styled(CRImage)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 224px;
  display: flex;
  justify-content: center;
  padding-left: 40px;
  gap: 8px;

  p {
    width: 45%;
  }

  button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`

export const Title = styled(Text)`
  margin-top: 12px;
  text-align: center;
`

export const Subtitle = styled(Text)`
  margin-top: 8px;
  text-align: center;
`

export const Steps = styled.ul`
  margin: 32px 0px 20px;
  li:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Step = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: var(--grey-100);
  border-radius: 12px;
`

export const Actions = styled(PopupActions)`
  justify-content: center;
`

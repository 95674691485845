import styled, { css, keyframes } from 'styled-components'

import { solid, outline, other } from './icons'
import { flag } from './icons/flag'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.i<{
  size?: number
  width?: number
  height?: number
  isLoading?: boolean
  isActive?: boolean
  round?: boolean
}>`
  display: flex;
  width: ${({ size, width }) => width || size || 24}px;
  height: ${({ size, height }) => height || size || 24}px;

  ${(p) =>
    p.round &&
    css`
      border-radius: 999px;
      overflow: hidden;
    `}

  ${(p) =>
    p.isLoading &&
    css`
      animation: ${rotate} 0.5s linear infinite;
    `}
  svg {
    width: 100%;
    ${(p) =>
      p.isActive &&
      css`
        color: var(--purple-600) !important;
      `}
  }
`

export type OtherIcon = keyof typeof other

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  name?: OtherIcon
  width?: number
  height?: number
  size?: number
  isActive?: boolean
  round?: boolean
}

function Base(props: IconProps) {
  const { name, ...rest } = props
  const Icon = name ? other[name] : null
  return (
    <Wrapper {...rest} isLoading={name === 'Loading'}>
      {props.children}
      {Icon && <Icon />}
    </Wrapper>
  )
}

export type OutlineIcon = keyof typeof outline

interface OutlineProps extends Omit<IconProps, 'name'> {
  name: OutlineIcon
}

function Outline({ name, ...rest }: OutlineProps) {
  const SVG = outline[name]
  return (
    <Base {...rest}>
      <SVG />
    </Base>
  )
}

export type SolidIcon = keyof typeof solid

interface SolidProps extends Omit<IconProps, 'name'> {
  name: SolidIcon
}

function Solid({ name, ...rest }: SolidProps) {
  const SVG = solid[name]
  return (
    <Base {...rest}>
      <SVG />
    </Base>
  )
}

export type FlagIcon = keyof typeof flag

interface FlagProps extends Omit<IconProps, 'name'> {
  name: FlagIcon
}

function Flag({ name, ...rest }: FlagProps) {
  const SVG = flag[name]
  return (
    <Base {...rest} round>
      {SVG && <SVG />}
    </Base>
  )
}

export const Icon = {
  Base,
  Outline,
  Solid,
  Flag,
}

export type IconType = 'Other' | 'Outline' | 'Solid' | 'Flag'

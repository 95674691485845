import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

interface RangeSliderProps {
  min: number
  max: number
  minValue?: number
  maxValue?: number
  onChange: (min: number, max: number) => void
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 32px;

  .slider {
    position: relative;
    width: 100%;
  }

  .slider__track,
  .slider__range {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    height: 1.5px;
    overflow: hidden;
  }

  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }

  .slider__range {
    background-color: var(--grey-900);
    z-index: 2;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }

  .thumb--left {
    z-index: 3;
  }

  .thumb--right {
    z-index: 4;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    border-radius: 50%;
    background-color: var(--grey-900);
    border: 8px solid var(--panel-1);
    outline: 2px solid var(--grey-900);
    cursor: pointer;
    height: 30px;
    width: 30px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    border-radius: 50%;
    background-color: var(--grey-900);
    border: 8px solid var(--panel-1);
    outline: 2px solid var(--grey-900);
    cursor: pointer;
    height: 30px;
    width: 30px;
    pointer-events: all;
    position: relative;
  }
`

const Component = ({
  min,
  max,
  minValue,
  maxValue,
  onChange,
  ...rest
}: RangeSliderProps) => {
  const [minVal, setMinVal] = useState(minValue || min)
  const [maxVal, setMaxVal] = useState(maxValue || max)
  const range = useRef<HTMLDivElement>(null)

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, maxVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, maxVal, getPercent])

  return (
    <Container {...rest}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(Number(event.target.value), maxVal - 1)
          setMinVal(value)
          onChange(value, maxVal)
        }}
        className="thumb thumb--left"
        style={{ ...(minVal > max - 100 && { zIndex: '5' }) }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(Number(event.target.value), minVal + 1)
          setMaxVal(value)
          onChange(minVal, value)
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track"></div>
        <div ref={range} className="slider__range"></div>
      </div>
    </Container>
  )
}

export const RangeSlider = React.memo(Component)

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Icon,
  PopupActions,
  PopupTopBar,
  Text,
  TextField,
} from '@/components/ui'
import { travelingMembers } from '@/constants/visa'
import {
  ItemSubtitle,
  Option,
  OptionContent,
  Stepper,
  StepperNumber,
} from '@/components/quiz/Popups/styles'
import { IFamilyInfo } from '@/models/IVisaSelectorPref'
import { SpainStepOneData, SpainStepOneProps } from './types'
import {
  EmailField,
  FieldGrid,
  FirstNameField,
  Label,
  Options,
  PopupContent,
} from './styles'

export const SpainStepOne = ({
  data,
  onContinue,
  onClose,
}: SpainStepOneProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SpainStepOneData>({
    defaultValues: data,
  })

  const values = watch()

  const handleIncrement = (id: keyof Omit<IFamilyInfo, 'alone'>) => {
    const value = values.familyInfo[id] ?? 0
    setValue(`familyInfo.${id}`, value + 1)
  }

  const handleDecrement = (id: keyof Omit<IFamilyInfo, 'alone'>) => {
    const value = values.familyInfo[id] ?? 0
    if (value === 0) return
    setValue(`familyInfo.${id}`, value - 1)
  }

  const onSubmit = (data: SpainStepOneData) => {
    onContinue(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PopupTopBar onClose={onClose}>Want to get insured in Spain?</PopupTopBar>
      <PopupContent>
        <Text variant="body-m-regular">Confirm your information below!</Text>
        <FirstNameField
          placeholder="First Name"
          invalid={!!errors.firstName}
          {...register('firstName', { required: true })}
        />
        <TextField
          placeholder="Last Name"
          invalid={!!errors.lastName}
          {...register('lastName', { required: true })}
        />
        <EmailField
          placeholder="Email Address"
          invalid={!!errors.email}
          {...register('email', { required: true })}
        />
        <Label>
          Birthday (optional)
          <FieldGrid>
            <TextField
              type="number"
              placeholder="Day"
              invalid={!!errors.dob}
              {...register('dob.day', { required: true, min: 1, max: 31 })}
            />
            <TextField
              type="number"
              placeholder="Month"
              invalid={!!errors.dob}
              {...register('dob.month', { required: true, min: 1, max: 12 })}
            />
            <TextField
              type="number"
              placeholder="Year"
              invalid={!!errors.dob}
              {...register('dob.year', { required: true, min: 1 })}
            />
          </FieldGrid>
        </Label>
        <Label>
          Traveling members
          <Options>
            {travelingMembers.map((item, index) => (
              <Option key={index}>
                <OptionContent>
                  <Text variant="body-m-regular">{item.title}</Text>
                  <ItemSubtitle variant="caption-s-medium">
                    {item.subtitle}
                  </ItemSubtitle>
                </OptionContent>
                <Stepper alignItems="center">
                  <Button
                    type="button"
                    variant="grey"
                    icon={<Icon.Solid name="minus" size={20} />}
                    onClick={() => handleDecrement(item.id)}
                  />
                  <StepperNumber variant="heading-h5">
                    {values.familyInfo[item.id]}
                  </StepperNumber>
                  <Button
                    type="button"
                    variant="grey"
                    icon={<Icon.Solid name="plus" size={20} />}
                    onClick={() => handleIncrement(item.id)}
                  />
                </Stepper>
              </Option>
            ))}
          </Options>
        </Label>
      </PopupContent>
      <PopupActions>
        <Button variant="grey" size="large" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="black" size="large" type="submit">
          Continue
        </Button>
      </PopupActions>
    </form>
  )
}

import React, { useMemo } from 'react'
import { Popup } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { Badge } from '@/components/uiJar/badge'
import { PremiumUpgradeReason } from '@/contexts/PremiumSubscription/types'
import { YEARLY_SUBSCRIPTION_PRICE } from '@/contexts/PremiumSubscription/constants'
import { useTheme } from '@/contexts/Theme'
import {
  PopupContainer,
  PopupContent,
  Hero,
  HeroImage,
  TagLine,
  CloseWrapper,
  Footer,
  EphesizedText,
  PromoCode,
} from './styles'

import { ITEMS } from './constants'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { imageUrl } from '../Image'

type UpgradePopupProps = {
  opened: boolean
  onClose: () => void
  onProceed: () => void
  reason?: PremiumUpgradeReason
}

export const BlackFridayUpgradePopup = ({
  onProceed,
  opened,
  onClose,
  reason,
}: UpgradePopupProps) => {
  const { theme } = useTheme()
  const isDark = theme === 'dark'
  const illustrationUrl = useMemo(() => {
    switch (reason) {
      case PremiumUpgradeReason.SETTING:
        return imageUrl('/img/premium/citizen-remote.svg')
      case PremiumUpgradeReason.ACCESS_SOCIAL:
        return imageUrl('/img/premium/social.svg')
      default:
        return imageUrl('/img/premium/citizen-remote.svg')
    }
  }, [reason])

  const title = useMemo(() => {
    switch (reason) {
      case PremiumUpgradeReason.SETTING:
        return 'Citizen Remote Premium'
      case PremiumUpgradeReason.ACCESS_SOCIAL:
        return 'Get the full Social experience'
      default:
        return 'Citizen Remote Premium'
    }
  }, [reason])
  return (
    <Popup isOpen={opened} preferredTheme="dark">
      <PopupContainer>
        <CloseWrapper justifyContent="flex-end">
          <Button
            icon={{ startIconName: 'crossSmall', type: 'Solid' }}
            size={2}
            variant="ghost"
            colorTheme="neutral"
            onClick={onClose}
          />
        </CloseWrapper>

        <PopupContent>
          <Hero>
            <HeroImage
              alt={`Premium subscription illustration for ${title}`}
              src={illustrationUrl}
            />
            <Flex direction="column" gap={4}>
              <Text variant="semibold-6" colorVar="neutral-12">
                Lifetime Premium For just $10!
              </Text>
              <Text variant="regular-4" colorVar="neutral-11">
                Go beyond the limits and unlock dozens of exclusive features by
                subscribing to{' '}
                <EphesizedText>Citizen Remote Premium.</EphesizedText>
              </Text>
            </Flex>
          </Hero>
          <PromoCode>BFCM2024</PromoCode>
          <TagLine>
            Use promo code during checkout to get the deal. Promo code expires
            on December 3rd.
          </TagLine>
        </PopupContent>
        <Footer $isDark={isDark}>
          <Button
            fullWidth
            variant="solid"
            colorTheme="neutral"
            size={3}
            onClick={onProceed}
          >
            Subscribe to Premium
          </Button>
          <Flex direction="column">
            <Text variant="medium-2" colorVar="neutral-9">
              By subscribing to premium, you agree to our
            </Text>
            <Text variant="medium-2" colorVar="neutral-9">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://citizenremote.com/terms-of-use/"
              >
                Terms of Service
              </a>{' '}
              and accept our{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://citizenremote.com/eula/'}
              >
                EULA
              </a>
            </Text>
          </Flex>
        </Footer>
      </PopupContainer>
    </Popup>
  )
}

import styled from 'styled-components'

export const HoverLabel = styled.label<{ size: number }>`
  display: flex;
  padding-left: ${(p) => p.size}px;
  padding-right: ${(p) => p.size}px;
  margin-left: ${(p) => -p.size}px;
  margin-right: ${(p) => -p.size}px;
  border-radius: 8px;
  transition: background-color, color 0.1s;
  cursor: pointer;

  &:hover {
    background-color: var(--grey-100);
  }
`

import React from 'react'
import { DateTime } from 'luxon'
import { Popup } from '@/components/uiJar/popup'
import { Badge } from '@/components/uiJar/badge'
import { Button } from '@/components/uiJar/Button'
import { IconLink } from '@/components/uiJar/IconLink'
import { usePremiumSubscription } from '@/contexts/PremiumSubscription'
import {
  PopupContainer,
  PopupContent,
  Divider,
  SubscriptionNote,
  PackageDescription,
  FooterActions,
  PopupHeading,
  HeadingTitle,
  ContentHeading,
} from './styles'
import { ITEMS } from './constants'
import { Text } from '../Text'
import { Flex } from '../Flex'
import { Icon } from '../Icon'

type CancelSubscriptionPopupProps = {
  isOpen: boolean
  onClose: () => void
  onCancelSubscription: () => void
}

const CancelSubscriptionPopup = (props: CancelSubscriptionPopupProps) => {
  const { isOpen, onClose, onCancelSubscription } = props

  const { subscriptionExpiry } = usePremiumSubscription()

  const date = subscriptionExpiry
    ? DateTime.fromISO(subscriptionExpiry as string)
    : ''

  return (
    <Popup fullScreenInMobile isOpen={isOpen} onClose={onClose}>
      <PopupContainer>
        <PopupHeading>
          <IconLink
            colorTheme="neutral"
            size={4}
            name="arrowLeft"
            type="Solid"
            onClick={onClose}
          />
          <HeadingTitle>Cancel subscription</HeadingTitle>
        </PopupHeading>
        <PopupContent>
          <Flex direction="column" gap={4}>
            <ContentHeading>
              <Text variant="semibold-4">Cancel subscription</Text>
            </ContentHeading>
            <Text variant="regular-3" colorVar="neutral-11">
              {`If you cancel your subscription, you’ll have access to all premium
              features until your subscription period ends. Then you’ll lose all
              access to all premium features in Citizen Remote app.`}
            </Text>
          </Flex>
          <Divider />
          <SubscriptionNote>
            <Icon.Solid name="exclaimationCircle" size={20} />
            <Text variant="regular-3" colorVar="neutral-11">
              You will keep your access to all Citizen Remote Premium features
              until {date ? date.toFormat('MMMM d, yyyy') : '-'}.
            </Text>
          </SubscriptionNote>
          <Divider />
          <PackageDescription>
            <Text variant="medium-3" colorVar="neutral-11">
              What’s included in Premium?
            </Text>
            <Flex direction="column" gap={24} as="ul">
              {ITEMS.map((item) => (
                <Flex key={item.title} gap={8} as="li">
                  <Icon.Base name={item.icon} size={20} />
                  <Text
                    variant="medium-3"
                    colorVar={item.comingSoon ? 'neutral-11' : 'neutral-12'}
                  >
                    {item.title}
                  </Text>
                  {item.new && (
                    <Badge size={1} variant="soft" colorTheme="accent">
                      new
                    </Badge>
                  )}
                  {item.comingSoon && (
                    <Badge size={1} variant="soft" colorTheme="neutral">
                      coming soon
                    </Badge>
                  )}
                </Flex>
              ))}
            </Flex>
          </PackageDescription>
        </PopupContent>
        <FooterActions>
          <Button
            variant="outline"
            colorTheme="neutral"
            size={3}
            fullWidth
            onClick={onClose}
          >
            Not now
          </Button>
          <Button
            onClick={onCancelSubscription}
            fullWidth
            variant="solid"
            colorTheme="danger"
            size={3}
          >
            Cancel subscription
          </Button>
        </FooterActions>
      </PopupContainer>
    </Popup>
  )
}

export default CancelSubscriptionPopup

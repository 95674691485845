import styled from 'styled-components'
import { PopupPadding, Flex, textVariants, TextField } from '@/components/ui'

export const PopupContent = styled.div<{ isNew: boolean; isViewing?: boolean }>`
  width: ${(p) => (p.isNew ? '512px;' : '416px;')}
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: ${(p) => (p.isViewing ? '600px;' : '500px;')}
  scrollbar-width: none;
  padding: ${PopupPadding};

  hr {
    border-top: 1px solid var(--gery-200);
  }
`

export const GreyColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
  background-color: var(--grey-100);
`

export const WhiteColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
`

export const PopupActions = styled(Flex)`
  padding: ${PopupPadding};
  border-top: 0.4px solid var(--grey-300);
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['caption-s-medium']}
  margin-top: 20px;
  gap: 10px;

  textarea {
    resize: none;
  }
`

export const ProposalPrices = styled.div`
  border-radius: 12px;
  border: 1px solid var(--grey-200);
  background: var(--panel-1);
  overflow: hidden;
`

export const PricesHeader = styled(Flex)`
  padding: 16px;
  align-self: stretch;
  background: var(--grey-100);

  p:nth-child(2) {
    margin-right: 50px;
  }
`

export const PricesContainer = styled.div`
  background: var(--panel-1);
  border-radius: 12px;
`

export const PriceItem = styled(Flex)`
  padding: 16px;
  border-top: 1px solid var(--grey-200);

  p:first-child {
    width: 75%;
  }
`

export const PriceField = styled(Flex)`
  padding: 16px;
  border-top: 1px solid var(--grey-200);

  i {
    color: var(--grey-500);
  }
`

export const NameField = styled(TextField)`
  width: 90%;
`

export const TotalsContainer = styled(Flex)<{ isViewing?: boolean }>`
  border-radius: 16px;
  border: 1px solid var(--grey-200);
  background: ${(p) => (p.isViewing ? 'var(--panel-1);' : 'var(--grey-100);')}

  ${(p) => p.isViewing && 'margin-top: 8px;'}

  div:first-child {
    border-top: none;
  }

  div {
    border-top: 1px solid var(--grey-200);
    padding: 16px;
  }
`

export const SummaryBox = styled(Flex)`
  margin-bottom: 16px;
`

export const BreakdownTitle = styled(Flex)`
  padding: 16px;
  align-self: stretch;
  background: var(--grey-100);
`

export const BreakdownItem = styled(Flex)<{ noBorder?: boolean }>`
  padding: 16px;
  ${(p) => !p.noBorder && 'border-top: 1px solid var(--grey-200)'}
`

export const TermsBox = styled(Flex)`
  padding: ${PopupPadding};

  p {
    a {
      color: var(--purple-900);
    }
  }
`

export const ProposalDatesContainer = styled.div`
  padding: ${PopupPadding};
`

export const ProposalDates = styled.div`
  border-radius: 12px;
  border: 1px solid var(--grey-200);
  background: var(--grey-100);
  overflow: hidden;

  div {
    padding: 16px;
  }

  div:first-child {
    border-bottom: 1px solid var(--grey-200);
  }
`

export const Notice = styled.footer`
  ${textVariants['body-s-medium']}
  color: var(--grey-500);
  padding: ${PopupPadding};
`

export const Content = styled.div`
  padding: ${PopupPadding};
  width: 384px;
`

import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react'
import { useRouter } from 'next/router'
import useApi from '@/hooks/useApi'
import { IFinance, IMoreInfo } from '@/models/IVisaSelectorPref'
import { ICountry } from '@/models/ICountry'
import { INationality, QuizContextInterface } from './types'
import { useAuthContext } from '../Auth'

const QuizContext = createContext<QuizContextInterface>(
  {} as QuizContextInterface,
)

export const QuizProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const { user } = useAuthContext()
  const { api } = useApi()
  const [prefsLoaded, setPrefsLoaded] = useState(false)

  const [step, setStep] = useState(0)
  const [leadEmail, setLeadEmail] = useState<string>()
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [isFinished, setFinished] = useState(false)
  const [destination, setDestination] = useState<ICountry>()
  const [nationality, setNationality] = useState<INationality>({
    hasMultiple: false,
    hasLegal: false,
  })
  const [finance, setFinance] = useState<IFinance>({})

  const [moreInfo, setMoreInfo] = useState<IMoreInfo>({
    travelingWith: { alone: true },
  })
  const [anythingElse, setAnythingElse] = useState<string>()

  const [havePrefs, setHavePrefs] = useState<boolean>(false)

  useEffect(() => {
    if (!user) return
    api.explore
      .visaPrefs()
      .then((data) => {
        setPrefsLoaded(true)
        const { prefs: existingPrefs } = data
        if (!existingPrefs) return

        // restore prefs from server
        setDestination(existingPrefs.destination)
        setNationality(existingPrefs.nationality)
        setFinance(existingPrefs.finance)
        setMoreInfo(existingPrefs.moreInfo)
        setAnythingElse(existingPrefs.notes)
        setHavePrefs(true)
      })
      .catch((_error) => {
        setPrefsLoaded(true)
      })
    setFirstName(user.firstName)
    setLastName(user.lastName)
  }, [user])

  const goBack = () => {
    if (step > 0) return setStep(step - 1)
    setFinished(false)
    router.back()
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const handleFinish = () => {
    setFinished(true)
  }

  const handleSetEmail = (email: string) => {
    setLeadEmail(email)
  }

  const handleDestination = (country: ICountry) => {
    setDestination(country)
    setStep(step + 1)
  }

  const handleNationality = (nationality: INationality) => {
    setNationality(nationality)
  }

  const handleFinance = (finance: IFinance) => {
    setFinance(finance)
  }

  const handleMoreInfo = (info: IMoreInfo) => {
    setMoreInfo(info)
  }

  const handleAnythingElse = (string?: string) => {
    setAnythingElse(string)
    setFinished(true)
  }

  const reset = () => {
    setStep(0)
    setFinished(false)
  }

  const handleHavePrefs = (havePrefs: boolean) => {
    setHavePrefs(havePrefs)
  }

  return (
    <QuizContext.Provider
      value={{
        step,
        isFinished,
        finish: handleFinish,
        leadEmail,
        setLeadEmail: handleSetEmail,
        goBack,
        destination,
        setDestination: handleDestination,
        nationality,
        setNationality: handleNationality,
        finance,
        setFinance: handleFinance,
        moreInfo,
        setMoreInfo: handleMoreInfo,
        anythingElse,
        setAnythingElse: handleAnythingElse,
        reset,
        havePrefs,
        setHavePrefs: handleHavePrefs,
        nextStep,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        prefsLoaded,
      }}
    >
      {children}
    </QuizContext.Provider>
  )
}

export const useQuizContext = (): QuizContextInterface => {
  return useContext(QuizContext)
}

import styled from 'styled-components'

export interface AvatarProps {
  size?: number
  url?: string
}

export const Avatar = styled.i<AvatarProps>`
  flex-shrink: 0;
  display: inline-flex;
  background-position: center;
  background-size: cover;
  border-radius: 999px;
  width: ${(p) => p.size || 24}px;
  height: ${(p) => p.size || 24}px;
  background-image: url('${(p) => (p.url ? p.url : '/app/img/avatar.png')}');
`

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Popup } from '@/components/ui'
import { IVisaWizardAnswer } from '@/models/IVisaWizardAnswer'
import { RequestDocumentPopup } from '../Popups/RequestDocument'
import { CongratulationsPopup } from '../Popups'

export const RequestDocumentFlow = forwardRef<
  { trigger: () => void },
  { onSave: (data: IVisaWizardAnswer) => void }
>(({ onSave }, ref) => {
  const [isTriggered, setTriggered] = useState(false)
  const [step, setStep] = useState(0)

  const handleClose = () => {
    setTriggered(false)
    setStep(0)
  }

  const handleSave = (data: IVisaWizardAnswer) => {
    onSave(data)
    setStep(1)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  if (!isTriggered) return null

  return (
    <>
      <Popup isOpen={step === 0}>
        <RequestDocumentPopup onClose={handleClose} onSave={handleSave} />
      </Popup>
      <Popup isOpen={step === 1}>
        <CongratulationsPopup
          title="Done!"
          message="Your request for document has been sent to the applicant"
          hideReceipt
          onClose={handleClose}
        />
      </Popup>
    </>
  )
})

RequestDocumentFlow.displayName = 'RequestDocumentFlow'

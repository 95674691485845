import React from 'react'
import { Button, Icon, Text } from '@/components/ui'
import {
  Actions,
  Cover,
  PopupContent,
  Step,
  Steps,
  Subtitle,
  Title,
} from './styles'
import { BaseWizardPopupProps } from '../types'

interface HireTaxPopupProps extends BaseWizardPopupProps {
  onConfirm: () => void
}

export const HireTaxPopup = ({ onConfirm, onClose }: HireTaxPopupProps) => {
  return (
    <>
      <Cover src="/img/hire_tax_advisor.png" fill="contain">
        <Text variant="heading-h4">Tax help for digital nomads & expats</Text>
        <Text variant="body-m-regular">
          Need help with your taxes? Talk to one of our experts!
        </Text>
        <Button
          variant="grey"
          icon={<Icon.Solid name="xMark" />}
          onClick={onClose}
        />
      </Cover>
      <PopupContent>
        <Title variant="heading-h5">
          Two easy steps to get help with your taxes
        </Title>
        <Subtitle variant="body-m-regular">
          Book a consultation and talk with one of our tax experts!
        </Subtitle>
        <Steps>
          <Step>
            <Text variant="body-s-semibold">Step 1</Text>
            <Text variant="body-m-regular">
              <b>Book a consultation</b> with our tax expert specialized in
              international taxation to get an initial assessment of your
              personal situation.
            </Text>
          </Step>
          <Step>
            <Text variant="body-s-semibold">Step 2</Text>
            <Text variant="body-m-regular">
              <b>We&apos;ll provide</b> personalized recommendations and guide
              you in any further steps to take.
            </Text>
          </Step>
        </Steps>
        <Title variant="heading-h5">We deliver results</Title>
        <Subtitle variant="body-m-regular">
          Read what our satisfied clients have to say about their positive
          experiences using our services.
        </Subtitle>
      </PopupContent>
      <Actions>
        <Button size="large" onClick={onConfirm}>
          Book Meeting
        </Button>
      </Actions>
    </>
  )
}

import styled from 'styled-components'
import { PropsWithChildren } from 'react'
import { Text } from './Text'
import { Icon } from '.'

export type AlertVariants = 'error' | 'success' | 'info' | 'warning'

const variantStyles = {
  error: {
    backgroundColor: 'var(--rose-200)',
    color: 'var(--rose-700)',
    icon: 'exclamationTriangle' as const,
  },
  success: {
    backgroundColor: 'var(--success-3)',
    color: 'var(--neutral-700)',
    icon: 'checkCircle' as const,
  },
  info: {
    backgroundColor: 'var(--blue-200)',
    color: 'var(--neutral-700)',
    icon: 'infoIcon' as const,
  },
  warning: {
    backgroundColor: 'var(--warning-3)',
    color: 'var(--neutral-700)',
    icon: 'warning' as const,
  },
}

const Container = styled.div<{
  bgColor: string
  textColor: string
  $marginTop?: string
}>`
  display: flex;
  flex-direction: row;
  padding: 16px;
  margin-top: ${({ $marginTop }) => $marginTop ?? '20px'};
  border-radius: 12px;
  background-color: ${({ bgColor }) => bgColor};
  column-gap: 8px;
  color: ${({ textColor }) => textColor};

  > i {
    flex-shrink: 0;
  }
`

interface Props extends PropsWithChildren {
  variant?: AlertVariants
}

export const Alert: React.FC<Props> = ({
  variant = 'error',
  children,
  ...rest
}) => {
  if (!Object.keys(variantStyles).includes(variant)) {
    console.warn(
      `Invalid variant "${variant}" provided to Alert component. Falling back to "error".`,
    )
    variant = 'error'
  }
  const { backgroundColor, color, icon } = variantStyles[variant]
  return (
    <Container
      {...rest}
      bgColor={backgroundColor}
      textColor={color}
      role="alert"
      aria-live="polite"
    >
      <Icon.Solid size={24} name={icon} />
      <Text variant="body-m-semibold">{children}</Text>
    </Container>
  )
}

import styled from 'styled-components'

interface Props {
  width?: number
  height?: number
  fill: 'cover' | 'contain'
  src: string
}

export const imageUrl = (url: string) => {
  if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
    return url
  }
  return `/app${url}`
}

export const CRImage = styled.div<Props>`
  width: ${(p) => (p.width ? `${p.width}px` : '100%')};
  height: ${(p) => (p.height ? `${p.height}px` : '100%')};
  background-size: ${(p) => p.fill};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${(p) => imageUrl(p.src)}');
  border-radius: 12px;
`

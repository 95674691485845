import React, { PropsWithChildren, useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import { Button } from '@/components/uiJar/Button'
import { Actions, ErrorBox, PopupContent } from './styles'
import { PaymentPopupProps } from './types'

export default function PaymentForm({
  returnUrl,
  onSuccess,
  children,
}: Pick<PaymentPopupProps, 'returnUrl' | 'onSuccess'> & PropsWithChildren) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) return

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!clientSecret) return

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          onSuccess()
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)
    setMessage(undefined)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  }
  return (
    <>
      <PopupContent>
        {message && (
          <ErrorBox size={3} variant="soft" colorTheme="danger">
            {message}
          </ErrorBox>
        )}
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {children}
      </PopupContent>
      <Actions>
        <Button
          variant="solid"
          size={3}
          colorTheme="neutral"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          Checkout
        </Button>
      </Actions>
    </>
  )
}

import React from 'react'
import { IconLink } from '@/components/uiJar/IconLink'
import { Flex, Icon, Text } from '@/components/ui'
import { Button } from '@/components/uiJar/Button'
import {
  HeadingTitle,
  Hero,
  PopupContainer,
  PopupContent,
  PopupHeading,
  PopupActions,
} from './styles'
import { BaseWizardPopupProps } from '../types'

export const PremiumCongratulationsPopup = ({
  onClose,
}: BaseWizardPopupProps) => {
  const handleContinue = () => {
    onClose()
  }

  return (
    <PopupContainer>
      <PopupHeading>
        <IconLink
          colorTheme="neutral"
          size={4}
          name="arrowLeft"
          type="Solid"
          onClick={handleContinue}
        />
        <HeadingTitle>Welcome to Premium</HeadingTitle>
      </PopupHeading>
      <PopupContent>
        <Hero>
          <Icon.Solid name="circleCheck" size={40} />
          <Flex direction="column" gap={4}>
            <Text variant="semibold-4">
              All set! Welcome to Citizen Remote Premium!
            </Text>
            <Text variant="regular-4" colorVar="neutral-11">
              Enjoy your premium experience in Citizen Remote app! No more
              limitations!
            </Text>
          </Flex>
        </Hero>
      </PopupContent>
      <PopupActions>
        <Button
          variant="solid"
          size={3}
          colorTheme="neutral"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </PopupActions>
    </PopupContainer>
  )
}

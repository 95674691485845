import React from 'react'
import styled from 'styled-components'
import { Media } from '@/utils/media'
import { Flex } from './Flex'
import { Text, textVariants } from './Text'

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  i {
    color: var(--neutral-9);
  }
`

const IconContainer = styled(Flex)`
  justify-content: center;
  margin-bottom: 32px;
`

const Title = styled(Text)`
  margin-bottom: 4px;
  text-align: center;
  color: var(--neutral-12);
`

const Subtitle = styled(Text)`
  margin-bottom: 4px;
  color: var(--neutral-11);
  text-align: center;
`

const ButtonContainer = styled(Flex)`
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  a {
    width: 100%;
  }
  ${Media.Tablet`
    width: fit-content;
    a {
      width: fit-content;
    }
    `}
`

interface EmptyCTAProps {
  title: string | JSX.Element
  titleVariant?: keyof typeof textVariants
  subtitleVariant?: keyof typeof textVariants
  subtitle: string
  icon?: React.ReactNode
  button?: React.ReactNode
}

export const EmptyCTA = ({
  title,
  titleVariant = 'semibold-6',
  subtitleVariant = 'regular-4',
  subtitle,
  icon,
  button,
  ...rest
}: EmptyCTAProps) => {
  return (
    <Container {...rest}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <Title variant={titleVariant}>{title}</Title>
      <Subtitle variant={subtitleVariant}>{subtitle}</Subtitle>
      {button && <ButtonContainer>{button}</ButtonContainer>}
    </Container>
  )
}

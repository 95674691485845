import React, { Fragment, useMemo } from 'react'
import { DateTime } from 'luxon'
import { Popup, PopupTopBar } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { Badge } from '@/components/uiJar/badge'
import { usePremiumSubscription } from '@/contexts/PremiumSubscription'
import { YEARLY_SUBSCRIPTION_PRICE } from '@/contexts/PremiumSubscription/constants'
import {
  Divider,
  Hero,
  PackageDescription,
  PlanDescription,
  PopupContainer,
  PopupContent,
} from './styles'

import { ITEMS } from './constants'
import { Text } from '../Text'
import { Icon } from '../Icon'
import { Flex } from '../Flex'

type ManageSubscriptionPopupProps = {
  isOpen: boolean
  onClose: () => void
  onCancelSubscription: () => void
  onResumeSubscription: () => void
}

const ManageSubscriptionPopup = (props: ManageSubscriptionPopupProps) => {
  const { isOpen, onClose, onCancelSubscription, onResumeSubscription } = props
  const { subscriptionExpiry, isLifeTimeUser, unSubscriptionDetected } =
    usePremiumSubscription()

  const subscriptionDescription = isLifeTimeUser
    ? 'Lifetime subscription'
    : 'Yearly subscription'

  const subscriptionInfo = useMemo(() => {
    const expiryDate = subscriptionExpiry
      ? DateTime.fromISO(subscriptionExpiry as string)
      : DateTime.now()
    if (isLifeTimeUser) {
      return 'You don’t need to worry about payment since you have lifetime premium.'
    } else if (unSubscriptionDetected) {
      const formattedDate = DateTime.fromISO(unSubscriptionDetected as string)
      return `Your subscription was cancelled on ${formattedDate.toFormat(
        'MMMM d, yyyy',
      )}. You will have access to premium features until ${expiryDate?.toFormat(
        'MMMM d, yyyy',
      )}`
    }
    return `Your next billing date is ${
      expiryDate ? expiryDate.toFormat('MMMM d, yyyy') : '-'
    } and you’ll be charged $${YEARLY_SUBSCRIPTION_PRICE}/year`
  }, [subscriptionExpiry, isLifeTimeUser, unSubscriptionDetected])

  const subscriptionFooterHelpText = useMemo(() => {
    if (unSubscriptionDetected) {
      return 'Want to resume your subscription?'
    }
    return `If you cancel your subscription, you’ll have access to all premium
            features until your subscription period ends. Then you’ll lose all
            access to all premium features in Citizen Remote app.`
  }, [unSubscriptionDetected])

  return (
    <Popup fullScreenInMobile isOpen={isOpen} onClose={onClose}>
      <PopupContainer>
        <PopupTopBar onClose={onClose}>
          <Text variant="semibold-4" colorVar="neutral-12">
            Manage subscription
          </Text>
        </PopupTopBar>
        <PopupContent>
          <Hero>
            <Icon.Solid name="star" size={24} />
            <Flex direction="column" gap={4}>
              <Text variant="semibold-4" colorVar="base-white">
                Premium membership
              </Text>
              <Text variant="regular-3" colorVar="base-white">
                {subscriptionDescription}
              </Text>
            </Flex>
          </Hero>

          <Divider />
          <PlanDescription>
            <Icon.Outline name="calendar" size={20} />
            <Text variant="regular-3" colorVar="neutral-11">
              {subscriptionInfo}
            </Text>
          </PlanDescription>

          <Divider />
          <PackageDescription>
            <Text variant="medium-3" colorVar="neutral-11">
              What’s included in Premium?
            </Text>
            <Flex direction="column" gap={24} as="ul">
              {ITEMS.map((item) => (
                <Flex key={item.title} gap={8} as="li">
                  <Icon.Base name={item.icon} size={20} />
                  <Text
                    variant="medium-3"
                    colorVar={item.comingSoon ? 'neutral-11' : 'neutral-12'}
                  >
                    {item.title}
                  </Text>
                  {item.new && (
                    <Badge size={1} variant="soft" colorTheme="accent">
                      new
                    </Badge>
                  )}
                  {item.comingSoon && (
                    <Badge size={1} variant="soft" colorTheme="neutral">
                      coming soon
                    </Badge>
                  )}
                </Flex>
              ))}
            </Flex>
          </PackageDescription>
          {!isLifeTimeUser && (
            <Fragment>
              <Divider />
              <Text variant="medium-2" colorVar="neutral-9">
                {subscriptionFooterHelpText}
              </Text>
              {unSubscriptionDetected ? (
                <Button
                  fullWidth
                  onClick={onResumeSubscription}
                  variant="solid"
                  colorTheme="neutral"
                  size={3}
                >
                  Resume subscription
                </Button>
              ) : (
                <Button
                  fullWidth
                  onClick={onCancelSubscription}
                  variant="soft"
                  colorTheme="danger"
                  size={3}
                >
                  Cancel subscription
                </Button>
              )}
            </Fragment>
          )}
        </PopupContent>
      </PopupContainer>
    </Popup>
  )
}

export default ManageSubscriptionPopup

import React from 'react'
import { Button } from '@/components/ui'
import { Actions, Cover, PopupContent, Subtitle, Title } from './styles'
import { BaseWizardPopupProps } from '../types'

interface Props extends BaseWizardPopupProps {
  onConfirm: () => void
}

export const WelcomeToWizardPopup = ({ onClose, onConfirm }: Props) => {
  return (
    <>
      <Cover src="/img/wizard_welcome.png" fill="contain" />
      <PopupContent>
        <Title variant="heading-h5">Welcome to your visa wizard</Title>
        <Subtitle variant="body-m-regular">
          This is the portal where you&apos;ll have direct access with your
          advisor to ensure a smooth visa application process!
        </Subtitle>
      </PopupContent>
      <Actions>
        <Button variant="grey" size="large" onClick={onClose}>
          Skip tour
        </Button>
        <Button size="large" onClick={onConfirm}>
          Take a quick tour
        </Button>
      </Actions>
    </>
  )
}

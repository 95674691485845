import { IFamilyInfo } from '@/models/IVisaSelectorPref'
import { IWhoCanApply } from '@/models/IVisaWIzard'

interface Item {
  id: keyof Omit<IFamilyInfo, 'alone'>
  title: string
  subtitle: string
}

export const travelingMembers: Item[] = [
  {
    id: 'spouse',
    title: 'Legal Spouse',
    subtitle: 'Must have marriage certificate',
  },
  {
    id: 'children',
    title: 'Children under 18',
    subtitle: 'Must have birth certificate',
  },
  { id: 'pets', title: 'Pets', subtitle: 'Must have documents' },
]

export const WhoCanApplyValues: Record<IWhoCanApply, string> = {
  [IWhoCanApply.EVERYONE]: 'Everyone',
  [IWhoCanApply.EU]: 'EU Only',
  [IWhoCanApply.NON_EU]: 'NON EU Only',
  [IWhoCanApply.OECD]: 'OECD Only',
}

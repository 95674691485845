import React, { PropsWithChildren, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { createPortal } from 'react-dom'
import { motion } from 'framer-motion'
import { useUIState } from '@/contexts/UIState'
import { useTheme } from '@/contexts/Theme'
import { Button } from './Button'
import { Icon } from './Icon'
import { textVariants } from './Text'
import { Flex } from './Flex'

interface Props {
  isOpen: boolean
  children: React.ReactNode
}

interface TopBarProps extends PropsWithChildren {
  onClose?: () => void
}

export interface PopupProps {
  onClose?: () => void
}

export const PopupPadding = '20px'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay-dark-11);
`

const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  background-color: var(--panel-1);
  border: 1px solid var(--neutral-alpha-4);
  z-index: 1;
  overflow: hidden;
  max-height: 100vh;
  position: relative;
`

export const TopBar = styled.div<{ hasChildren: boolean }>`
  ${textVariants['body-l-semibold']}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${PopupPadding};
  height: 76px;
  border-bottom: 0.4px solid var(--grey-300);
  flex-shrink: 0;

  ${(p) =>
    !p.hasChildren &&
    css`
      border-bottom: none;
      position: absolute;
      right: 0;
    `}
`

export const PopupActions = styled(Flex)`
  padding: ${PopupPadding};
  border-top: 0.4px solid var(--grey-300);
  gap: 16px;
  flex-shrink: 0;
`

export const PopupTopBar: React.FC<TopBarProps> = ({ children, onClose }) => {
  return (
    <TopBar hasChildren={!!children}>
      {children}
      {onClose && (
        <Button
          type="button"
          variant="grey"
          icon={<Icon.Solid name="xMark" />}
          onClick={onClose}
        />
      )}
    </TopBar>
  )
}

export const Popup = ({ isOpen, children }: Props): JSX.Element | null => {
  const { incrementOpenedPopupsCount, decrementOpenedPopupsCount } =
    useUIState()
  const { theme } = useTheme()
  useEffect(() => {
    if (isOpen) {
      incrementOpenedPopupsCount()
      return () => {
        decrementOpenedPopupsCount()
      }
    }
  }, [isOpen, incrementOpenedPopupsCount, decrementOpenedPopupsCount])
  if (!isOpen) return null
  return (
    <>
      {createPortal(
        <Container className={theme === 'dark' ? 'dark' : ''}>
          <Background />
          <Content
            initial={{ opacity: 0, translateY: 50 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.2 }}
          >
            {children}
          </Content>
        </Container>,
        document.getElementById('main')!,
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import _, { noop } from 'lodash'
import {
  Button,
  Icon,
  PopupTopBar,
  Radio,
  Skeleton,
  Text,
} from '@/components/ui'
import useApi from '@/hooks/useApi'
import { useDebounce } from '@/hooks/useDebounce'
import { ICountry } from '@/models/ICountry'
import { IPagination } from '@/models/IPagination'
import { PopupProps } from '../quiz/Popups/types'
import {
  Actions,
  Content,
  Options,
  Option,
  OptionContent,
  SearchField,
  SearchContainer,
} from '../quiz/Popups/styles'

interface Props extends PopupProps<ICountry> {
  title?: string
}

export const SelectCountry: React.FC<Props> = ({
  title = 'Select Country',
  onCancel,
  onConfirm,
}) => {
  const { api, status, error } = useApi()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [selected, setSelected] = useState<ICountry>()
  const [countries, setCountries] = useState<ICountry[]>([])
  const [pagination, setPagination] = useState<IPagination>()

  const [ref, { rootRef }] = useInfiniteScroll({
    loading: status === 'loading',
    hasNextPage: !pagination?.isLastPage ?? true,
    onLoadMore: () => loadMore(),
    disabled: !!error,
    rootMargin: '0px 0px 10px 0px',
  })

  useEffect(() => {
    const payload = debouncedSearch.length ? { name: debouncedSearch } : {}
    api.location
      .getCountries(payload)
      .then((data) => {
        setPagination(data)
        setCountries(data.countries)
      })
      .catch(noop)
  }, [debouncedSearch])

  const loadMore = async () => {
    const payload = debouncedSearch.length ? { name: debouncedSearch } : {}
    const data = await api.location
      .getCountries({
        ...payload,
        token: pagination?.token,
      })
      .catch(noop)
    if (data) {
      setPagination(data)
      setCountries([...countries, ...data.countries])
    }
  }

  return (
    <>
      <PopupTopBar onClose={onCancel}>{title}</PopupTopBar>
      <Content>
        <SearchContainer>
          <SearchField
            preIcon={<Icon.Solid name="magnifyingGlass" />}
            placeholder="Search country"
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchContainer>
        <Options ref={rootRef}>
          {countries.map((country, index) => (
            <Option key={index}>
              <OptionContent>
                <Text variant="body-m-regular">
                  {country.flag} {country.name}
                </Text>
              </OptionContent>
              <Radio
                name="country"
                value={country.name}
                checked={country.id === selected?.id}
                onChange={(e) => e.target.checked && setSelected(country)}
              />
            </Option>
          ))}
          {status === 'loading' &&
            countries.length === 0 &&
            _.times(5, (index) => (
              <Option key={index}>
                <OptionContent>
                  <Skeleton height={24} />
                </OptionContent>
                <Skeleton width={24} height={24} />
              </Option>
            ))}
          {!pagination?.isLastPage && (
            <Option ref={ref}>
              <OptionContent>
                <Skeleton height={24} />
              </OptionContent>
              <Skeleton width={24} height={24} />
            </Option>
          )}
        </Options>
      </Content>
      <Actions>
        <Button variant="grey" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="black"
          size="large"
          onClick={() => onConfirm(selected!)}
          disabled={!selected}
        >
          Confirm
        </Button>
      </Actions>
    </>
  )
}

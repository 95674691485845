import { AxiosInstance } from 'axios'

import { IGroup } from '@/models/IGroup'
import { IGroupMember } from '@/models/IGroupMember'
import { IGroupRequest } from '@/models/IGroupRequest'
import { IPagination } from '@/models/IPagination'
import { IPost } from '@/models/IPost'
import { ILiker } from '@/models/ILiker'
import { IComment } from '@/models/IComment'
import { PostTypes } from '@/contexts/CreatePost/types'
import { IUser } from '@/models/IUser'
import { IPoll } from '@/models/IPoll'

interface Pagination {
  pagination: IPagination
}

interface GroupCreateRequest {
  title: string
  about?: string
  fileId?: string
}

interface PostCreateRequest {
  title: string
  content?: string
  type: PostTypes
  fileIds?: string[]
  groupId: string
  pollQuestion?: string
  pollOptions?: string[]
  otherTags?: string[]
  userTags?: string[]
}

interface PostCreateResponse {
  post: IPost
}

interface PollCreateRequest {
  postId: string
  question?: string
  options?: string[]
}

interface GroupUpdateRequest {
  title?: string
  fileId?: string | null
  about?: string
  link?: string
}

export interface GroupSearchRequest {
  text?: string
  isMember?: boolean
  suggested?: boolean
  withCount?: boolean
  forUser?: string
  limit?: number
  offset?: number
  token?: string
}

export interface GroupSearchResponse {
  groups: IGroup[]
}

interface GroupRequestsSearchRequest {
  text?: string
  limit?: number
  offset?: number
  token?: string
}

interface GroupRequestsResponse {
  requests: IGroupRequest[]
  token: string
  isLastPage: boolean
}

interface GroupMembersSearchRequest {
  text?: string
  limit?: number
  offset?: number
  token?: string
}

interface GroupMembersResponse {
  members: IGroupMember[]
  token: string
  isLastPage: boolean
}

interface IApiReportPayload {
  reason: string | null
  id: string
  type?: 'group' | 'post' | 'user'
}

export interface PostSearchRequest {
  type?: string
  text?: string
  groupId?: string
  feed?: 'mine' | 'all'
  authorId?: string
  sortBy?: string
  limit?: number
  offset?: number
  token?: string
  platform?: 'web'
}

export interface UserSearchRequest {
  text?: string
  limit?: number
}

export interface UserSearchResponse {
  users: IUser[]
}

export interface PostSearchResponse {
  posts: IPost[]
}

interface LikesSearchResponse {
  likes: ILiker[]
}

interface LikesSearchResquest {
  id?: string
  token?: string
}

export interface CommentSearchRequest {
  parentType?: 'comment' | 'post'
  parentId?: string
  sortBy?: 'recent' | 'hot'
  limit?: number
  offset?: number
  token?: string
  platform?: 'web'
}

interface CommentSearchResponse {
  comments: IComment[]
}

export interface CreateCommentRequest {
  content: string
  parentType: 'post' | 'comment'
  parentId: string
  platform?: 'web'
}

export interface CommentUpdateRequest {
  content: string
}

interface CreateCommentResponse {
  comment: IComment
  commentsCount: number
}

export class SocialService {
  constructor(private client: AxiosInstance) {}

  checkTitle = (title: string) =>
    this.client
      .get<IGroup | null>(`/groups/check/${title}`)
      .then((response) => response.data)

  create = (body: GroupCreateRequest) =>
    this.client.post<IGroup>('/groups', body).then((response) => response.data)

  updateGroup = (id: string, body: GroupUpdateRequest) =>
    this.client.patch<IGroup>(`/groups/${id}`, body)

  getGroup = (id: string) =>
    this.client.get<IGroup>(`/groups/${id}`).then((response) => response.data)

  getPost = (id: string) =>
    this.client.get<IPost>(`/posts/web/${id}`).then((response) => response.data)

  deletePost = (id: string) => this.client.delete(`/posts/${id}`)

  getPostLikes = (body: LikesSearchResquest) =>
    this.client
      .get<LikesSearchResponse & IPagination>(`/posts/${body.id}/likes`)
      .then((response) => response.data)

  getGroups = (body: GroupSearchRequest) =>
    this.client
      .get<GroupSearchResponse & IPagination>('/groups', {
        params: body,
      })
      .then((response) => response.data)

  getPosts = (body: PostSearchRequest) =>
    this.client
      .get<PostSearchResponse & IPagination>('/posts', {
        params: body,
      })
      .then((response) => response.data)

  joinGroup = (id: string) =>
    this.client
      .post(`/groups/${id}/join`, { id })
      .then((response) => response.data)

  requestToJoin = (id: string) =>
    this.client
      .post(`/groups/${id}/request`, { id })
      .then((response) => response.data)

  likePost = (id: string) =>
    this.client
      .post(`/posts/${id}/like`, { id })
      .then((response) => response.data)

  getRequests = (id: string, body: GroupRequestsSearchRequest) =>
    this.client
      .get<GroupRequestsResponse>(`/groups/${id}/requests`, {
        params: body,
      })
      .then((response) => response.data)

  getMembers = (id: string, body: GroupMembersSearchRequest) =>
    this.client.get<GroupMembersResponse>(`/groups/${id}/members`, {
      params: body,
    })

  acceptRequest = (id: string, requestId: string) =>
    this.client
      .post(`/groups/${id}/accept/${requestId}`, {})
      .then((response) => response.data)

  rejectRequest = (id: string, requestId: string) =>
    this.client
      .delete(`/groups/${id}/reject/${requestId}`)
      .then((response) => response.data)

  leaveGroup = (id: string) =>
    this.client.delete(`/groups/${id}/leave`).then((response) => response.data)

  removeMember = (id: string, membershipId: string) =>
    this.client.delete(`/groups/${id}/remove/${membershipId}`)

  deleteGroup = (id: string) =>
    this.client.delete(`/groups/${id}`).then((response) => response.data)

  votePoll = (pollId: string, option: string) =>
    this.client.post(`/poll/${pollId}`, { option })

  removeVotePoll = (pollId: string) => this.client.delete(`/poll/${pollId}`)

  getComments = (body: CommentSearchRequest) =>
    this.client
      .get<CommentSearchResponse & IPagination>('/comments', {
        params: body,
      })
      .then((response) => response.data)

  getComment = (id: string) =>
    this.client
      .get<IComment>(`/comments/web/${id}`)
      .then((response) => response.data)

  likeComment = (id: string) =>
    this.client
      .post(`/comments/${id}/like`, { id })
      .then((response) => response.data)

  createComment = (body: CreateCommentRequest) =>
    this.client
      .post<CreateCommentResponse>('/comments', body)
      .then((response) => response.data)

  updateComment = (id: string, body: CommentUpdateRequest) =>
    this.client
      .patch<IComment>(`/comments/${id}`, body)
      .then((response) => response.data)

  deleteComment = (id: string) => this.client.delete(`/comments/${id}`)

  createPost = async (body: PostCreateRequest) =>
    this.client
      .post<PostCreateResponse>('/posts/v3', body)
      .then((response) => response.data)

  createPoll = (body: PollCreateRequest) =>
    this.client.post<IPoll>('/poll', body).then((response) => response.data)

  updatePost = (id: string, body: PostCreateRequest) =>
    this.client
      .patch<IPost>(`/posts/${id}`, body)
      .then((response) => response.data)

  getUser = (id: string) =>
    this.client
      .get<IUser>(`/users/user/${id}`)
      .then((response) => response.data)

  getUsers = (body: UserSearchRequest) =>
    this.client
      .get<UserSearchResponse & IPagination>('/users/search', {
        params: body,
      })
      .then((response) => response.data)

  report = ({ id, reason, type = 'post' }: IApiReportPayload) =>
    this.client
      .post(`/report/${type}/${id}`, {
        reason,
      })
      .then((response) => response.data)

  blockUser = async (id: string, action: 'block' | 'unblock') =>
    this.client.post(`/users/${id}/${action}`).then((response) => response.data)
}

import styled from 'styled-components'
import { Media } from '@/utils/media'
import {
  FullScreenMobileContainer,
  DefaultPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'

import { textVariants } from '../Text'
import { Flex } from '../Flex'
import { Text } from '../Text'

export const PopupContainer = styled(FullScreenMobileContainer)`
  position: relative;
  height: 550px;

  ${Media.Tablet`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    `}
`

export const PopupContent = styled(DefaultPopupContentWrapper)`
  position: relative;
  background-image: url('/app/img/black-friday/top.svg');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 16px;
  overflow: auto;
  flex-grow: 1;
  overflow: hidden;
`
export const Hero = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  gap: 24px;
  text-align: center;
`
export const HeroImage = styled.img`
  height: 64px;
  width: auto;
`
export const TagLine = styled.p`
  width: 100%;
  ${textVariants['regular-3']}
  color: var(--neutral-alpha-9);
  font-style: italic;
  text-align: center;
  padding: 16px;
`

export const EphesizedText = styled.span`
  ${textVariants['semibold-4']}
  color: var(--neutral-11);
`

export const PromoCode = styled(Text)`
  ${textVariants['semibold-4']}
  color: var(--neutral-12);
  text-align: center;
  padding: 8px 16px;
  background: transparent;
  position: relative;
  border-radius: 999px; /* Increased from 38px */
  width: max-content;
  margin: 0 auto;
  border: none;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 1px;
    border-radius: 999px;
    background: linear-gradient(135deg, #ff5fe5 0%, #ff8a02 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`
export const CloseWrapper = styled(Flex)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
`
export const Footer = styled(Flex)<{ $isDark?: boolean }>`
  text-align: center;
  gap: 8px;
  background-image: url('/app/img/black-friday/bot.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  height: 270px;
  padding: 32px;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0px;
`

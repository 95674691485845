import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { DateTime } from 'luxon'
import { Popup } from '@/components/ui'
import { useWizardContext } from '@/contexts/Wizard'
import { useAuthContext } from '@/contexts/Auth'
import { useQuizContext } from '@/contexts/Quiz'
import { CongratulationsPopup, SpainStepOne, SpainStepTwo } from '../Popups'
import {
  SpainStepOneData,
  SpainStepTwoData,
} from '../Popups/InsuranceSpain/types'

const finalMessage = () =>
  `Your application for health insurance has been sent. We will reach out to you soon.`

export const SpainInsuranceFlow = forwardRef<{ trigger: () => void }>(
  (_, ref) => {
    const { user } = useAuthContext()
    const { moreInfo } = useQuizContext()

    const [isTriggered, setTriggered] = useState(false)
    const [step, setStep] = useState(0)
    const [stepOne, setStepOne] = useState<SpainStepOneData>()
    const [stepTwo, setStepTwo] = useState<SpainStepTwoData>()

    useEffect(() => {
      if (!user) return
      const dob = user.birthday && DateTime.fromISO(user.birthday)
      setStepOne({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        dob: dob
          ? {
              day: dob.day,
              month: dob.month,
              year: dob.year,
            }
          : {
              day: 0,
              month: 0,
              year: 0,
            },
        familyInfo: moreInfo.travelingWith?.children
          ? moreInfo.travelingWith
          : {
              alone: false,
              spouse: 0,
              children: 0,
              pets: 0,
            },
      })
    }, [user, moreInfo])

    const handleClose = () => {
      setTriggered(false)
      setStep(0)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => setTriggered(true),
        }
      },
      [],
    )

    if (!isTriggered) return null

    return (
      <>
        <Popup isOpen={step === 0}>
          <SpainStepOne
            data={stepOne}
            onClose={handleClose}
            onContinue={(data) => {
              setStepOne(data)
              setStep(1)
            }}
          />
        </Popup>
        <Popup isOpen={step === 1}>
          <SpainStepTwo
            data={stepTwo}
            stepOneData={stepOne}
            onClose={handleClose}
            onBack={() => setStep(0)}
            onContinue={(data) => {
              setStepTwo(data)
              setStep(2)
            }}
          />
        </Popup>
        <Popup isOpen={step === 2}>
          <CongratulationsPopup
            title="Spain, here we come!"
            message={finalMessage()}
            onClose={handleClose}
          />
        </Popup>
      </>
    )
  },
)

SpainInsuranceFlow.displayName = 'SpainInsuranceFlow'

import { ISubscription } from '@/models/ISubscription'

export enum PremiumUpgradeReason {
  SETTING = 'settings',
  ACCESS_SOCIAL = 'access_social',
}
/**
 * * Context interface for managing premium subscription state and actions
 */
export interface PremiumSubscriptionContextInterface {
  /** Whether the user has an active subscription */
  subscribed: boolean
  /** Opens the premium upgrade flow with specified reason */
  openUpgradePremiumFlow: (reason: PremiumUpgradeReason) => void
  /** Opens the subscription management flow for current subscribers */
  openManageSubscriptionFlow: () => void
  /** Whether the subscription data has been loaded */
  loaded: boolean
  /** Current subscription details if available */
  subscription?: ISubscription
  /** ISO 8601 formatted expiry date of the subscription */
  subscriptionExpiry?: string
  /** Whether the user has a lifetime subscription */
  isLifeTimeUser: boolean
  /**Date if the user has unsubscribed */
  unSubscriptionDetected: string | null
}

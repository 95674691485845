import styled from 'styled-components'
import { Media } from '@/utils/media'
import {
  FullScreenMobileContainer,
  DefaultPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'

import { textVariants } from '../Text'
import { Flex } from '../Flex'

export const PopupContainer = styled(FullScreenMobileContainer)`
  position: relative;

  ${Media.Tablet`
    display: flex;
    flex-direction: column;
    height: 722px;
    overflow: hidden;
    `}
`

export const PopupContent = styled(DefaultPopupContentWrapper)`
  position: relative;
  padding: 16px;
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 184px;
`
export const Hero = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  gap: 24px;
  text-align: center;
`
export const HeroImage = styled.img`
  height: 64px;
  width: auto;
`
export const TagLine = styled.p`
  width: 100%;
  ${textVariants['regular-3']}
  font-style: italic;
  text-align: center;
  padding: 16px;
`
export const Body = styled(Flex)`
  padding: 24px;
  gap: 20px;
  flex-direction: column;
  background-color: var(--neutral-2);
  border-radius: 12px;
`
export const CloseWrapper = styled(Flex)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
`
export const Footer = styled(Flex)<{ $isDark?: boolean }>`
  text-align: center;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  padding: 32px;
  z-index: 1;
  height: 168px;
  left: 0;
  right: 0;
  bottom: 0px;
  background: ${({ $isDark }) =>
    $isDark
      ? 'linear-gradient(0deg, #000000 58.93%, rgba(0, 0, 0, 0) 100%)'
      : 'linear-gradient(0deg, #ffffff 58.93%, rgba(255, 255, 255, 0) 100%)'};
`

import React, { ElementType, Fragment, HtmlHTMLAttributes } from 'react'
import styled, {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemedStyledProps,
} from 'styled-components'
import { Icon, OtherIcon, OutlineIcon, SolidIcon } from '@/components/ui/Icon'
import { solid, outline, other } from '@/components/ui/icons'

type IconColorTheme =
  | 'primary'
  | 'accent'
  | 'neutral'
  | 'danger'
  | 'success'
  | 'warning'
type IconSize = 1 | 2 | 3 | 4
type IconType = 'Base' | 'Outline' | 'Solid'
type IconName = OtherIcon | OutlineIcon | SolidIcon

export interface IconLinkProps extends HtmlHTMLAttributes<HTMLDivElement> {
  colorTheme: IconColorTheme
  size: IconSize
  type: IconType
  name: IconName
  highContrast?: boolean
  as?: ElementType
}

const colorTheme: Record<
  IconColorTheme,
  FlattenInterpolation<ThemedStyledProps<IconLinkProps, IconLinkProps>>
> = {
  neutral: css<IconLinkProps>`
    color: ${(p) =>
      p.highContrast ? 'var(--neutral-alpha-12)' : 'var(--neutral-alpha-11)'};
    &:hover {
      color: ${(p) =>
        p.highContrast ? 'var(--neutral-alpha-11)' : 'var(--neutral-alpha-12)'};
    }
  `,
  primary: css`
    // to be implemented
  `,
  accent: css`
    // to be implemented
  `,
  danger: css`
    // to be implemented
  `,
  success: css`
    // to be implemented
  `,
  warning: css`
    // to be implemented
  `,
}

const sizeCss: Record<IconSize, FlattenSimpleInterpolation> = {
  1: css`
    height: 20px;
    width: 20px;
  `,
  2: css`
    height: 22px;
    width: 22px;
  `,
  3: css`
    height: 24px;
    width: 24px;
  `,
  4: css`
    height: 28px;
    width: 28px;
  `,
}

const iconSize: Record<IconSize, number> = {
  1: 16,
  2: 18,
  3: 20,
  4: 24,
}

const StyledIconLink = styled.div<IconLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease-out all;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  ${(p) => sizeCss[p.size]}
  ${(p) => colorTheme[p.colorTheme]}
`

const IconRenderer: React.FC<{
  size: IconSize
  iconType: IconType | undefined
  iconName?: OtherIcon | OutlineIcon | SolidIcon
}> = (props) => {
  const { iconType, iconName, size } = props
  if (!iconName) return <Fragment />
  if (iconType === 'Base' && iconName in other) {
    return <Icon.Base name={iconName as OtherIcon} size={iconSize[size]} />
  }
  if (iconType === 'Outline' && iconName in outline) {
    return <Icon.Outline name={iconName as OutlineIcon} size={iconSize[size]} />
  }
  if (iconType === 'Solid' && iconName in solid) {
    return <Icon.Solid name={iconName as SolidIcon} size={iconSize[size]} />
  }
  return <Fragment />
}

export const IconLink = React.forwardRef<HTMLDivElement, IconLinkProps>(
  (props, ref) => {
    const { size, type, name } = props

    return (
      <StyledIconLink ref={ref} {...props}>
        <IconRenderer size={size} iconType={type} iconName={name} />
      </StyledIconLink>
    )
  },
)

IconLink.displayName = 'IconLink'

import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from './Icon'
import { Text } from './Text'
import { IconContainer as IconContainerBase } from './IconContainer'

type Variant = 'blue' | 'orange' | 'purple' | 'grey'

interface CTAProps {
  variant: Variant
  icon?: React.ReactNode
  title: string
  subtitle: string
  isStatic?: boolean
}

const IconContainer = styled(IconContainerBase)`
  margin-right: 12px;
`

const Container = styled.div<{ variant: Variant }>`
  display: flex;
  padding: 16px;
  border-radius: 12px;
  border: 0.4px solid;
  color: var(--grey-900);
  align-items: center;

  ${(p) =>
    p.variant === 'blue' &&
    css`
      border-color: var(--blue-200);
      background-color: var(--blue-50);
    `}

  ${(p) =>
    p.variant === 'orange' &&
    css`
      border-color: var(--orange-200);
      background-color: var(--orange-50);
    `}

    ${(p) =>
    p.variant === 'purple' &&
    css`
      border-color: var(--purple-200);
      background-color: var(--purple-50);
    `}

    ${(p) =>
    p.variant === 'grey' &&
    css`
      border-color: var(--grey-200);
      background-color: var(--grey-50);
    `}
`

export const Content = styled.div`
  flex-grow: 1;
`

export const Action = styled(Icon.Solid)``

export const CTA = ({
  variant,
  icon,
  title,
  subtitle,
  isStatic = false,
  ...rest
}: CTAProps) => {
  return (
    <Container variant={variant} {...rest}>
      {icon && (
        <IconContainer size={32} variant={variant}>
          {icon}
        </IconContainer>
      )}
      <Content>
        <Text variant="body-m-semibold">{title}</Text>
        <Text variant="body-m-regular" className="cta-subtitle">
          {subtitle}
        </Text>
      </Content>
      {!isStatic && <Action name="chevronRight" size={20} />}
    </Container>
  )
}

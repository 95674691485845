import React, { useEffect, useMemo, useState } from 'react'
import { noop } from 'lodash'
import {
  Button,
  Checkbox,
  FileField,
  PopupTopBar,
  Select,
  SelectOption,
  TextField,
} from '@/components/ui'
import useApi from '@/hooks/useApi'
import { useWizardContext } from '@/contexts/Wizard'
import { IFileRef } from '@/models/IFile'
import { IAdvisorFileTemplate } from '@/models/IAdvisorFileTemplate'
import { RequestDocumentPopupProps } from './types'
import { Actions, Label, PopupContent } from './styles'
import { defaultOption } from './constants'

export const RequestDocumentPopup = ({
  onSave,
  onClose,
}: RequestDocumentPopupProps) => {
  const { userWizard } = useWizardContext()
  const { api, status } = useApi()
  const [name, setName] = useState('')
  const [notes, setNotes] = useState('')
  const [save, setSave] = useState(true)
  const [addAutomatically, setAutomatically] = useState(true)
  const [file, setFile] = useState<File[]>()
  const [template, setTemplate] = useState<SelectOption>(defaultOption)
  const [templates, setTemplates] = useState<IAdvisorFileTemplate[]>([])

  useEffect(() => {
    if (template.value === 'new') {
      setSave(true)
    } else {
      setSave(false)
      const templateRecord = templates.find((t) => t.id === template.value)
      if (!templateRecord) return
      setName(templateRecord.label)
      setNotes(templateRecord.description || '')
    }
  }, [template, templates])

  useEffect(() => {
    api.advisor.getTemplates().then(setTemplates)
  }, [api])

  const options = useMemo(() => {
    return [
      defaultOption,
      ...templates.map((t) => ({ value: t.id, label: t.label })),
    ]
  }, [templates])

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()
    let uploaded: IFileRef | null = null
    const templateRecord = templates.find((t) => t.id === template.value)

    if (file) {
      const formData = new FormData()
      formData.append('file', file[0])
      uploaded = await api.advisor.uploadFile(formData)
      if (!uploaded) return
    } else if (templateRecord) {
      uploaded = templateRecord.files ?? null
    }

    api.advisor
      .requestDocument(userWizard.id, {
        name,
        notes: notes.length ? notes : undefined,
        file: uploaded ?? undefined,
        save,
        addAutomatically: addAutomatically && save,
      })
      .then((data) => {
        onSave(data)
      })
      .catch(noop)
  }

  return (
    <>
      <PopupTopBar onClose={onClose}>Request Document</PopupTopBar>
      <form onSubmit={handleSave}>
        <PopupContent>
          <Select
            size={1}
            options={options}
            value={template}
            onChange={(e) => setTemplate(e as SelectOption)}
          />
          <TextField
            required
            placeholder="What is that document? *"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            multiline
            rows={4}
            placeholder="Document Description (optional)"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Checkbox
            disabled={template.value !== 'new'}
            checked={save}
            onChange={(e) => setSave(e.target.checked)}
          >
            Save as new template
          </Checkbox>
          {save && (
            <Checkbox
              checked={addAutomatically}
              onChange={(e) => setAutomatically(e.target.checked)}
            >
              Automatically ask for new cases
            </Checkbox>
          )}
          <Label>
            Example document
            <FileField onSelect={setFile} subtitle={file && file[0].name} />
          </Label>
        </PopupContent>
        <Actions>
          <Button variant="black" size="large" isLoading={status === 'posting'}>
            Continue
          </Button>
        </Actions>
      </form>
    </>
  )
}

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import { LayoutSearchContextType } from './types'

const LayoutSearchContext = createContext<LayoutSearchContextType>({
  searchQuery: '',
  searchDraft: '',
  setSearchDraft: () => {},
  setSearchQuery: () => {},
  searchCallback: () => {},
  handleSetSearchCallback: () => {},
})

export const LayoutSearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchDraft, setSearchDraft] = useState('')
  const [searchCallback, setSearchCallback] = useState<(() => void) | null>(
    null,
  )
  const handleSetSearchCallback = useCallback((callback: () => void) => {
    setSearchCallback(() => callback)
  }, [])

  return (
    <LayoutSearchContext.Provider
      value={{
        searchDraft,
        searchQuery,
        setSearchDraft,
        setSearchQuery,
        searchCallback,
        handleSetSearchCallback,
      }}
    >
      {children}
    </LayoutSearchContext.Provider>
  )
}

export const useLayoutSearchContext = () => useContext(LayoutSearchContext)

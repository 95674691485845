import styled from 'styled-components'
import {
  Button,
  Flex,
  PopupActions,
  PopupPadding,
  textVariants,
} from '@/components/ui'
import { Media } from '@/utils/media'

export const PopupContent = styled(Flex)`
  ${textVariants['body-m-regular']}
  flex-direction: column;
  padding: ${PopupPadding};
  width: 384px;
  height: 480px;

  ${Media.Desktop`
    max-height: 450px;
  `}

  p {
    margin: 10px 0px;
  }

  li {
    font-weight: bold;
    margin-left: ${PopupPadding};
  }
`

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`

export const Actions = styled(PopupActions)`
  button {
    width: 100%;
  }
`

import React, { ButtonHTMLAttributes, ElementType } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { textVariants } from '@/components/ui/Text'
import {
  Icon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '@/components/ui/Icon'
import IconRenderer from '../helper/IconRenderer'

type ButtonVariant = 'solid' | 'soft' | 'outline' | 'ghost' | 'alternative'
type ButtonColorTheme = 'primary' | 'accent' | 'neutral' | 'danger'
type ButtonSize = 1 | 2 | 3 | 4 | 5

interface IconProps {
  type: IconType
  startIconName?: OtherIcon | OutlineIcon | SolidIcon
  endIconName?: OtherIcon | OutlineIcon | SolidIcon
}

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  variant: ButtonVariant
  colorTheme: ButtonColorTheme
  size: ButtonSize
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  as?: ElementType
  icon?: IconProps
  fullWidth?: boolean
  iconButton?: boolean
  isLoading?: boolean
}

const solidVariant: Record<ButtonColorTheme, FlattenSimpleInterpolation> = {
  primary: css`
    // to be implemented
  `,
  accent: css`
    // to be implemented
  `,
  neutral: css`
    background-color: var(--neutral-12);
    color: var(--neutral-1);
    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
  danger: css`
    background-color: var(--danger-9);
    color: var(--neutral-1);
    &:hover {
      background-color: var(--danger-10);
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
}

const softVariant: Record<ButtonColorTheme, FlattenSimpleInterpolation> = {
  primary: css`
    // to be implemented
  `,
  accent: css`
    // to be implemented
  `,
  neutral: css`
    background-color: var(--neutral-alpha-3);
    color: var(--neutral-12);
    &:hover {
      background-color: var(--neutral-alpha-4);
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
  danger: css`
    background-color: var(--danger-alpha-3);
    color: var(--danger-11);
    &:hover {
      background-color: var(--danger-alpha-4);
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
}

const outlineVariant: Record<ButtonColorTheme, FlattenSimpleInterpolation> = {
  primary: css`
    // to be implemented
  `,
  accent: css`
    // to be implemented
  `,
  neutral: css`
    border: 1px solid var(--neutral-alpha-6);
    color: var(--neutral-12);
    background-color: transparent;
    &:hover {
      background-color: var(--neutral-alpha-3);
      border-color: var(--neutral-alpha-3);
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
  danger: css`
    border: 1px solid var(--danger-alpha-6);
    color: var(--danger-11);
    &:hover {
      background-color: var(--danger-alpha-2);
      border-color: var(--danger-alpha-2);
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
}

const ghostVariant: Record<ButtonColorTheme, FlattenSimpleInterpolation> = {
  primary: css`
    // to be implemented
  `,
  accent: css`
    // to be implemented
  `,
  neutral: css`
    color: var(--neutral-12);
    background-color: transparent;
    &:hover {
      background-color: var(--neutral-alpha-3);
    }
    &:disabled {
      color: var(--neutral-alpha-8);
      background-color: var(--panel-3);
    }
  `,
  danger: css`
    // to be implemented
  `,
}

const alternativeVariant: Record<ButtonColorTheme, FlattenSimpleInterpolation> =
  {
    primary: css`
      // to be implemented
    `,
    accent: css`
      // to be implemented
    `,
    neutral: css`
      border: 1px solid var(--neutral-alpha-6);
      color: var(--neutral-12);
      background-color: var(--panel-1);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
        0px 0px 0px 1px rgba(0, 0, 0, 0);
      &:hover {
        border-color: var(--panel-3);
      }
      &:disabled {
        color: var(--neutral-alpha-8);
        border-color: var(--panel-3);
      }
    `,
    danger: css`
      // to be implemented
    `,
  }

const variantsMap: Record<
  ButtonVariant,
  Record<ButtonColorTheme, FlattenSimpleInterpolation>
> = {
  solid: solidVariant,
  soft: softVariant,
  outline: outlineVariant,
  ghost: ghostVariant,
  alternative: alternativeVariant,
}

const getSizeCss = (iconButton?: boolean) => ({
  1: css`
    ${textVariants['medium-2']}
    height: 24px;
    ${!iconButton && 'padding: 4px 8px;'}
    ${iconButton && 'width: 24px;'}
    border-radius: 4px;
    gap: 4px;
  `,
  2: css`
    ${textVariants['medium-3']}
    height: 32px;
    ${!iconButton && 'padding:  6px 12px;'}
    ${iconButton && 'width: 32px;'}
    border-radius: 6px;
    gap: 4px;
  `,
  3: css`
    ${textVariants['medium-3']}
    height: 40px;
    ${!iconButton && 'padding:  10px 16px;'}
    ${iconButton && 'width: 40px;'}
    border-radius: 8px;
    gap: 4px;
  `,
  4: css`
    ${textVariants['medium-4']}
    height: 48px;
    ${!iconButton && 'padding: 12px 26px;'}
    ${iconButton && 'width: 48px;'}
    border-radius: 10px;
    gap: 8px;
  `,
  5: css`
    ${textVariants['medium-5']}
    height: 56px;
    ${!iconButton && 'padding: 14px 20px;'}
    ${iconButton && 'width: 56px;'}
    border-radius: 12px;
    gap: 8px;
  `,
})

const iconSize: Record<ButtonSize, number> = {
  1: 16,
  2: 18,
  3: 20,
  4: 24,
  5: 34,
}

const StyledButton = styled.button<ButtonProps>`
  font-family: var(--font-inter);
  display: flex;
  width: ${(p) => (p.fullWidth ? '100%' : 'fit-content')};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease-out all;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  ${(p) => getSizeCss(p.iconButton)[p.size]}
  ${(p) => variantsMap[p.variant][p.colorTheme]}
`

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      size,
      colorTheme,
      fullWidth,
      children,
      icon,
      iconButton,
      isLoading,
      ...rest
    },
    ref,
  ) => {
    const { startIconName, endIconName, type } = icon || {
      startIcon: undefined,
      endIcon: undefined,
      type: undefined,
    }

    iconButton = iconButton || !children

    return (
      <StyledButton
        ref={ref}
        variant={variant}
        size={size}
        colorTheme={colorTheme}
        fullWidth={fullWidth}
        iconButton={iconButton}
        {...rest}
      >
        {!isLoading && (
          <IconRenderer
            sizeMap={iconSize}
            size={size}
            iconType={type}
            iconName={startIconName}
          />
        )}

        {children}
        <IconRenderer
          sizeMap={iconSize}
          size={size}
          iconType={type}
          iconName={endIconName}
        />

        {isLoading && (
          <IconRenderer
            sizeMap={iconSize}
            size={size}
            iconType={'Other'}
            iconName={'Loading'}
          />
        )}
      </StyledButton>
    )
  },
)

Button.displayName = 'Button'

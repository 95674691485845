import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { textVariants } from './Text'
import { Icon, SolidIcon } from './Icon'

const MenuContainer = styled.ul`
  padding: 8px;
  width: 214px;
`
// Todo: Implement disabled UI
const MenuItem = styled.li`
  ${textVariants['body-m-semibold']}
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 8px 8px 36px;
  cursor: pointer;

  &:hover {
    background-color: var(--grey-100);
  }

  i {
    position: absolute;
    left: 8px;
  }
`

export interface MenuItem {
  title?: string
  icon?: SolidIcon
  disabled?: boolean
}

interface Props<T extends MenuItem> {
  items: Array<T>
  onSelect: (item: T) => void | Promise<void>
}

export const Menu = <T extends MenuItem>({ items, onSelect }: Props<T>) => {
  const [processingIndex, setProcessingIndex] = useState<number>(-1)

  const handleSelect = async (e: React.MouseEvent, index: number) => {
    e.stopPropagation()
    e.preventDefault()
    const value = onSelect(items[index])
    if (typeof value === 'object' && typeof value.then === 'function') {
      setProcessingIndex(index)
      await value.finally(() => setProcessingIndex(-1))
    } else {
      setProcessingIndex(-1)
    }
  }

  return (
    <MenuContainer>
      {items.map((it, index) => (
        <MenuItem
          key={index}
          onClick={(e) => {
            if (!it.disabled) handleSelect(e, index)
          }}
        >
          <>
            {it.icon && processingIndex !== index && (
              <Icon.Solid size={20} name={it.icon} />
            )}
            {processingIndex === index && (
              <Icon.Base name="Loading" size={20} />
            )}
            {it.title}
          </>
        </MenuItem>
      ))}
    </MenuContainer>
  )
}

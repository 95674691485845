import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { UIStateContextInterface } from './types'

const UIStateContext = createContext<UIStateContextInterface | undefined>(
  undefined,
)

type UIStateProviderProps = {
  children: ReactNode
}

export const UIStateProvider = ({ children }: UIStateProviderProps) => {
  const [openedPopupsCount, setOpenedPopupsCount] = useState(0)

  const incrementOpenedPopupsCount = useCallback(() => {
    setOpenedPopupsCount((openedPopupsCount) => openedPopupsCount + 1)
  }, [])

  const appHeaderRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    appHeaderRef.current = document.getElementById('app-header')
  }, [])

  const decrementOpenedPopupsCount = useCallback(() => {
    setOpenedPopupsCount((openedPopupsCount) => {
      if (openedPopupsCount > 0) {
        return openedPopupsCount - 1
      }
      return openedPopupsCount
    })
  }, [])

  useEffect(() => {
    if (openedPopupsCount > 0) {
      //disable body scroll
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth
      document.body.style.marginRight = `${scrollbarWidth}px`
      document.body.classList.add('scroll-lock')
      if (appHeaderRef.current) {
        appHeaderRef.current.style.right = `${scrollbarWidth}px`
      }
    } else {
      //enable body scroll
      document.body.classList.remove('scroll-lock')
      document.body.style.marginRight = ''
      if (appHeaderRef.current) {
        appHeaderRef.current.style.right = ''
      }
    }
  }, [openedPopupsCount])

  return (
    <UIStateContext.Provider
      value={{
        openedPopupsCount,
        incrementOpenedPopupsCount,
        decrementOpenedPopupsCount,
      }}
    >
      {children}
    </UIStateContext.Provider>
  )
}

export const useUIState = () => {
  const context = useContext(UIStateContext)
  if (!context) {
    throw new Error('useUIState must be used within a UIStateProvider')
  }
  return context
}

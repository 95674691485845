import { AxiosInstance } from 'axios'

interface Price {
  currency: string
  value: number
}
interface VisaProduct {
  id: string
  attributes: {
    country: string
    title: string
    description: string
    enforcement: string
    more: string[]
    hasVariants: boolean
    price: Price
    processingTime: string[]
    validity: string[]
  }
  type: string
  relationships: {
    country: { id: string }
  }
}

interface ProcedureResponse {
  meta: {
    copyright: string
    version: string
  }
  data: VisaProduct[]
}

interface ISherpaService {
  getProd(destinations?: string, nationality?: string): Promise<VisaProduct[]>
  getProce(
    destinations?: string,
    nationality?: string,
  ): Promise<ProcedureResponse>
}

export class SherpaService implements ISherpaService {
  constructor(private instance: AxiosInstance) {}

  private validateInputs(destinations: string, nationality: string): void {
    if (!destinations && !nationality) {
      throw new Error(
        'At least one parameter (destinations or nationality) must be provided',
      )
    }
    if (destinations && !/^[A-Z,\s]+$/i.test(destinations)) {
      throw new Error(
        'Invalid destinations format. Use comma-separated country codes.',
      )
    }
    if (nationality && !/^[A-Z]+$/i.test(nationality)) {
      throw new Error('Invalid nationality format. Use country code.')
    }
  }

  getProd = async (
    destinations: string = '',
    nationality: string = '',
  ): Promise<VisaProduct[]> => {
    try {
      this.validateInputs(destinations, nationality)

      const sanitizedDestinations = destinations.trim().toUpperCase()
      const sanitizedNationality = nationality.trim().toUpperCase()
      const response = await this.instance.get<VisaProduct[]>(
        `https://api.joinsherpa.io/v1/products?destinations=${sanitizedDestinations}&nationality=${sanitizedNationality}`,
        {
          headers: { 'x-api-key': process.env.NEXT_PUBLIC_SHERPA_API_KEY },
          timeout: 5000,
        },
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw new Error('Network error: Unable to reach Sherpa API')
    }
  }

  getProce = async (
    destinations: string = '',
    nationality: string = '',
  ): Promise<ProcedureResponse> => {
    try {
      this.validateInputs(destinations, nationality)

      const sanitizedDestinations = destinations.trim().toUpperCase()
      const sanitizedNationality = nationality.trim().toUpperCase()
      const response = await this.instance.get<ProcedureResponse>(
        `https://requirements-api.joinsherpa.com/v2/procedures?filter%5Bcategory%5D=DOC_REQUIRED&filter%5Bcountry%5D=${sanitizedDestinations}&filter%5Bnationalities%5D=${sanitizedNationality}&filter%5Benforcement%5D=MANDATORY&key=${process.env.NEXT_PUBLIC_SHERPA_API_PROCED_KEY}&affiliateId=${process.env.NEXT_PUBLIC_SHERPA_API_PROCED_AFFILIATE_KEY}`,
        {
          headers: {
            'x-sherpa-deviceid': process.env.NEXT_PUBLIC_SHERPA_API_DEVICE_KEY,
          },
          timeout: 5000,
        },
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw new Error('Network error: Unable to reach Sherpa API')
    }
  }
}

import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, PopupTopBar } from '@/components/ui'
import { IVisaWizardItem } from '@/models/IVisaWizardItem'
import { Actions, PopupContent } from './styles'
import { BaseWizardPopupProps } from '../types'

interface DocumentInfoPopupProp extends BaseWizardPopupProps {
  title: string
  description?: string
}

export const DocumentInfoPopup = ({
  title,
  description,
  onClose,
}: DocumentInfoPopupProp) => {
  return (
    <>
      <PopupTopBar onClose={onClose}>{title}</PopupTopBar>
      <PopupContent>
        <ReactMarkdown linkTarget="_blank">{`${description}`}</ReactMarkdown>
      </PopupContent>
      <Actions>
        <Button variant="grey" size="large" onClick={onClose}>
          Close
        </Button>
      </Actions>
    </>
  )
}

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react'
import { ThemeContextInterface } from './types'

const ThemeContext = createContext<ThemeContextInterface | undefined>(undefined)

type ThemeProviderProps = {
  children: ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    const localTheme = localStorage.getItem('theme') as 'light' | 'dark'
    if (localTheme === 'light' || localTheme === 'dark') {
      setTheme(localTheme)
    }
  }, [])

  const toggleTheme = useCallback(() => {
    setTheme((theme) => {
      localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light')
      return theme === 'light' ? 'dark' : 'light'
    })
  }, [])

  useEffect(() => {
    document.body.classList.toggle('dark', theme === 'dark')
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      <div className={theme === 'dark' ? 'dark' : ''}>{children}</div>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

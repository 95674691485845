import styled, { css } from 'styled-components'
import { Flex, PopupPadding, textVariants, Text } from '@/components/ui'

export const PopupGrid = styled(Flex)`
  width: 800px;
  height: 700px;
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${PopupPadding};
  overflow-y: auto;

  hr {
    border-top: 1px solid var(--gery-200);
    margin-bottom: 16px;
  }
`

export const GreyColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
  background-color: var(--grey-100);
`

export const WhiteColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['body-s-medium']}
  row-gap: 4px;
  margin-bottom: 20px;
`

export const Calculation = styled(Flex)<{ isCenter?: boolean }>`
  flex-direction: column;
  ${(p) => p.isCenter && 'justify-content: center;'}
  flex-grow: 1;
`

export const Footer = styled.footer`
  ${textVariants['body-s-medium']}
  color: var(--grey-500);
`

export const Pricing = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  border-radius: 16px;
  background-color: var(--panel-1);

  > div {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    color: var(--grey-500);
  }

  > div:last-child {
    border: 1px solid var(--grey-200);
    color: var(--grey-900);
  }
`

export const YourEarning = styled(Flex)`
  padding: 16px;
  border-radius: 16px;
  background: var(--base-gradient);
  color: var(--panel-1);
  justify-content: space-between;
  align-items: center;
`

export const RemainingDue = styled(Flex)`
  padding: 16px;
  border-radius: 16px;
  background: var(--panel-1);
`

export const ProposalPrices = styled.div`
  border-radius: 12px;
  border: 1px solid var(--grey-200);
  background: var(--panel-1);
  overflow: hidden;
`

export const BreakdownTitle = styled(Flex)`
  padding: 16px;
  align-self: stretch;
  background: var(--grey-100);
`

export const BreakdownItem = styled(Flex)<{ noBorder?: boolean }>`
  padding: 16px;
  ${(p) => !p.noBorder && 'border-top: 1px solid var(--grey-200)'}
`

export const TotalsContainer = styled(Flex)<{ hasGradient?: boolean }>`
  border-radius: 16px;
  border: 1px solid var(--grey-200);
  background: var(--panel-1);
  margin-bottom: 32px;
  overflow: hidden;

  div:first-child {
    border-top: none;
  }

  div {
    border-top: 1px solid var(--grey-200);
    padding: 16px;
  }

  ${(p) =>
    p.hasGradient &&
    css`
      div:last-child {
        background: var(--base-gradient);
        color: var(--panel-1);
      }
    `}
`

export const Content = styled.div`
  padding: ${PopupPadding};
  width: 384px;
`

export const Actions = styled(Flex)`
  padding: ${PopupPadding};
  border-top: 0.4px solid var(--grey-300);
`

import React from 'react'
import {
  Actions,
  FormSubGroup,
  PopupContent,
  TotalSkeletonWrapper,
} from './styles'
import { Flex } from '../Flex'
import { Skeleton } from '../Skeleton'

const PaymentFormSkeleton = () => {
  return (
    <>
      <PopupContent>
        <Flex direction="column" justifyContent="center" gap={24}>
          <Flex direction="column" gap={8}>
            <Skeleton width={80} height={15} />
            <Skeleton height={39} />
          </Flex>
          <FormSubGroup>
            <FormSubGroup direction="column">
              <Skeleton width={80} height={15} />
              <Skeleton height={39} />
            </FormSubGroup>
            <FormSubGroup direction="column">
              <Skeleton width={80} height={15} />
              <Skeleton height={39} />
            </FormSubGroup>
          </FormSubGroup>

          <FormSubGroup direction="column">
            <Skeleton width={80} height={15} />
            <Skeleton height={39} />
          </FormSubGroup>
          <FormSubGroup direction="column">
            <Skeleton width={80} height={15} />
            <Skeleton height={39} />
          </FormSubGroup>

          <Flex direction="column" gap={4}>
            <Skeleton height={15} />
            <Skeleton width={200} height={15} />
          </Flex>
          <Flex gap={8}>
            <Skeleton height={39} />
            <Skeleton width={72} height={39} />
          </Flex>

          <TotalSkeletonWrapper>
            <Skeleton height={24} width={90} />
            <Skeleton width={30} height={24} />
          </TotalSkeletonWrapper>
        </Flex>
      </PopupContent>
      <Actions>
        <Skeleton height={40} />
      </Actions>
    </>
  )
}

export default PaymentFormSkeleton

export const checkIsAuthRoute = (path: string) => {
  return path.startsWith('/auth')
}

export const checkIsGatedRoute = (path: string) => {
  return (
    !path.startsWith('/auth') &&
    !path.startsWith(`/quiz`) && // visa quiz can be taken without auth
    !path.startsWith('/_error') &&
    !path.startsWith('/404') &&
    !path.startsWith('/internal/auth')
  )
}

export const logout = (url?: string | null) => {
  localStorage.removeItem('token')
  return (window.location.href = `/app/auth/login${
    url ? `?return=${url}` : ''
  }`)
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { noop } from 'lodash'
import useApi from '@/hooks/useApi'
import { IntentBase } from '@/utils/Api/payments.service'
import { showToast } from '@/utils/toast'
import { PaymentReason } from '@/constants/payments'
import { PopupTopBar } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { useTheme } from '@/contexts/Theme'
import { PaymentPopupProps } from './types'
import PaymentForm from './PaymentForm'
import {
  CouponCode,
  CouponContainer,
  DiscountRow,
  PopupContainer,
  TotalAmountContainer,
} from './styles'

import PaymentFormSkeleton from './PaymentFormSkeleton'
import { stripeCustomAppearance } from './stripeCustomAppearance'
import { Text } from '../Text'
import { Flex } from '../Flex'

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || '')

export function PaymentPopup<T extends IntentBase>({
  returnUrl,
  onSuccess,
  onClose,
  type,
  topBarTitle,
  ...rest
}: PaymentPopupProps & T) {
  const stripe = useStripe()
  const { api, status } = useApi()
  const { theme } = useTheme()
  const [amount, setAmount] = useState<number>()
  const [originalAmount, setOriginalAmount] = useState<number>(0)
  const [coupon, setCoupon] = useState<string>('')
  const [clientSecret, setClientSecret] = useState<string>()
  const [discount, setDiscount] = useState(0)

  const setupIntent = useCallback(
    (id: string) => {
      api.payments.retrieveIntent(id).then((data) => {
        if (data.metadata && data.metadata.discount) {
          const discountAmount = parseFloat(data.metadata.discount)
          const amountCharged = data.amount / 100
          setOriginalAmount(amountCharged + discountAmount)
          setDiscount(discountAmount)
        }
      })
    },
    [api.payments],
  )

  useEffect(() => {
    if (!stripe) return

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!clientSecret) {
      api.payments
        .createPaymentIntent(type, rest)
        .then((data) => {
          if (!data.client_secret) return
          const amountCharged = data.amount / 100
          setAmount(amountCharged)
          setClientSecret(data.client_secret)
          if (
            type === PaymentReason.WizardPayment &&
            data.metadata &&
            data.metadata.discount
          ) {
            const discountAmount = parseFloat(data.metadata.discount)
            setOriginalAmount(amountCharged + discountAmount)
            setDiscount(discountAmount)
          }
        })
        .catch(noop)
    } else {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (type === PaymentReason.WizardPayment && paymentIntent?.id) {
          setupIntent(paymentIntent.id)
        }

        setAmount(paymentIntent!.amount / 100)
        setClientSecret(clientSecret)
      })
    }
  }, [stripe, setupIntent])

  const handleApply = useCallback(() => {
    if (!coupon) {
      return showToast({
        type: 'error',
        message: 'Please enter a coupon code first',
      })
    }
    api.payments
      .createPaymentIntent(type, { ...rest, coupon })
      .then((data) => {
        if (data.client_secret) {
          setAmount(data.amount / 100)
          setClientSecret(data.client_secret)
        } else {
          onSuccess()
        }
      })
      .catch(noop)
  }, [coupon])

  const options: StripeElementsOptions = useMemo(
    () => ({
      clientSecret,
      appearance: stripeCustomAppearance(theme),
    }),
    [clientSecret, theme],
  )

  return (
    <PopupContainer>
      <PopupTopBar onClose={onClose}>
        {topBarTitle ? topBarTitle : 'Checkout'}
      </PopupTopBar>
      {clientSecret && amount ? (
        <Elements key={clientSecret} options={options} stripe={stripePromise}>
          <PaymentForm returnUrl={returnUrl} onSuccess={onSuccess}>
            {type === PaymentReason.PremiumSubscription && (
              <CouponContainer direction="row" alignItems="flex-end">
                <CouponCode
                  label="Coupon"
                  size={2}
                  variant="soft"
                  optional
                  placeholder="Coupon Code"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <Button
                  variant="outline"
                  size={3}
                  colorTheme="neutral"
                  onClick={handleApply}
                  isLoading={status === 'posting'}
                >
                  Apply
                </Button>
              </CouponContainer>
            )}
            <TotalAmountContainer>
              {discount > 0 && (
                <>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text variant="regular-3">Total</Text>
                    <Text variant="regular-3">
                      ${originalAmount.toLocaleString()}
                    </Text>
                  </Flex>
                  <DiscountRow
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text variant="regular-3">Discount</Text>
                    <Text variant="regular-3">
                      ${discount.toLocaleString()}
                    </Text>
                  </DiscountRow>
                </>
              )}
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="semibold-4">
                  {discount > 0 ? 'New Total' : 'Total price'}
                </Text>
                <Text variant="semibold-4">${amount.toLocaleString()}</Text>
              </Flex>
            </TotalAmountContainer>
          </PaymentForm>
        </Elements>
      ) : (
        <PaymentFormSkeleton />
      )}
    </PopupContainer>
  )
}

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import ManageSubscriptionPopup from '@/components/ui/ManageSubscriptionPopup'
import CancelSubscriptionPopup from '@/components/ui/CancelSubscriptionPopup'
import ConfirmCancelSubscriptionPopup from '@/components/ui/CancelSubscriptionPopup/confirm'
import CancelSubscriptionSuccessPopup from '@/components/ui/CancelSubscriptionPopup/success'
import { usePremiumSubscription } from '@/contexts/PremiumSubscription'
import { PremiumUpgradeReason } from '@/contexts/PremiumSubscription/types'

export const ManageSubscriptionFlow = forwardRef<{ trigger: () => void }>(
  (_, ref) => {
    const [step, setStep] = useState(0)
    const [isTriggered, setTriggered] = useState(false)
    const [cancelNote, setCancelNote] = useState('')
    const { openUpgradePremiumFlow } = usePremiumSubscription()

    const handleClose = () => {
      setStep(0)
      setTriggered(false)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => setTriggered(true),
        }
      },
      [],
    )

    if (!isTriggered) return null

    return (
      <>
        <ManageSubscriptionPopup
          isOpen={step === 0}
          onClose={handleClose}
          onCancelSubscription={() => setStep(1)}
          onResumeSubscription={() => {
            handleClose()
            openUpgradePremiumFlow(PremiumUpgradeReason.SETTING)
          }}
        />
        <CancelSubscriptionPopup
          isOpen={step === 1}
          onClose={handleClose}
          onCancelSubscription={() => setStep(2)}
        />
        <ConfirmCancelSubscriptionPopup
          isOpen={step === 2}
          onClose={handleClose}
          afterCancel={(note) => {
            setStep(3)
            setCancelNote(note)
          }}
        />
        <CancelSubscriptionSuccessPopup
          isOpen={step === 3}
          onClose={handleClose}
          note={cancelNote}
        />
      </>
    )
  },
)

ManageSubscriptionFlow.displayName = 'ManageSubscriptionFlow'

import styled, { css } from 'styled-components'

interface Props {
  width?: number | string
  height?: number | string
}

export const Gap = styled.div<Props>`
  ${(p) =>
    p.width &&
    css`
      width: ${p.width}px;
    `}
  ${(p) =>
    p.height &&
    css`
      height: ${p.height}px;
    `}
`

import React, {
  useRef,
  forwardRef,
  InputHTMLAttributes,
  useState,
  useEffect,
} from 'react'
import {
  HelperText,
  HelperTextWrapper,
  InputFieldWrapper,
  InputPrefixHolder,
  InputWrapper,
  Label,
  OptionalLabel,
  StyledIconHolder,
  StyledInputField,
} from './styles'
import InputPrefix from './InputPrefix'
import IconRenderer from '../helper/IconRenderer'
import {
  FlagIcon,
  Icon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '../../ui/Icon'

export type InputSize = 1 | 2 | 3 | 4

export const iconSize: Record<InputSize, number> = {
  1: 20,
  2: 24,
  3: 24,
  4: 24,
}

export const inputHorizontalIconOffset: Record<InputSize, number> = {
  1: 8,
  2: 10,
  3: 12,
  4: 16,
}

const prefixWidthEstimate: Record<InputSize, number> = {
  1: 81,
  2: 91,
  3: 113,
  4: 125,
}
interface IconProps {
  type: IconType
  name?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
  onClick?: () => void
}

export type InputVariance = {
  variant?: 'outline' | 'soft'
  size?: InputSize
  hasError?: boolean
  className?: string
}

type InputPrefixProps = {
  icon?: IconProps
  text?: string
  onClick?: () => void
}

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'outline' | 'soft'
  size?: InputSize
  hasError?: boolean
  className?: string
  label?: string
  helperText?: string
  optional?: boolean
  startIcon?: IconProps
  endIcon?: IconProps
  startInputPrefix?: InputPrefixProps
  endInputPrefix?: InputPrefixProps
}

export type IconHolderProps = {
  focused: boolean
  orientation: 'start' | 'end'
  size: InputSize
}

const InputField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputFieldProps
> = (props, ref) => {
  const {
    label,
    className,
    hasError,
    helperText,
    id,
    optional,
    size,
    startIcon,
    endIcon,
    startInputPrefix,
    endInputPrefix,
    onFocus,
    onBlur,
    ...rest
  } = props

  const [focused, setFocused] = useState(false)

  const startInputPrefixRef = useRef<HTMLDivElement>(null)
  const endInputPrefixRef = useRef<HTMLDivElement>(null)

  //FIX ME: Adjust estimation for various input prefix for instant input rendering. Currently assumes icon + text for flag
  const [startInputPrefixWidth, setStartInputPrefixWidth] = useState(
    prefixWidthEstimate[size || 2],
  )
  const [endInputPrefixWidth, setEndInputPrefixWidth] = useState(
    prefixWidthEstimate[size || 2],
  )

  useEffect(() => {
    if (startInputPrefixRef.current) {
      setStartInputPrefixWidth(startInputPrefixRef.current.offsetWidth)
    }
    if (endInputPrefixRef.current) {
      setEndInputPrefixWidth(endInputPrefixRef.current.offsetWidth)
    }
  }, [])

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true)
    onFocus && onFocus(event)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false)
    onBlur && onBlur(event)
  }

  return (
    <InputFieldWrapper>
      {label && (
        <Label htmlFor={id}>
          {label}
          {optional && <OptionalLabel>{`(Optional)`}</OptionalLabel>}
        </Label>
      )}
      <InputWrapper>
        {startInputPrefix && (
          <InputPrefixHolder orientation="left" ref={startInputPrefixRef}>
            <InputPrefix
              size={size || 2}
              orientation="left"
              icon={startInputPrefix.icon}
              text={startInputPrefix.text}
              onClick={startInputPrefix.onClick}
            />
          </InputPrefixHolder>
        )}
        {startIcon && (
          <StyledIconHolder
            focused={focused}
            orientation="start"
            size={size || 2}
            onClick={startIcon.onClick}
            clickable={!!startIcon.onClick}
          >
            <IconRenderer
              size={size || 2}
              sizeMap={iconSize}
              iconName={startIcon.name}
              iconType={startIcon.type}
            />
          </StyledIconHolder>
        )}
        <StyledInputField
          ref={ref}
          size={size}
          hasError={hasError}
          id={id}
          onFocus={handleFocus}
          onBlur={handleBlur}
          startIcon={startIcon}
          endIcon={endIcon}
          style={{
            paddingLeft: startInputPrefix
              ? startInputPrefixWidth + inputHorizontalIconOffset[size || 2]
              : undefined,
            paddingRight: endInputPrefix
              ? endInputPrefixWidth + inputHorizontalIconOffset[size || 2]
              : undefined,
          }}
          {...rest}
        />
        {endIcon && (
          <StyledIconHolder
            clickable={!!endIcon.onClick}
            focused={focused}
            orientation="end"
            size={size || 2}
            onClick={endIcon.onClick}
          >
            <IconRenderer
              size={size || 2}
              sizeMap={iconSize}
              iconName={endIcon.name}
              iconType={endIcon.type}
            />
          </StyledIconHolder>
        )}
        {endInputPrefix && (
          <InputPrefixHolder orientation="right" ref={endInputPrefixRef}>
            <InputPrefix
              size={size || 2}
              orientation="right"
              icon={endInputPrefix.icon}
              text={endInputPrefix.text}
              onClick={endInputPrefix.onClick}
            />
          </InputPrefixHolder>
        )}
      </InputWrapper>
      {helperText && (
        <HelperTextWrapper hasError={!!hasError}>
          <span>
            {hasError && <Icon.Solid name="circlExclamation" size={16} />}
          </span>

          <HelperText>{helperText}</HelperText>
        </HelperTextWrapper>
      )}
    </InputFieldWrapper>
  )
}

export default forwardRef(InputField)

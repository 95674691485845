import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Popup } from '@/components/ui'
import { IUser } from '@/models/IUser'
import { CongratulationsPopup, HireAdvisorPopup } from '../Popups'

export const HireAdvisorFlow = forwardRef<
  { trigger: () => void },
  { advisor: IUser }
>(({ advisor }, ref) => {
  const [isTriggered, setTriggered] = useState(false)
  const [step, setStep] = useState(0)

  const handleClose = () => {
    setTriggered(false)
    setStep(0)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  if (!isTriggered) return null

  return (
    <>
      <Popup isOpen={step === 0}>
        <HireAdvisorPopup
          advisor={advisor}
          onClose={handleClose}
          onConfirm={() => setStep(1)}
        >
          <ol>
            <li>Your advisor will receive your request for proposal.</li>
            <li>
              Your advisor will send over their quote and detailed list of
              services they will provide.
            </li>
            <li>You can then accept or reject the proposal.</li>
          </ol>
        </HireAdvisorPopup>
      </Popup>
      <Popup isOpen={step === 1}>
        <CongratulationsPopup
          hideReceipt
          title={`Woo hoo! Your request has been sent. Your advisor will now review your case and send over a proposal.`}
          onClose={handleClose}
        />
      </Popup>
    </>
  )
})

HireAdvisorFlow.displayName = 'HireAdvisorFlow'

import React, { useState, useEffect, useCallback } from 'react'
import { DateTime } from 'luxon'
import Link from 'next/link'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import useApi from '@/hooks/useApi'
import { PopupTopBar, Button, Flex, Text, Checkbox } from '@/components/ui'
import { showToast } from '@/utils/toast'
import { IViewProposalProps } from './types'
import {
  PopupActions,
  PopupContent,
  SummaryBox,
  ProposalPrices,
  BreakdownTitle,
  BreakdownItem,
  TermsBox,
  TotalsContainer,
  Notice,
  WhiteColumn,
  GreyColumn,
  ProposalDates,
  ProposalDatesContainer,
} from './styles'

export const ViewProposal: React.FC<IViewProposalProps> = ({
  onClose,
  onUpdate,
  hasActions = true,
  platformFee = 30,
  proposal,
  onPayment,
  mode = 'user',
  acceptedDate = '',
}) => {
  const { api, status } = useApi()

  const [advisorProposal, setAdvisorProposal] = useState<
    IProposal | null | undefined
  >(proposal)
  const [acceptedTerms, toggleAcceptedTerms] = useState(false)
  const [proposalTotal, setProposalTotal] = useState<string>()
  const [dueToday, setDueToday] = useState<string>('0')
  const [techFee, setTechFee] = useState<string>('0')
  const [earning, setEarning] = useState<string>('0')

  useEffect(() => {
    const prices = advisorProposal?.prices
    const total =
      prices?.map((item) => item.price).reduce((a, b) => a + b, 0) || 0

    const techFee = (total * platformFee) / 100
    const earning = total - techFee

    setProposalTotal(Math.ceil(total).toLocaleString())
    setTechFee(Math.ceil(techFee).toLocaleString())
    setEarning(Math.ceil(earning).toLocaleString())
    setDueToday(advisorProposal?.initialPayment?.toLocaleString() || '0')
  }, [advisorProposal?.prices, advisorProposal?.initialPayment, platformFee])

  const handleUpdate = useCallback(
    (status: ProposalStatus.Accepted | ProposalStatus.Rejected) => {
      advisorProposal &&
        api.wizard
          .updateProposal(advisorProposal?.id, {
            status,
            initialPayment: advisorProposal?.initialPayment || null,
          })
          .then((response) => {
            onUpdate(response.proposal)

            showToast({
              type: 'success',
              message: `Proposal ${
                status === ProposalStatus.Accepted ? 'accepted' : 'rejected'
              }`,
            })

            if (status === ProposalStatus.Accepted && response.initialPayment) {
              onPayment(response.initialPayment)
            } else {
              onClose()
            }
          })
    },
    [advisorProposal, api.wizard, onUpdate, onClose, onPayment],
  )

  const formatDates = (date: string) => {
    return DateTime.fromISO(date).toFormat('dd MMM')
  }

  return (
    <>
      <Flex>
        <WhiteColumn>
          <PopupTopBar onClose={mode === 'user' ? onClose : undefined}>
            View Proposal
          </PopupTopBar>
          <PopupContent isNew={false} isViewing={!hasActions}>
            <Flex direction="column" gap={8}>
              <SummaryBox direction="column" gap={8}>
                <Text variant="caption-s-medium">Advisor summary message</Text>
                <Text variant="body-m-regular">{advisorProposal?.summary}</Text>
              </SummaryBox>
              <ProposalPrices>
                <BreakdownTitle justifyContent="space-between">
                  <Text variant="caption-s-medium">Service</Text>
                  <Text variant="caption-s-medium">Price</Text>
                </BreakdownTitle>
                {advisorProposal?.prices?.map((item, index) => (
                  <BreakdownItem key={index} justifyContent="space-between">
                    <Text variant="body-m-regular">{item.title}</Text>
                    <Text variant="body-m-regular">
                      ${item.price.toLocaleString()}
                    </Text>
                  </BreakdownItem>
                ))}
              </ProposalPrices>
              <BreakdownItem justifyContent="space-between" noBorder>
                <Text variant="body-m-semibold">Proposed Total</Text>
                <Text variant="body-m-semibold">${proposalTotal}</Text>
              </BreakdownItem>
              <hr />
              <BreakdownItem justifyContent="space-between" noBorder>
                <Text variant="body-m-semibold">
                  {hasActions ? 'Due Today' : 'Initial Payment Request'}
                </Text>
                <Text variant="body-m-semibold">${dueToday}</Text>
              </BreakdownItem>
            </Flex>
          </PopupContent>
          {hasActions && (
            <TermsBox justifyContent="start" gap={13}>
              <Checkbox
                checked={acceptedTerms}
                onChange={(e) => toggleAcceptedTerms(e.target.checked)}
              >
                <Text variant="body-m-regular">
                  By accepting this proposal you agree with <br /> our{' '}
                  <Link
                    href="https://citizenremote.com/terms-of-use/"
                    target="_blank"
                  >
                    Terms of Services
                  </Link>{' '}
                  and{' '}
                  <Link
                    href="https://citizenremote.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </Text>
              </Checkbox>
            </TermsBox>
          )}
          {hasActions ? (
            <PopupActions justifyContent="center" gap={16}>
              <Button
                variant="grey"
                size="medium"
                onClick={() => handleUpdate(ProposalStatus.Rejected)}
                isLoading={status === 'posting'}
              >
                Reject Proposal
              </Button>
              <Button
                variant="black"
                size="medium"
                disabled={!acceptedTerms}
                onClick={() => handleUpdate(ProposalStatus.Accepted)}
                isLoading={status === 'posting'}
              >
                {!!advisorProposal?.initialPayment
                  ? 'Accept and Pay'
                  : 'Accept Proposal'}
              </Button>
            </PopupActions>
          ) : (
            <ProposalDatesContainer>
              <ProposalDates>
                <Flex justifyContent="space-between">
                  <Text variant="body-m-regular">Proposal sent date</Text>
                  <Text variant="body-m-regular">
                    {formatDates(advisorProposal?.createdAt!)}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text variant="body-m-regular">Proposal accepted date</Text>
                  <Text variant="body-m-regular">
                    {formatDates(acceptedDate!)}
                  </Text>
                </Flex>
              </ProposalDates>
            </ProposalDatesContainer>
          )}
        </WhiteColumn>
        {!hasActions && mode === 'advisor' && (
          <GreyColumn>
            <PopupTopBar onClose={onClose}>Payment breakdown</PopupTopBar>
            <PopupContent isNew={false} isViewing={!hasActions}>
              <Text variant="body-s-semibold">Proposal Total</Text>
              <TotalsContainer direction="column" isViewing={!hasActions}>
                <Flex justifyContent="space-between">
                  <Text variant="body-m-regular">Proposal total</Text>
                  <Text variant="body-m-regular">${proposalTotal}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text variant="body-m-regular">
                    Total Technology Fee ({platformFee}%)
                  </Text>
                  <Text variant="body-m-regular">${techFee}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text variant="body-m-semibold">Your Total Earning</Text>
                  <Text variant="body-m-semibold">${earning}</Text>
                </Flex>
              </TotalsContainer>
            </PopupContent>
            <Notice>
              We&rsquo;re applying {platformFee}% Technology Fee to all payments
              <br />
              in order to maintain work of Citizen Remote&rsquo;s platform.
            </Notice>
          </GreyColumn>
        )}
      </Flex>
    </>
  )
}

import React, { useCallback, useState } from 'react'
import { Button, PopupTopBar, Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { showToast } from '@/utils/toast'
import { ProposalStatus } from '@/models/IProposal'
import { Actions, Content } from './styles'

interface WithdrawProposalProps {
  onCancel: () => void
  proposalId?: string
  onUpdate: () => void
}

export const WithdrawProposalPopup: React.FC<WithdrawProposalProps> = ({
  onCancel,
  proposalId,
  onUpdate,
}) => {
  const { api, status } = useApi()

  const handleWithdraw = useCallback(() => {
    proposalId &&
      api.wizard
        .updateProposal(proposalId, { status: ProposalStatus.Withdrawn })
        .then(() => {
          onUpdate()

          showToast({
            type: 'success',
            message: 'Proposal withdrawn',
          })

          onCancel()
        })
  }, [proposalId, api.wizard, onUpdate, onCancel])
  return (
    <>
      <PopupTopBar onClose={onCancel}>Withdrawing Proposal?</PopupTopBar>
      <Content>
        <Text variant="body-m-semibold">
          Are you sure you want to withdraw this proposal?
        </Text>
      </Content>
      <Actions justifyContent="center" gap={16}>
        <Button variant="grey" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          isLoading={status === 'posting'}
          variant="black"
          size="large"
          onClick={handleWithdraw}
        >
          Confirm
        </Button>
      </Actions>
    </>
  )
}

import styled from 'styled-components'
import {
  Flex,
  HoverLabel,
  PopupPadding,
  TextField,
  textVariants,
} from '@/components/ui'

export const PopupContent = styled.div`
  padding: ${PopupPadding};
  width: 384px;
  height: 474px;
  overflow: auto;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['body-s-semibold']}
  margin-top: 20px;
  gap: 10px;
`

export const FirstNameField = styled(TextField)`
  margin-top: 20px;
  margin-bottom: 12px;
`

export const EmailField = styled(TextField)`
  margin-top: 24px;
`

export const FieldGrid = styled(Flex)`
  gap: 12px;
`

export const Options = styled.div`
  & > :not(:last-child) {
    border-bottom: 0.4px solid var(--grey-300);
  }
`

export const Kind = styled(HoverLabel)`
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`

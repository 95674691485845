import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Checkbox, PopupTopBar, Text } from '@/components/ui'
import { Actions, Content, PopupContent } from './styles'
import { BaseWizardPopupProps } from '../types'

interface TermsOServicePopupProp extends BaseWizardPopupProps {
  onContinue: () => void
}

export const TermsOServicePopup = ({
  onContinue,
  onClose,
}: TermsOServicePopupProp) => {
  const [agreed, setAgreed] = useState(true)

  return (
    <>
      <PopupTopBar onClose={onClose}>Terms & Services</PopupTopBar>
      <PopupContent>
        <Content>
          <p>Last Revised Date: Oct 29, 2023</p>
          <b>
            Citizen Remote is a technology platform designed to facilitate a
            seamless visa application process through efficient communication
            and documentation management. While our platform is distinguished by
            its commitment to excellence, the specific services provided are
            solely determined and handled by local legal professionals, inside
            our platform.
          </b>
          <ReactMarkdown>
            {`
These terms and conditions outline the rules and regulations for the use of the Citizen Remote resources, including our principal website at [www.citizenremote.com](http://www.citizenremote.com/), as well as all related subdomains, web and mobile applications (herein referred to as "Citizen Remote" and/or "Company").

By accessing Citizen Remote we assume you accept these terms and conditions. Do not continue to use Citizen Remote platforms and resources if you do not agree to take all of the terms and conditions stated on this page.

1. **Scope of Terms & Conditions**

These terms and conditions are between you and Citizen Remote LLC ("Citizen Remote"), and governs your use of the services provided by Citizen Remote (the "Services"). By using the Services, you agree to be legally bound by these terms and conditions. The Services are intended for use by persons 18 years of age or older. If you are under 18 years of age, you may not use these Services.

2. **Description of Services - No Affiliation with Government Agencies**

Citizen Remote provides a variety of informational, marketing and technology-based support-services for remote workers and digital nomads. These services range from providing assistance for navigating visa applications to finding short and long-term housing. Citizen Remote is an informational and technology-based platform, as well as a social community, and is not formally affiliated with any government agency. Use of the Services is not required to complete and file such forms and is merely a facilitator.

3. **Citizen Remote is Not a Law Firm and Does Not Provide Legal Advice**

You understand that while Citizen Remote might engage legal professionals, we are not a law firm. By choosing to work with Citizen Remote, this does not automatically create an attorney-client relationship.

Citizen Remote is a social and technology-based platform. While we create processes designed to streamline visa processes we are not a legal provider.

4. **Release of Confidential Information.**

As needed, you have directed us to engage others as necessary to assist with preparing and filing a visa application as we see fit, and also directed us to release your confidential information to them. This includes, but is not limited to: visa experts, immigration lawyers and formal government authorities where the actual visa filing occurs, as well as any communications with government officials. You hereby waive any claim that may arise against us as a result of our disclosure of your confidential information in this context.

5. **Citizen Remote Visa Services**

Citizen Remote is a technology-based platform that offers individuals with streamlined solutions when applying for digital nomad and remote work visas. While we do not provide legal services, part of this feature includes access to visa experts, including immigration lawyers. Services include, but are not limited to:

**5.1. Visa eligibility assessments**. Before commencing with the submission of an application Citizen Remote's legal professionals will evaluate the viability of your filing. They do this to assist in the success rate of each application.

**5.2. Access to comprehensive checklists.** By engaging Citizen Remote you will have access to a database of information that is required for the visa filing. This includes a list of documentation and the accompanying requirements for each item (i.e. certification/apostille/translation requirements), as well as where to obtain official documents as needed. This information can alternatively be provided by legal professionals in the platform.

**5.2.1. Supporting Documentation.** Many visa applications require tailored documentation to be submitted along with the main application requirements. This might include personalized statements of intent as well as a general cover letter (i.e. petition letter). Citizen Remote's legal professionals will provide this documentation for your application.

**5.3. Customer Service Portal.** Each client is provided with a communication portal where your application will be prepared. This includes direct communications with support-staff and legal professionals for preparing a visa application and turn-key solutions for submitting documentation and receiving the visa, if approved.

**5.4. Visa Submission.** Citizen Remote's legal professionals will manage the actual submission of the visa application to the relevant government authorities.

**5.5. Government Communications.** Citizen Remote's legal professionals will manage any required communications with the government officials related to the filing.

**5.5. Appointment Scheduling.** If required for obtaining the visa, Citizen Remote's legal professionals may support you with scheduling an actual appointment with the requisite government authority (i.e. Consular Processing).

6. **Fees Payable**

**6.1. Service Fees.** Citizen Remote service fees have been outlined in the payment portal attached to these terms and conditions. This may include a flat service fee as well as additional fees related to this service as outlined below.

**6.2. Government Fees.**

Government agencies charge fees for the filing and processing of various government forms ("Government Fees"). Government Fees are separate from our Service Fees and must be paid to the applicable government agency at the time of filing. Unless we indicate otherwise, you remain responsible for paying Government Fees directly to the applicable government agency and are non-refundable.

**6.3. Payment of Service Fees.** When you purchase services for which Service Fees apply, you will be required to provide Citizen Remote with valid, up-to-date credit card or other payment information.

**6.3.1.** You authorize Citizen Remote to charge your credit card or other payment method for all Service Fees you incur as they become due and payable. You are responsible for maintaining up-to-date payment information. If we cannot charge you for Service Fees when due because your payment information is incorrect or no longer valid, or if we do not receive your payment when due, Citizen Remote will have no obligation to provide the services associated with those Service Fees.

**6.3.2. Payment in Full Before Submission.** You also understand that the submission of an application to the requisite authorities is contingent on all payments having been made in-full.

**6.4. Refunds/Credits**. By using Citizen Remote technology to support your visa efforts, you acknowledge that we do not have a refund policy that guarantees success in any visa application. As outlined in Paragraph 7, "Guarantee Policy", government procedures and visa requirements often change. In a situation where your visa is rejected, we would consider offering a free lifetime premium membership – this offer cannot be redeemed in any other manner than the subscription itself. This does not apply in situations where you provide Citizen Remote with misleading or false information as outlined in Paragraph 9.

**6.5. Pro Rata Fees.** If for any reason you are unable to continue with the selected Service, Citizen Remote has the right to receive compensation for all work performed. This includes, and is not limited to service-hours that have been proportionally fulfilled as part of the Services offer.

**6.6**. **Request for Additional Information or Appeals**. Oftentimes a government may request additional information and/or require an appeal to an existing application. Most of the time additional fees will not be required, however, in complex cases or where the fault of the request/denial is due to you, then Citizen Remote reserves the right to charge you for the additional work needed. We would discuss this with you before engaging in any further Services.

7. **Guarantee Policy**

Both parties understand that there are no guarantees in immigration matters, as governments have the full-discretionary power to approve or deny an application – this can also be true despite having provided all prima facie information that is required.

In the event of a denial or rejection, Citizen Remote may choose to provide you with a lifetime membership for our application. We will make a determination of this offer within 10 business days of a government decision.

Submitting false, inaccurate, misleading or deceptive information, or failing to disclose information requested by Citizen Remote or required to be included or disclosed on a visa form or other government form may result in your application being denied by the applicable government agency and Citizen Remote will not have any liability as a result of such actions.

YOU UNDERSTAND THAT OUR ADVICE IS PREMISED ON YOUR REPRESENTATIONS AND THAT YOUR FAILURE TO DISCLOSE INFORMATION COULD RESULT IN AN UNFAVORABLE ADJUDICATION BY THE RESPECTIVE GOVERNMENT AUTHORITY.

8. **Limitation of Liability**

Under no circumstances shall Citizen Remote, its affiliates, and respective employees or agents be liable to you for any loss or damages arising out of our these services. This extends to claims where Citizen Remote should have known the possibility of such losses or damages, and in no circumstances will Citizen Remote be liable for any damages in the excess of fees paid by you in connection with these services herein.

Shall you wish to raise any action in relation to your visa services support as a result of working with an advisor, this must be brought-up as a disciplinary action within the respective jurisdiction of that advisor.

9. **Indemnification**

You agree to defend, indemnify and hold harmless Citizen Remote, its affiliates, and their respective directors, officers and employees from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to your breach of this Agreement, your improper use of the Services, or your breach of any applicable law or infringement of the rights of a third party. Citizen Remote shall have the right to participate in the defense of any such claim, at its own cost. You may not settle or negotiate any claim that results in liability to, or imposes any obligation upon, Citizen Remote, financial or otherwise, without the written consent of Citizen Remote.

19. **Termination**

These terms and conditions shall remain in force and effective unless and until terminated as provided in this Section 10. You may terminate this Agreement at any time by providing written notice to Citizen Remote, via email to hello@citizenremote.com. Except where you terminate this Agreement due to an uncured material breach of this Agreement by Citizen Remote, Citizen Remote will have no obligation to refund you any Service Fees previously paid to Citizen Remote for the Services. Citizen Remote may terminate this Agreement immediately with or without notice, and/or may deny you access to the Services, in Citizen Remote's sole discretion, in the event you breach, or threaten to breach, any term of this Agreement. If you have already paid for Services and have been found to be in violation of this Agreement, your account will be deleted without notice and no refund of Service Fees will be made. Unless otherwise agreed to by Citizen Remote in writing, this Agreement shall terminate automatically on that date which is 12 months following the date on which you initially purchased Services and no Service Fees will be refunded.

Upon any termination of this Agreement any visa services will be considered fulfilled and there will be no outstanding obligations to provide additional services.

The respective representations, warranties, agreements, covenants, indemnities and other statements of each of the parties set forth in this Agreement or made by or on behalf of them pursuant to this Agreement shall remain in full force and effect following termination of this Agreement.

As indicated in Section 6.5 - Pro Rata Fees - any work and services performed up to the date of termination will be considered as part of the overall fees owed to Citizen Remote.

11. **Dispute Resolution by Binding Arbitration**

These terms and conditions require the use of arbitration on an individual basis to resolve disputes between you and Citizen Remote. Both parties waive the right to a jury trial or class action. You may download or copy a form to initiate arbitration from the AAA website at [https://www.adr.org](https://www.adr.org/).

**12. Miscellaneous Terms**

**12.1. Consent to Electronic Communications.** You consent to receive communications from Citizen Remote electronically, and you agree that all agreements, notices, disclosures and other communications that Citizen Remote provides to you electronically, via email, or within the web application, satisfy any legal requirement that such communications or agreements be in writing.

**12.2. Assignment.** You may not assign your rights under this Agreement without the prior written permission of Citizen Remote and any attempt by you to do so shall be null and void.

**12.3. Waiver.** The waiver of a breach of any provision of this Agreement will not operate or be interpreted as a waiver of any other or subsequent breach.

**12.4. Severability.** If any provision of this Agreement is held to be invalid or unenforceable for any reason, the remaining provisions will continue in full force without being impaired or invalidated in any way.

**12.5. Governing Law.** This Agreement will be construed in accordance with and governed exclusively by the laws of the State of Delaware applicable to agreements made among Delaware residents and to be performed wholly within such jurisdiction, regardless of the parties' actual domiciles.

**12.6. Entire Agreement.** This Agreement, including all agreements referred to and incorporated herein, sets forth the entire understanding and agreement between you and Citizen Remote, and supersedes any and all other oral or written agreements or understandings between the parties.`}
          </ReactMarkdown>
        </Content>
        <Checkbox
          checked={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
        >
          <Text variant="body-s-regular">I agree to the Terms & Services</Text>
        </Checkbox>
      </PopupContent>
      <Actions>
        <Button
          variant="black"
          size="large"
          onClick={onContinue}
          disabled={!agreed}
        >
          Continue
        </Button>
      </Actions>
    </>
  )
}

import styled from 'styled-components'
import {
  FullScreenMobileContainer,
  DefaultPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'

import { Flex } from '../Flex'

export const PopupContainer = styled(FullScreenMobileContainer)``

export const PopupContent = styled(DefaultPopupContentWrapper)`
  position: relative;
  height: 100%;
  overflow: auto;
  gap: 20px;
`
export const Hero = styled(Flex)`
  gap: 16px;
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(135deg, #ff5fe5 0%, #ff8a02 100%);
  i {
    color: var(--base-white);
  }
`
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed var(--neutral-4);
`
export const PlanDescription = styled(Flex)`
  gap: 12px;
  max-width: 380px;
  i {
    flex-shrink: 0;
    color: var(--neutral-11);
  }
`

export const PackageDescription = styled(Flex)`
  gap: 20px;
  flex-direction: column;
`

import styled from 'styled-components'
import {
  DefaultPopupContentWrapper,
  FullScreenMobileContainer,
} from '@/components/uiJar/utilityStyles'
import InputField from '@/components/uiJar/InputField'
import { Badge } from '@/components/uiJar/badge'
import { Media } from '@/utils/media'
import { PopupActions } from '../Popup'
import { Flex } from '../Flex'

export const PopupContainer = styled(FullScreenMobileContainer)``

export const PopupContent = styled(DefaultPopupContentWrapper)``

export const FormSubGroup = styled(Flex)`
  width: 100%;
  gap: 8px;
`

export const Actions = styled(PopupActions)`
  padding: 16px 20px;
  border-top: 1px solid var(--neutral-alpha-4);
  button {
    width: 100%;
  }
`

export const DiscountRow = styled(Flex)`
  margin: 8px 0;
`

export const TotalAmountContainer = styled.div`
  border-top: 1px dashed var(--neutral-alpha-4);
  margin-top: 24px;
  padding-top: 48px;
`

export const CouponContainer = styled(Flex)`
  margin-top: 12px;
  gap: 16px;
`

export const CouponCode = styled(InputField)`
  flex: 1;
`

export const ErrorBox = styled(Badge)`
  width: 100%;
  margin-bottom: 16px;
`

export const TotalSkeletonWrapper = styled(Flex)`
  border-top: 1px solid var(--neutral-alpha-4);
  padding-top: 48px;
  justify-content: space-between;
`

import { css } from 'styled-components'

const createMedia =
  (query: string) =>
  (...params: Parameters<typeof css>) =>
    css`
      @media ${query} {
        ${css(...params)}
      }
    `

export const Media = {
  Tablet: createMedia('(min-width: 768px)'),
  Laptop: createMedia('(min-width: 1024px)'),
  Desktop: createMedia('(min-width: 1216px)'),
}

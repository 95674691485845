import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { noop } from 'lodash'
import { IVisaWizardItem } from '@/models/IVisaWizardItem'
import { Button, Icon, PopupTopBar, PopupActions, Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { IFile, IFileRef } from '@/models/IFile'
import {
  DocumentPreview,
  FileContainer,
  FilePreviewCTA,
  PopupContent,
  PopupGrid,
  UploadSection,
  FileList,
  FileItem,
  FileName,
  MarkdownContent,
  FileField,
  LabelField,
} from './styles'
import { BaseWizardPopupProps } from '../types'

interface FileUploadPopupProp extends BaseWizardPopupProps {
  item?: IVisaWizardItem
  isViewer?: boolean
  isLabel?: boolean
  uploads?: IFile[]
  onSubmit: (files: IFileRef[], label?: string) => void
}

export const FileUploadPopup = ({
  item,
  uploads,
  isLabel = false,
  isViewer = false,
  onClose,
  onSubmit,
}: FileUploadPopupProp) => {
  const { api, status } = useApi()

  const [answerLabel, setAnswerLabel] = useState('')
  const [uploaded, setUploaded] = useState<IFile[]>(uploads || [])
  const [selected, setSelected] = useState<File[]>([])

  const handleSubmit = async () => {
    if (uploaded?.length === 0 && selected.length === 0) return
    const previous = uploaded?.map((u) => ({
      id: u.id,
      name: u.name,
      mime: u.mime,
    }))
    if (uploaded.length > 0 && selected.length === 0) {
      return onSubmit(previous)
    }
    const formData = new FormData()
    selected.forEach((file) => formData.append('files[]', file))
    const files = await api.wizard.uploadFiles(formData).catch(noop)
    if (files) {
      onSubmit([...previous, ...files], answerLabel)
    }
  }

  const handleRemove = (index: number) => {
    const newUploaded = [...uploaded]
    newUploaded.splice(index, 1)
    setUploaded(newUploaded)
  }

  return (
    <PopupGrid>
      {item && (
        <DocumentPreview isViewer={isViewer}>
          <PopupTopBar onClose={isViewer ? onClose : undefined}>
            {item?.title}
          </PopupTopBar>
          <PopupContent>
            <MarkdownContent>
              <ReactMarkdown linkTarget="_blank">{`${item?.description}`}</ReactMarkdown>
            </MarkdownContent>
            {item?.exampleFile && (
              <FileContainer url={item?.exampleFile.path}>
                <FilePreviewCTA
                  as="a"
                  href={item?.exampleFile.path}
                  target="_blank"
                >
                  <Text variant="body-m-semibold">View Example Document</Text>
                  <Icon.Solid name="chevronRight" size={20} />
                </FilePreviewCTA>
              </FileContainer>
            )}
            {item.exampleTemplate && (
              <a
                href={item.exampleTemplate.path}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="primary-ghost">
                  Download Customizable Template
                </Button>
              </a>
            )}
          </PopupContent>
        </DocumentPreview>
      )}
      {!isViewer && (
        <UploadSection>
          <PopupTopBar onClose={onClose}>File upload</PopupTopBar>
          <PopupContent>
            {isLabel && (
              <LabelField
                placeholder="Answer Label"
                value={answerLabel}
                onChange={(e) => setAnswerLabel(e.target.value)}
              />
            )}
            <FileField onSelect={setSelected} multiple />
            {selected.length > 0 && (
              <>
                <Text variant="body-s-semibold">Selected files</Text>
                <FileList>
                  {selected.map((file, index) => (
                    <FileItem key={index}>
                      <Icon.Solid name="documentText" size={20} />
                      <FileName variant="body-m-regular">{file.name}</FileName>
                    </FileItem>
                  ))}
                </FileList>
              </>
            )}
            {uploaded?.length > 0 && (
              <>
                <Text variant="body-s-semibold">Uploaded files</Text>
                <FileList>
                  {uploaded.map((file, index) => (
                    <FileItem key={index}>
                      <Icon.Solid name="documentText" size={20} />
                      <FileName variant="body-m-regular">
                        <a href={file.path} target="_blank" rel="noreferrer">
                          {file.name}
                        </a>
                      </FileName>
                      <Button
                        variant="grey-ghost"
                        icon={<Icon.Solid name="trash" size={16} />}
                        onClick={() => handleRemove(index)}
                      />
                    </FileItem>
                  ))}
                </FileList>
              </>
            )}
          </PopupContent>
          <PopupActions>
            <Button variant="grey" size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="black"
              size="large"
              onClick={handleSubmit}
              disabled={
                (selected.length === 0 && uploaded.length === 0) ||
                status === 'posting'
              }
              isLoading={status === 'posting'}
            >
              Submit
            </Button>
          </PopupActions>
        </UploadSection>
      )}
    </PopupGrid>
  )
}

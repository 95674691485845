import React, { useCallback, useState } from 'react'
import { Avatar, Button, Flex, Icon, Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { useWizardContext } from '@/contexts/Wizard'
import { IUser } from '@/models/IUser'
import { Actions, PopupContent, PopupNotice, TopBar } from './styles'
import { BaseWizardPopupProps } from '../types'

interface HireAdvisorPopupProps extends BaseWizardPopupProps {
  onConfirm: () => void
  children: React.ReactNode
  advisor: IUser
}

export const HireAdvisorPopup = ({
  onConfirm,
  onClose,
  children,
  advisor,
}: HireAdvisorPopupProps) => {
  const { api, status } = useApi()
  const { userWizard, updateUserWizard } = useWizardContext()

  const handleHireLawyer = useCallback(() => {
    api.wizard.hireAdvisor(userWizard.id).then((data) => {
      updateUserWizard(data)
      onConfirm()
    })
  }, [api, userWizard.id, updateUserWizard, onConfirm])

  return (
    <>
      <PopupContent>
        <TopBar justifyContent="space-between">
          <Avatar size={64} url={advisor.avatarFull} />
          <Button
            type="button"
            variant="grey"
            icon={<Icon.Solid name="xMark" />}
            onClick={onClose}
          />
        </TopBar>
        <Flex alignItems="start" direction="column" gap={8}>
          <Text variant="heading-h5">Ready to take the next step?</Text>
          <Text variant="body-m-regular">
            If you&rsquo;ve had a consultation with your advisor already and are
            ready to take the next step, you can request to hire your advisor.
          </Text>
        </Flex>
        {children}
      </PopupContent>
      <PopupNotice variant="body-s-regular">
        You won&apos;t be charged anything now.
      </PopupNotice>
      <Actions>
        <Button
          size="large"
          onClick={handleHireLawyer}
          isLoading={status === 'posting'}
        >
          Request Proposal
        </Button>
      </Actions>
    </>
  )
}

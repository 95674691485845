import styled from 'styled-components'
import {
  FullScreenMobileContainer,
  DefaultPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'

import { Media } from '@/utils/media'
import { textVariants } from '../../Text'
import { Flex } from '../../Flex'

export const PopupContainer = styled(FullScreenMobileContainer)``

export const PopupContent = styled(DefaultPopupContentWrapper)`
  position: relative;
  height: 100%;
  overflow: auto;
  gap: 20px;
  padding-bottom: 32px;
`

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed var(--neutral-4);
`
export const SubscriptionNote = styled(Flex)`
  gap: 12px;

  i {
    flex-shrink: 0;
    color: var(--neutral-11);
  }
`

export const FooterActions = styled(Flex)`
  flex-direction: column;
  padding: 16px 20px;
  padding-top: 0;
  gap: 8px;
  ${Media.Tablet`
    flex-direction: row;
    border-top: 1px solid var(--neutral-4);
    padding-top: 16px;
  `}
`

import { ICountry } from './ICountry'
import { IUser } from './IUser'

export enum IWhoCanApply {
  EVERYONE = 1,
  EU = 2,
  NON_EU = 3,
  OECD = 4,
}

export interface IVisaWizard {
  id: string

  title: string
  articleUrl?: string
  published: boolean

  countryId: string
  country: ICountry

  advisorId?: string
  advisor?: IUser

  whoCan: IWhoCanApply
  selfEmployed: boolean
  employee: boolean
  remoteLength: number
  monthlyIncome: number
  savings: number
  visaDuration: number
  petsAllowed: boolean
  weight: number

  match?: boolean
  createdAt: Date
}

import styled from 'styled-components'
import { Flex, PopupActions, PopupPadding, textVariants } from '@/components/ui'

export const PopupContent = styled(Flex)`
  width: 384px;
  padding: ${PopupPadding};
  flex-direction: column;
  gap: 20px;
`

export const Actions = styled(PopupActions)`
  button {
    width: 100%;
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['body-s-semibold']}
  gap: 10px;
`

import React, { ReactNode, useState } from 'react'
import * as ReactDOM from 'react-dom'
import styled from 'styled-components'
import { usePopper } from 'react-popper'
import { Options, Placement } from '@popperjs/core'
import { AnimatePresence, motion } from 'framer-motion'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useTheme } from '@/contexts/Theme'

const Container = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['zIndex'].includes(prop),
})<{ zIndex?: number }>`
  background-color: var(--panel-1);
  border-radius: 12px;
  border: 1px solid var(--neutral-100);
  z-index: ${(p) => p.zIndex || 100};
  box-shadow: 0px 2.803px 5.639px 4px rgba(16, 24, 40, 0.04),
    0px 41px 41px 0px rgba(16, 24, 40, 0.15);
`

interface Props {
  isOpen: boolean
  placement?: Placement
  options?: Omit<Options, 'placement'>
  children?: (value: IContext) => ReactNode
  view: React.ReactNode
  onClose?: () => void
  zIndex?: number
}

interface IContext {
  anchorRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

const Context = React.createContext<IContext>({} as IContext)

export const Popout: React.FC<Props> = ({
  isOpen,
  placement = 'bottom-end',
  options,
  view,
  children,
  onClose,
  zIndex,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  )
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    ...options,
  })

  useOnClickOutside(popperElement, () => onClose?.())

  const { theme } = useTheme()

  const portalTarget =
    typeof document !== 'undefined' && document.getElementById('main')
  return (
    <AnimatePresence>
      <Context.Provider
        value={{
          anchorRef: setReferenceElement,
        }}
      >
        {children && <Context.Consumer>{children}</Context.Consumer>}
        {isOpen &&
          portalTarget &&
          ReactDOM.createPortal(
            <Container
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              initial={{ opacity: 0, marginTop: 0 }}
              animate={{ opacity: 1, marginTop: 8 }}
              exit={{ opacity: 0, marginTop: 0 }}
              transition={{ ease: 'easeOut', duration: 0.1 }}
              zIndex={zIndex}
              className={theme === 'dark' ? 'dark' : ''}
            >
              {view}
            </Container>,
            portalTarget,
          )}
      </Context.Provider>
    </AnimatePresence>
  )
}
